import { gql } from '@apollo/client';
import { TableContainer } from '@mui/material';
import { toast } from 'components/common/Toast';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { SLABrandInboundRankingProductCompetitorDialogView } from 'features/socialListeningAnalytics/views/slaBrandInboundRankingProductDialog/SLABrandInboundRankingProductCompetitorDialogView';
import {
  CapturedProductAttributeUsageFragmentForSlaRankingTableAttributesFragmentDoc,
  CapturedProductFragmentForSlaBrandInboundRankingProductDialogViewFragmentDoc,
  CapturedProductFragmentForSlaRankingTableCompetitorFragmentDoc,
  CapturedProductFragmentForSlaRankingTableFragment,
  CapturedProductFragmentForViewProductPopoverFragmentDoc,
  CapturedProductGraphDataFragmentForSlaRankingGraphDataFragmentDoc,
  CapturedProductSectionSortBy,
  CapturedProductSectionSortField,
  CapturedProductSectionViewType,
  CompetitorFlatCreatorAnalyticFiltersInputForCapturedProduct,
  SocialPostFragmentSocialPostThumbnailFragmentDoc,
  SortOrder,
  useGetCapturedProductsForSlaRankingTableCompetitorQuery,
} from 'graphql/generated';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ToastPosition } from 'react-hot-toast';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';
import { SLAProductContextMenu } from '../slaProductContextMenu';
import { SLARankingTableContent } from './SLARankingTableContent';
import { ViewProductPopover } from './ViewProductPopover';

// eslint-disable-next-line
gql`
  fragment CapturedProductFragmentForSLARankingTableCompetitor on CapturedProductDataForInbound {
    id
    line
    model
    percentChange
    socialPostCount
    totalViewCount
    brand {
      id
      name
    }
    category {
      id
      name
    }
    attributeUsage {
      ...CapturedProductAttributeUsageFragmentForSLARankingTableAttributes
    }
    graphData {
      ...CapturedProductGraphDataFragmentForSLARankingGraphData
    }
    previewPosts(count: $count) {
      ...SocialPostFragmentSocialPostThumbnail
    }
    ...CapturedProductFragmentForSLABrandInboundRankingProductDialogView
    ...CapturedProductFragmentForViewProductPopover
  }
  ${SocialPostFragmentSocialPostThumbnailFragmentDoc}
  ${CapturedProductFragmentForSlaBrandInboundRankingProductDialogViewFragmentDoc}
  ${CapturedProductFragmentForViewProductPopoverFragmentDoc}
  ${CapturedProductGraphDataFragmentForSlaRankingGraphDataFragmentDoc}
  ${CapturedProductAttributeUsageFragmentForSlaRankingTableAttributesFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetCapturedProductsForSLARankingTableCompetitor(
    $data: CompetitorFlatCreatorAnalyticFiltersInputForCapturedProduct!
    $count: Int!
  ) {
    capturedProductsPaginatedForCompetitors(data: $data) {
      pageInfo {
        hasNextPage
        endCursor
      }
      data {
        ...CapturedProductFragmentForSLARankingTableCompetitor
      }
      meta {
        totalPostsCount
      }
    }
  }
  ${CapturedProductFragmentForSlaRankingTableCompetitorFragmentDoc}
`;

enum ActionType {
  Sorting = 'sorting',
  Editing = 'editing',
}

interface SLARankingTableCompetitorProps {
  onSetTotalPostsCount: (count: number) => void;
  filters: Omit<
    CompetitorFlatCreatorAnalyticFiltersInputForCapturedProduct,
    'sortBy'
  >;
  onSelectFilter: (
    filter: Pick<
      CompetitorFlatCreatorAnalyticFiltersInputForCapturedProduct,
      | 'capturedProductBrandIds'
      | 'capturedProductAttributeIds'
      | 'capturedProductCategoryIds'
      | 'capturedProductLineModelInputs'
    >,
  ) => void;
}

export const SLARankingTableCompetitor = ({
  onSetTotalPostsCount,
  filters,
  onSelectFilter,
}: SLARankingTableCompetitorProps) => {
  const { viewType, searchTerm } = filters;
  const searchedTerm = searchTerm || '';
  const [sortBy, setSortBy] = useState<CapturedProductSectionSortBy>({
    field: CapturedProductSectionSortField.NumberOfPosts,
    order: SortOrder.Desc,
  });
  const [contextMenuAnchorPosition, setContextMenuAnchorPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const [scrollDirection, setScrollDirection] = useState<
    'left' | 'right' | null
  >(null);

  const [lastScrollLeft, setLastScrollLeft] = useState(0);
  const tableContainerRef = useRef<HTMLDivElement | null>(null);

  // This is purely used for the popover
  const [viewProductType, setViewProductType] =
    useState<CapturedProductSectionViewType | null>(null);

  const [viewProductAnchorEl, setViewProductAnchorEl] =
    useState<null | HTMLElement>(null);

  const [dialogProduct, setDialogProduct] =
    useState<CapturedProductFragmentForSlaRankingTableFragment | null>(null);

  const productVariableData = useMemo(() => {
    return {
      ...filters,
      sortBy,
    };
  }, [sortBy, filters]);

  const { data, fetchMore, loading } =
    useGetCapturedProductsForSlaRankingTableCompetitorQuery({
      variables: { data: { ...productVariableData }, count: 4 },
      fetchPolicy: 'cache-and-network',
    });

  useEffect(() => {
    if (data?.capturedProductsPaginatedForCompetitors.meta?.totalPostsCount) {
      onSetTotalPostsCount(
        data.capturedProductsPaginatedForCompetitors.meta.totalPostsCount,
      );
    }
    onSetTotalPostsCount(
      data?.capturedProductsPaginatedForCompetitors.meta?.totalPostsCount ?? 0,
    );
  }, [data, onSetTotalPostsCount]);

  const products = useMemo(
    () =>
      data?.capturedProductsPaginatedForCompetitors.data.filter(
        (product, index, self) =>
          index === self.findIndex((p) => p.id === product.id),
      ) ?? [],
    [data],
  );

  const stopEvents = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const showFilterSelectionToast = (message: string) => {
    toast({
      position: 'bottom-center' as ToastPosition,
      message,
      icon: <IconBoldTickCircle color={theme.colors?.primary.parchment} />,
      sx: {
        span: {
          color: theme.colors?.primary.parchment,
          ...theme.typography['headline-sm'],
        },
        alignItems: 'center',
        display: 'flex',
        gap: 2,
        backgroundColor: theme.colors?.primary.black,
        borderRadius: 25,
        padding: theme.spacing(1, 3, 1, 2),
      },
      shouldShowCloseButton: false,
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (tableContainerRef.current) {
        const currentScrollLeft = tableContainerRef.current.scrollLeft;

        // Determine horizontal scroll direction (left/right)
        if (currentScrollLeft === 0) {
          setScrollDirection(null);
        } else if (currentScrollLeft > lastScrollLeft) {
          setScrollDirection('right');
        } else if (currentScrollLeft < lastScrollLeft) {
          setScrollDirection('left');
        }

        setLastScrollLeft(currentScrollLeft);
      }
    };

    // Add event listener for scroll
    const currentTable = tableContainerRef.current;
    currentTable?.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      currentTable?.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollLeft]);

  return (
    <>
      <TableContainer
        sx={{ maxHeight: 320, overflowY: 'auto' }}
        ref={tableContainerRef}
      >
        <InfiniteScroll
          loadMore={() => {
            fetchMore({
              variables: {
                data: {
                  ...productVariableData,
                  skip: products.length,
                },
                count: 4,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return {
                  ...fetchMoreResult,
                  capturedProductsPaginatedForCompetitors: {
                    ...fetchMoreResult.capturedProductsPaginatedForCompetitors,
                    data: [
                      ...prev.capturedProductsPaginatedForCompetitors.data,
                      ...fetchMoreResult.capturedProductsPaginatedForCompetitors
                        .data,
                    ],
                  },
                };
              },
            });
          }}
          threshold={510}
          useWindow={false}
          initialLoad={false}
          hasMore={
            data?.capturedProductsPaginatedForCompetitors.pageInfo.hasNextPage
          }
        >
          <SLARankingTableContent
            products={products}
            viewType={viewType}
            sortInfo={{
              field: sortBy.field,
              order: sortBy.order ?? SortOrder.Desc,
              setSortBy,
            }}
            searchTerm={searchedTerm}
            loading={loading}
            scrollDirection={scrollDirection}
            setDialogProduct={setDialogProduct}
            setViewProductAnchorEl={setViewProductAnchorEl}
            setViewProductType={setViewProductType}
            stopEvents={stopEvents}
            onSelectFilter={onSelectFilter}
            filters={{
              ...filters,
            }}
            showFilterSelectionToast={showFilterSelectionToast}
            searchedTerm={searchedTerm}
          />
        </InfiniteScroll>
      </TableContainer>

      {dialogProduct && !viewProductAnchorEl && (
        <SLABrandInboundRankingProductCompetitorDialogView
          onClose={() => {
            setDialogProduct(null);
            setViewProductType(null);
          }}
          filters={{
            ...filters,
            // for top products section we need to set viewType to ProductLineModel
            viewType: CapturedProductSectionViewType.ProductLineModel,
            // NOTE: Accept everything as line & model filters, including null and empty string
            capturedProductLineModelInputs: [
              {
                line: dialogProduct.line,
                model: dialogProduct.model,
              },
            ],
            ...(dialogProduct.category
              ? { capturedProductCategoryIds: [dialogProduct.category.id] }
              : {}),
            ...(dialogProduct.brand
              ? { capturedProductBrandIds: [dialogProduct.brand.id] }
              : {}),
          }}
          capturedProduct={dialogProduct}
          // this is used for showing the dialog in the correct view
          currentViewType={viewProductType || filters.viewType}
        />
      )}
      {viewProductType && dialogProduct && (
        <ViewProductPopover
          anchorEl={viewProductAnchorEl}
          onClose={() => {
            setViewProductAnchorEl(null);
            setDialogProduct(null);
            setViewProductType(null);
          }}
          onViewProduct={() => {
            if (
              viewProductType === CapturedProductSectionViewType.ProductBrand &&
              dialogProduct.brand
            ) {
              onSelectFilter({
                capturedProductBrandIds: [
                  ...(filters.capturedProductBrandIds ?? []),
                  dialogProduct.brand.id,
                ],
              });
              showFilterSelectionToast(
                `Filter by Brand: ${dialogProduct.brand.name}`,
              );
            } else if (dialogProduct.category) {
              onSelectFilter({
                capturedProductCategoryIds: [
                  ...(filters.capturedProductCategoryIds ?? []),
                  dialogProduct.category.id,
                ],
              });
              showFilterSelectionToast(
                `Filter by Category: ${dialogProduct.category.name}`,
              );
            }
            setViewProductAnchorEl(null);
            setDialogProduct(null);
            setViewProductType(null);
          }}
          onViewInsights={() => {
            setViewProductAnchorEl(null);
          }}
        />
      )}

      {contextMenuAnchorPosition && (
        <SLAProductContextMenu
          open
          anchorPosition={contextMenuAnchorPosition}
          anchorReference="anchorPosition"
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => setContextMenuAnchorPosition(null)}
          onMouseDown={stopEvents}
          onMouseMove={stopEvents}
          renderButton={false}
        />
      )}
    </>
  );
};
