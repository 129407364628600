import { gql } from '@apollo/client';
import { SLACreatorDemographics } from 'features/socialListeningAnalytics/components';
import {
  BrandContentType,
  BrandInboundFiltersInput,
  SocialPostCreatorDemographicFragmentSlaCreatorDemographicsFragmentDoc,
  SocialPostGenderDemographic,
  SocialPostGenerationDemographic,
  useGetBrandInboundCreatorDemographicsForSlaBrandInboundCreatorDemographicsViewQuery,
} from 'graphql/generated';

// eslint-disable-next-line
gql`
  query GetBrandInboundCreatorDemographicsForSlaBrandInboundCreatorDemographicsView(
    $data: BrandInboundFiltersInput!
  ) {
    getBrandInboundCreatorDemographics(data: $data) {
      ...SocialPostCreatorDemographicFragmentSLACreatorDemographics
    }
  }
  ${SocialPostCreatorDemographicFragmentSlaCreatorDemographicsFragmentDoc}
`;

interface Props {
  filters: BrandInboundFiltersInput;
  onChange: (data: {
    selectedGender: SocialPostGenderDemographic[];
    selectedGeneration: SocialPostGenerationDemographic[];
  }) => void;
}

export const SLABrandInboundCreatorDemographicsView = ({
  filters,
  onChange,
}: Props) => {
  const { data } =
    useGetBrandInboundCreatorDemographicsForSlaBrandInboundCreatorDemographicsViewQuery(
      {
        variables: {
          data: filters,
        },
        skip:
          !filters.contentType ||
          !filters.generation ||
          !filters.gender ||
          (filters.contentType === BrandContentType.Topic &&
            !filters.topicIds?.length),
        fetchPolicy: 'cache-and-network',
      },
    );

  const creatorDemographics = data?.getBrandInboundCreatorDemographics || [];

  return (
    <SLACreatorDemographics
      creatorDemographics={creatorDemographics}
      selectedGender={filters.gender || []}
      selectedGeneration={filters.generation || []}
      onChange={onChange}
    />
  );
};
