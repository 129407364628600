import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { BrandParsingSettingsView } from 'features/brand';
import {
  BrandFragmentBrandParsingSettingsViewFragmentDoc,
  ListeningTopicType,
  TopicFragmentTopicParsingSettingsViewFragmentDoc,
  useGetBrandForSmlOnboardingBrandParsingSettingsQuery,
  useUpdateTopicForSmlOnboardingBrandParsingSettingsMutation,
} from 'graphql/generated';
import { useEffect } from 'react';
import { theme } from 'styles/theme';
import { RenderNextButton } from '../renderNextButton/RenderNextButton';

// eslint-disable-next-line
gql`
  query GetBrandForSMLOnboardingBrandParsingSettings($id: String!) {
    brand(id: $id) {
      id
      topics {
        id
        type
      }
      ...BrandFragmentBrandParsingSettingsView
    }
  }
  ${BrandFragmentBrandParsingSettingsViewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation UpdateTopicForSMLOnboardingBrandParsingSettings(
    $data: UpdateTopicInput!
  ) {
    updateTopic(data: $data) {
      id
      ...TopicFragmentTopicParsingSettingsView
    }
  }
  ${TopicFragmentTopicParsingSettingsViewFragmentDoc}
`;

type SocialMediaListeningOnboardingBrandParsingSettingsProps = {
  brandId?: string;
  onNext: () => void;
};

export const SocialMediaListeningOnboardingBrandParsingSettings = ({
  brandId,
  onNext,
}: SocialMediaListeningOnboardingBrandParsingSettingsProps) => {
  const { data } = useGetBrandForSmlOnboardingBrandParsingSettingsQuery({
    variables: { id: brandId || '' },
    skip: !brandId,
    fetchPolicy: 'cache-and-network',
  });
  const brand = data?.brand;
  const canGoNext =
    brand?.parsingSettings?.Instagram.enabled ||
    brand?.parsingSettings?.Tiktok.enabled ||
    brand?.parsingSettings?.Youtube.enabled;

  /**
   * During onboarding flow, we also create a default BrandMention topic
   * When brand's parsing settings are updated, we also have to update the topic's parsing settings
   * Otherwise, all platforms for the brand mention topic will be disabled by default (see defaultParsingSettings)
   */
  const [updateTopic] =
    useUpdateTopicForSmlOnboardingBrandParsingSettingsMutation();
  useEffect(() => {
    if (brand && brand.parsingSettings) {
      const brandMentionTopic = brand.topics.find(
        (topic) => topic.type === ListeningTopicType.BrandMention,
      );

      if (brandMentionTopic) {
        updateTopic({
          variables: {
            data: {
              topicId: brandMentionTopic.id,
              data: {
                parsingSettings: {
                  Instagram: {
                    enabled: brand.parsingSettings.Instagram.enabled,
                    types: {
                      Photo: brand.parsingSettings.Instagram.types?.Photo,
                      Video: brand.parsingSettings.Instagram.types?.Video,
                      Carousel: brand.parsingSettings.Instagram.types?.Carousel,
                    },
                  },
                  Tiktok: {
                    enabled: brand.parsingSettings.Tiktok.enabled,
                  },
                  Youtube: {
                    enabled: brand.parsingSettings.Youtube.enabled,
                  },
                },
              },
            },
          },
        });
      }
    }
  }, [brand]); // eslint-disable-line

  const onSubmit = async () => {
    onNext();
  };

  if (!brandId) {
    return null;
  }

  return (
    <Box
      position="relative"
      width="60vw"
      display="flex"
      gap={4}
      flexDirection="column"
      color={theme.colors?.primary.parchment}
      sx={{ overflowY: 'auto', pb: 4 }}
    >
      <Typography variant="headline-lg" fontSize={theme.spacing(7)}>
        Choose your platforms
      </Typography>
      <Typography variant="subhead-xl" mb={8}>
        Choose platforms for data collection. Each platform added will use more
        hours from your account.
      </Typography>

      <BrandParsingSettingsView key={brandId} brandId={brandId} />

      <RenderNextButton onNextClick={onSubmit} disabled={!canGoNext} />
    </Box>
  );
};
