import { Box, Checkbox, Switch, SxProps, Typography } from '@mui/material';
import { platformIconMap, platformLabelMap } from 'features/socialPost';
import { Platform, SocialPostType } from 'graphql/generated';

export type BrandPlatformParsingSettingProps = {
  platform: Platform;
  types?: SocialPostType[];
  platformChecked: boolean;
  typesChecked?: Partial<Record<SocialPostType, boolean>>;
  sx?: SxProps;
  componentsProps?: {
    platform?: {
      sx?: SxProps;
    };
    type?: {
      sx?: SxProps;
    };
  };
  readOnly?: boolean;
  onTogglePlatform: (value: boolean) => void;
  onToggleType?: (type: SocialPostType, value: boolean) => void;
};

export const BrandPlatformParsingSetting = (
  props: BrandPlatformParsingSettingProps,
) => {
  const {
    platform,
    types,
    platformChecked,
    typesChecked,
    sx,
    componentsProps = {},
    readOnly,
    onTogglePlatform,
    onToggleType,
  } = props;

  const Icon = platformIconMap[platform];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        ...sx,
      }}
    >
      <Box
        sx={{
          py: 2,
          px: 4,
          borderRadius: 3,
          bgcolor: '#FFFFFF1A',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          ...componentsProps.platform?.sx,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
          }}
        >
          {Icon && <Icon size={20} />}
          <Typography variant="headline-md" fontSize={20}>
            {platformLabelMap[platform]}
          </Typography>
        </Box>
        <Switch
          checked={platformChecked}
          onChange={(e) => onTogglePlatform(e.target.checked)}
          readOnly={readOnly}
        />
      </Box>
      {types && types.length > 0 && platformChecked ? (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {types.map((type) => {
            return (
              <Box
                key={type}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  px: 4,
                  py: 3,
                  borderRadius: 2,
                  bgcolor: '#2306030D',
                  ml: 9,
                  mb: 3,
                  ...componentsProps.type?.sx,
                }}
              >
                <Checkbox
                  checked={typesChecked?.[type]}
                  onChange={(e) => onToggleType?.(type, e.target.checked)}
                  sx={{
                    p: 0,
                    color: 'inherit !important',
                  }}
                  readOnly={readOnly}
                  size="small"
                />
                <Typography variant="headline-md" fontSize={20}>
                  {type}
                </Typography>
              </Box>
            );
          })}
        </Box>
      ) : null}
    </Box>
  );
};
