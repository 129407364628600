/**
 * This should replace the existing useGraphDataMaker hook.
 */

import { ChartDataPoint } from 'graphql/generated';
import moment from 'moment';
import { useMemo } from 'react';

export type ChartDataPointWithDateRange = ChartDataPoint & {
  dateRange: [Date, Date];
};

export type GraphDataMakerProps = {
  dailyChartData: ChartDataPoint[];
  dateRange?: (Date | null)[];
};

export const createDateRangesForGraph = (startDate: Date, endDate: Date) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const diffDays = end.diff(start, 'days');

  type DateRange = {
    startDate: Date;
    endDate: Date;
    label: string;
  };

  // For years (> 365 days)
  if (diffDays > 365) {
    const ranges: DateRange[] = [];
    const currentDate = start.clone().startOf('year');
    while (currentDate.isBefore(end)) {
      ranges.push({
        startDate: currentDate.toDate(),
        endDate: currentDate.clone().endOf('year').toDate(),
        label: currentDate.format('YYYY'),
      });
      currentDate.add(1, 'year');
    }
    return ranges;
  }

  // For quarters (> 90 days)
  if (diffDays > 90) {
    const ranges: DateRange[] = [];
    const currentDate = start.clone().startOf('quarter');
    while (currentDate.isBefore(end)) {
      ranges.push({
        startDate: currentDate.toDate(),
        endDate: currentDate.clone().endOf('quarter').toDate(),
        label: `${currentDate.format('MMM')} - ${currentDate
          .clone()
          .endOf('quarter')
          .format('MMM YYYY')}`,
      });
      currentDate.add(1, 'quarter');
    }
    return ranges;
  }

  // For months (> 31 days)
  if (diffDays > 31) {
    const ranges: DateRange[] = [];
    const currentDate = start.clone().startOf('month');
    while (currentDate.isBefore(end)) {
      ranges.push({
        startDate: currentDate.toDate(),
        endDate: currentDate.clone().endOf('month').toDate(),
        label: currentDate.format('MMM YYYY'),
      });
      currentDate.add(1, 'month');
    }
    return ranges;
  }

  // For weeks (> 7 days)
  if (diffDays > 7) {
    const ranges: DateRange[] = [];
    const currentDate = start.clone().startOf('week');
    while (currentDate.isBefore(end)) {
      ranges.push({
        startDate: currentDate.toDate(),
        endDate: currentDate.clone().endOf('week').toDate(),
        label: `${currentDate.format('MMM D')} - ${currentDate
          .clone()
          .endOf('week')
          .format('MMM D')}`,
      });
      currentDate.add(1, 'week');
    }
    return ranges;
  }

  // For days (default)
  const ranges: DateRange[] = [];
  const currentDate = start.clone().startOf('day');
  while (currentDate.isBefore(end)) {
    ranges.push({
      startDate: currentDate.toDate(),
      endDate: currentDate.clone().endOf('day').toDate(),
      label: currentDate.format('MMM D'),
    });
    currentDate.add(1, 'day');
  }
  return ranges;
};

const getChartDailyDataProcessed = (props: GraphDataMakerProps) => {
  const { dailyChartData, dateRange } = props;
  const [startDate, endDate] = dateRange || [];

  const chartData = [] as ChartDataPoint[];
  const currentDate = moment(startDate).clone();
  while (currentDate.isBefore(endDate)) {
    if (
      dailyChartData.find((data) => moment(data.X).isSame(currentDate, 'day'))
    ) {
      chartData.push({
        X: currentDate.toDate(),
        Y:
          dailyChartData.find((data) =>
            moment(data.X).isSame(currentDate, 'day'),
          )?.Y ?? 0,
      });
    } else {
      chartData.push({ X: currentDate.toDate(), Y: 0 });
    }

    currentDate.add(1, 'day');
  }
  return chartData.sort((a, b) => moment(a.X).diff(moment(b.X)));
};

// Pure data processing functions
export const processGraphData = (props: GraphDataMakerProps) => {
  const { dailyChartData: _dailyChartDataWithMissingDays, dateRange } = props;
  const [startDate, endDate] = dateRange || [];

  const dailyChartData = getChartDailyDataProcessed(props);

  const stateDateUtc = moment(startDate).utc();
  const endDateUtc = moment(endDate).utc();

  const groupedDataByDay = groupDataByDay(dailyChartData, stateDateUtc);
  const groupedDataByWeek = groupDataByWeek(
    dailyChartData,
    stateDateUtc,
    endDateUtc,
  );
  const groupedDataByMonth = groupDataByMonth(dailyChartData);

  return prepareChartData(
    dailyChartData,
    groupedDataByDay,
    groupedDataByWeek,
    groupedDataByMonth,
  );
};

// Helper functions
function groupDataByDay(
  dailyChartData: ChartDataPoint[],
  stateDateUtc: moment.Moment,
) {
  return dailyChartData.reduce((acc, curr) => {
    const day = moment(curr.X).utc().dayOfYear();
    const year = moment(curr.X).utc().year();
    const dayStart = moment().utc().dayOfYear(day).year(year).startOf('day');
    const dayLabel = dayStart.format('MMM D');

    if (!acc[dayLabel]) {
      acc[dayLabel] = {
        value: 0,
        dateRange: [
          stateDateUtc.clone().startOf('day').toDate(),
          stateDateUtc.clone().endOf('day').toDate(),
        ],
      };
    }

    acc[dayLabel].value += curr.Y;
    return acc;
  }, {} as Record<string, { value: number; dateRange: [Date, Date] }>);
}

function groupDataByWeek(
  dailyChartData: ChartDataPoint[],
  stateDateUtc: moment.Moment,
  endDateUtc: moment.Moment,
) {
  return dailyChartData.reduce((acc, curr) => {
    const week = moment(curr.X).utc().isoWeek();
    const year = moment(curr.X).utc().isoWeekYear();
    const isoWeekStart = moment()
      .utc()
      .isoWeek(week)
      .isoWeekYear(year)
      .startOf('isoWeek');
    const isoWeekEnd = moment()
      .utc()
      .isoWeek(week)
      .isoWeekYear(year)
      .endOf('isoWeek');

    const weekStart = stateDateUtc.isBetween(isoWeekStart, isoWeekEnd)
      ? stateDateUtc
      : isoWeekStart;
    const weekEnd = endDateUtc.isBetween(isoWeekStart, isoWeekEnd)
      ? endDateUtc
      : isoWeekEnd;

    const weekLabel = `${weekStart.format('MMM D')} - ${weekEnd.format(
      'MMM D',
    )}`;

    if (!acc[weekLabel]) {
      acc[weekLabel] = {
        value: 0,
        dateRange: [weekStart.utc().toDate(), weekEnd.utc().toDate()],
      };
    }

    acc[weekLabel].value += curr.Y;
    return acc;
  }, {} as Record<string, { value: number; dateRange: [Date, Date] }>);
}

function groupDataByMonth(dailyChartData: ChartDataPoint[]) {
  return dailyChartData.reduce((acc, curr) => {
    const month = moment(curr.X).utc().month();
    const year = moment(curr.X).utc().year();
    const monthStart = moment().utc().month(month).year(year).startOf('month');
    const monthLabel = monthStart.format('MMM YYYY');

    if (!acc[monthLabel]) {
      acc[monthLabel] = {
        value: 0,
        dateRange: [
          moment(monthStart).utc().startOf('month').toDate(),
          moment(monthStart).utc().endOf('month').toDate(),
        ],
      };
    }

    acc[monthLabel].value += curr.Y;
    return acc;
  }, {} as Record<string, { value: number; dateRange: [Date, Date] }>);
}

function prepareChartData(
  dailyChartData: ChartDataPoint[],
  groupedDataByDay: Record<string, { value: number; dateRange: [Date, Date] }>,
  groupedDataByWeek: Record<string, { value: number; dateRange: [Date, Date] }>,
  groupedDataByMonth: Record<
    string,
    { value: number; dateRange: [Date, Date] }
  >,
): ChartDataPointWithDateRange[] {
  if (dailyChartData.length === 0) {
    return [];
  }

  const totalDays = dailyChartData.length;
  const firstDate = moment(dailyChartData[0].X);
  const lastDate = moment(dailyChartData[totalDays - 1].X);
  const totalWeeks = lastDate.diff(firstDate, 'weeks');

  if (totalDays <= 8) {
    return Object.keys(groupedDataByDay).map((key) => ({
      X: key,
      Y: groupedDataByDay[key].value,
      dateRange: groupedDataByDay[key].dateRange,
    }));
  }

  if (totalWeeks < 8) {
    return Object.keys(groupedDataByWeek).map((key) => ({
      X: key,
      Y: groupedDataByWeek[key].value,
      dateRange: groupedDataByWeek[key].dateRange,
    }));
  }

  return Object.keys(groupedDataByMonth).map((key) => ({
    X: key,
    Y: groupedDataByMonth[key].value,
    dateRange: groupedDataByMonth[key].dateRange,
  }));
}

// React Hook
export const useGraphDataMakerNew = (props: GraphDataMakerProps) => {
  const result = useMemo(() => processGraphData(props), [props]);
  return { chartData: result };
};
