import { gql } from '@apollo/client';
import { Box, Button, Chip, Grid, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { RadioMenuItem } from 'components/common/form/Select';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { IconLinearChart2 } from 'components/icons/components/linear/IconLinearChart2';
import { IconLinearEye } from 'components/icons/components/linear/IconLinearEye';
import { IconOutlinePlay } from 'components/icons/components/outline/IconOutlinePlay';
import { SocialPostThumbnail } from 'features/socialPost';
import {
  CapturedProductFragmentForTopProductsSectionFragment,
  CapturedProductSectionSortField,
  CapturedProductSectionViewType,
  SocialPostFragmentSocialPostThumbnailFragmentDoc,
} from 'graphql/generated';
import { useCallback } from 'react';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';

// eslint-disable-next-line
gql`
  fragment CapturedProductFragmentForTopProductsSection on CapturedProductDataForInbound {
    id
    line
    model
    socialPostCount
    totalViewCount
    brand {
      id
      name
    }
    category {
      id
      name
    }
    previewPosts(count: $count) {
      ...SocialPostFragmentSocialPostThumbnail
    }
  }
  ${SocialPostFragmentSocialPostThumbnailFragmentDoc}
`;

interface TopProductsSectionProps {
  viewType: CapturedProductSectionViewType;
  topProducts: CapturedProductFragmentForTopProductsSectionFragment[];
  selectedSortKey: CapturedProductSectionSortField;
  setSelectedSortKey: (sortKey: CapturedProductSectionSortField) => void;
}

const viewByIconMap = {
  [CapturedProductSectionSortField.NumberOfPosts]: IconOutlinePlay,
  [CapturedProductSectionSortField.ViewCount]: IconLinearEye,
  [CapturedProductSectionSortField.TotalEngagement]: IconLinearChart2,
};

export const TopProductsSection = ({
  topProducts,
  viewType,
  selectedSortKey,
  setSelectedSortKey,
}: TopProductsSectionProps) => {
  const getSummary = useCallback(
    (product: CapturedProductFragmentForTopProductsSectionFragment) => {
      if (selectedSortKey === CapturedProductSectionSortField.NumberOfPosts) {
        return `${formatBigNumber(product.socialPostCount)} posts`;
      }

      return `${formatBigNumber(product.totalViewCount)} total views`;
    },
    [selectedSortKey],
  );
  const viewByOptions = [
    {
      label: 'Total post count',
      value: CapturedProductSectionSortField.NumberOfPosts,
    },
    {
      label: 'Total engagement',
      value: CapturedProductSectionSortField.TotalEngagement,
    },
    {
      label: 'Total view count',
      value: CapturedProductSectionSortField.ViewCount,
    },
  ];
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
        margin: theme.spacing(6, 4, 2, 4),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="headline-md"
          fontSize={20}
          color={theme.colors?.utility[900]}
        >
          Top Products
        </Typography>

        <ContextMenu
          sx={{
            '& .context-menu-item': {
              p: 0,
              color: theme.colors?.primary.black,
            },
          }}
          renderExtraTop={() => (
            <Typography
              variant="subhead-sm"
              color={theme.colors?.utility[700]}
              ml={3}
              mb={1}
            >
              View by
            </Typography>
          )}
          options={viewByOptions.map((option) => ({
            renderOption: () => (
              <RadioMenuItem
                key={option.value}
                label={option.label}
                value={option.value}
                checked={selectedSortKey === option.value}
                sx={{
                  p: `${theme.spacing(2, 3)}`,
                }}
              />
            ),
            onClick: () => {
              setSelectedSortKey(option.value);
            },
          }))}
          renderButton={() => {
            const Icon = viewByIconMap[selectedSortKey];
            return (
              <Button
                variant="tertiary"
                sx={{
                  backgroundColor: theme.colors?.primary.parchment,
                  borderRadius: theme.spacing(2),
                  gap: 2,
                  px: 2,
                  '&:hover': {
                    backgroundColor: theme.colors?.primary.parchment,
                  },
                }}
                disableRipple={false}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    backgroundColor: 'rgba(35, 6, 3, 0.05)',
                    p: theme.spacing(1),
                    borderRadius: theme.spacing(1),
                  }}
                >
                  <Icon size={16} />
                </Box>
                <Typography variant="subhead-lg">
                  {viewByOptions.find(
                    (option) => option.value === selectedSortKey,
                  )?.label ?? 'View by'}
                </Typography>

                <IconLinearArrowDown size={16} />
              </Button>
            );
          }}
        />
      </Box>
      <Grid container spacing={6}>
        {topProducts.map((product) => {
          const chipLabel =
            viewType === CapturedProductSectionViewType.ProductBrand
              ? product.category?.name
              : product.brand?.name;
          return (
            <Grid item xs={12} sm={6} md={6} lg={3} key={product.id}>
              <Box
                sx={{
                  backgroundColor: theme.colors?.primary.parchment,
                  borderRadius: theme.spacing(4),
                  p: 2.5,
                  display: 'flex',
                  gap: 3,
                }}
              >
                <SocialPostThumbnail
                  socialPost={product.previewPosts[0]}
                  sx={{
                    width: 96,
                    height: 128,
                    borderRadius: 4,
                    objectFit: 'cover',
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    my: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                    }}
                  >
                    <Typography
                      variant="headline-sm"
                      sx={{
                        wordBreak: 'break-word',
                      }}
                    >
                      {product.line ?? ''}
                      {product.line && product.model && ' / '}
                      {product.model ?? ''}
                    </Typography>
                    {chipLabel && (
                      <Chip
                        label={chipLabel}
                        sx={{
                          background: 'rgba(35, 6, 3, 0.30)',
                          color: theme.colors?.primary.white,
                          ...theme.typography['headline-xxs'],
                          border: 'none',
                          width: 'fit-content',
                          whiteSpace: 'normal',
                          span: {
                            whiteSpace: 'normal',
                          },
                        }}
                      />
                    )}
                  </Box>
                  <Typography
                    variant="subhead-sm"
                    color={theme.colors?.utility[900]}
                  >
                    {getSummary(product)}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
