import { gql } from '@apollo/client';
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  TextField,
  Typography,
  createFilterOptions,
} from '@mui/material';
import {
  CollectionFragmentCollectionAutocompleteMenuFragmentDoc,
  CollectionFragmentCollectionMenuItemViewFragmentDoc,
  CollectionPermission,
  GetPostCollectionInput,
  useGetPostCollectionsForCollectionAutocompleteMenuQuery,
} from 'graphql/generated';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { CollectionListItemSkeleton } from '../listItem';
import {
  COLLECTION_MENU_ITEM_ADD_NEW_VALUE_KEY,
  CollectionMenuItemAddNewView,
} from './CollectionMenuItemAddNewView';
import {
  CollectionMenuItemView,
  CollectionMenuItemViewProps,
} from './CollectionMenuItemView';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetPostCollectionsForCollectionAutocompleteMenu(
    $input: GetPostCollectionInput!
  ) {
    postCollections(input: $input) {
      meta {
        totalCount
      }
      pageInfo {
        endCursor
        hasNextPage
        startCursor
        hasPreviousPage
      }
      data {
        type
        item {
          ...CollectionFragmentCollectionAutocompleteMenu
        }
      }
    }
  }
  ${CollectionFragmentCollectionAutocompleteMenuFragmentDoc}
`;

type OptionType = {
  value: string;
  label: string;
  disabled?: boolean;
};

const filter = createFilterOptions<OptionType>();

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment CollectionFragmentCollectionAutocompleteMenu on CollectionModel {
    id
    ...CollectionFragmentCollectionMenuItemView
  }
  ${CollectionFragmentCollectionMenuItemViewFragmentDoc}
`;

export interface CollectionAutocompleteMenuProps
  extends Pick<
      AutocompleteProps<any, boolean, boolean, boolean>,
      'onChange' | 'noOptionsText' | 'filterOptions'
    >,
    Omit<CollectionMenuItemViewProps, 'collection'> {
  filters: Omit<GetPostCollectionInput, 'query'>;
}

export const CollectionAutocompleteMenu = (
  props: CollectionAutocompleteMenuProps,
) => {
  const {
    componentProps,
    hideSelectIcon,
    onClick,
    renderSelectIcon,
    selectIconPosition,
    selectedCollectionIds,
    filters,
    ...rest
  } = props;
  const [searchStr, setSearchStr] = useState('');

  const {
    data: collectionsData,
    loading,
    fetchMore,
  } = useGetPostCollectionsForCollectionAutocompleteMenuQuery({
    variables: {
      input: {
        ...filters,
        query: searchStr,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const collections =
    collectionsData?.postCollections.data.flatMap((d) => d.item) || [];

  const fetchMoreCollections = async () => {
    await fetchMore({
      variables: {
        input: {
          ...filters,
          query: searchStr,
          after: collectionsData?.postCollections.pageInfo.endCursor,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          ...prev,
          postCollections: {
            meta: fetchMoreResult.postCollections.meta,
            pageInfo: {
              ...fetchMoreResult.postCollections.pageInfo,
            },
            data: [
              ...prev.postCollections.data,
              ...fetchMoreResult.postCollections.data.filter(
                (x) =>
                  !prev.postCollections.data.some(
                    (y) => y.item.id === x.item.id,
                  ),
              ),
            ].filter(
              (e, index, self) =>
                index === self.findIndex((t) => t.item.id === e.item.id),
            ),
          },
        };
      },
    });
  };

  const options: OptionType[] = useMemo(() => {
    const selectedCollectionIds = props.selectedCollectionIds || [];
    return (
      collections
        .filter((c) => c.myPermissions.includes(CollectionPermission.Update))
        .map((collection) => ({
          value: collection.id,
          label: collection.name,
        }))
        .sort((a, b) => {
          const aIsSelected = selectedCollectionIds.includes(a.value) ? 1 : 0;
          const bIsSelected = selectedCollectionIds.includes(b.value) ? 1 : 0;

          return bIsSelected - aIsSelected;
        }) || []
    );
  }, [collections]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Autocomplete
      inputValue={searchStr}
      onInputChange={(_, value, reason) => {
        if (reason === 'input') {
          setSearchStr(value);
        }
      }}
      open
      multiple
      value={[...options]}
      options={options}
      loading={loading}
      loadingText={<CollectionListItemSkeleton />}
      disablePortal
      disableCloseOnSelect
      renderInput={(params) => (
        <TextField
          {...params}
          onKeyDown={(event) => {
            event.stopPropagation();
          }}
          autoFocus
          placeholder="Search"
          sx={{
            px: 2,
            mt: 4,
            '.MuiOutlinedInput-root': {
              height: '32px',
              borderRadius: 100,
              py: '6px !important',
              px: '12px !important',
              bgcolor: theme.colors?.primary.white,
              boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',

              input: {
                ...theme.typography['subhead-lg'],
                p: '0 !important',
              },

              '.MuiOutlinedInput-notchedOutline': {
                display: 'none !important',
              },
            },
          }}
        />
      )}
      renderTags={() => null}
      renderOption={(props, option: OptionType) => {
        const disabled = option.disabled;
        if (option.value === COLLECTION_MENU_ITEM_ADD_NEW_VALUE_KEY) {
          return (
            <CollectionMenuItemAddNewView
              {...props}
              key={option.value}
              label={option.label}
              disabled={disabled}
              {...(disabled
                ? {
                    renderWarning: () => (
                      <Typography
                        variant="subhead-sm"
                        color={theme.colors?.utility[600]}
                        fontStyle="italic"
                      >
                        Collection already exist
                      </Typography>
                    ),
                  }
                : {})}
            />
          );
        }

        const childCollection = collections.find((c) => c.id === option.value);

        if (childCollection) {
          return (
            <CollectionMenuItemView
              key={childCollection.id}
              collection={childCollection}
              componentProps={componentProps}
              selectedCollectionIds={selectedCollectionIds}
              hideSelectIcon={hideSelectIcon}
              renderSelectIcon={renderSelectIcon}
              selectIconPosition={selectIconPosition}
              onClick={onClick}
            />
          );
        }

        return null;
      }}
      popupIcon={null}
      clearIcon={null}
      // @ts-ignore
      PopperComponent={Box}
      PaperComponent={Box}
      componentsProps={{
        popper: {
          sx: {
            flex: 1,
            position: 'relative',
            width: '100% !important',
            overflow: 'auto',
            height: '100%',
            my: 4,
            '.MuiAutocomplete-listbox': {
              maxHeight: 252,
            },
          },
        },
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const isOptionExist = options.some(
          (option) =>
            option.label.trim().toLowerCase() ===
            params.inputValue.trim().toLowerCase(),
        );

        if (params.inputValue.trim() !== '' && !isOptionExist) {
          filtered.unshift({
            label: params.inputValue,
            value: COLLECTION_MENU_ITEM_ADD_NEW_VALUE_KEY,
            disabled: filtered.length > 0,
          });
        }

        return filtered;
      }}
      noOptionsText={
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="subhead-lg"
            color={theme.colors?.utility[600]}
            textAlign="center"
          >
            No collection here. Create one now by typing in the search bar.
          </Typography>
        </Box>
      }
      ListboxProps={{
        onScrollCapture: (event: React.SyntheticEvent) => {
          if (collectionsData?.postCollections.pageInfo.hasNextPage) {
            const listboxNode = event.currentTarget;
            const currentScrollPos =
              listboxNode.scrollTop + listboxNode.clientHeight;
            if (
              Math.abs(currentScrollPos - listboxNode.scrollHeight) < 200 &&
              !loading
            ) {
              fetchMoreCollections();
            }
          }
        },
      }}
      {...rest}
    />
  );
};
