import { Box, IconButton, Typography } from '@mui/material';
import { IconLinearClose } from 'components/icons/components/linear/IconLinearClose';
import { IconLinearMinus } from 'components/icons/components/linear/IconLinearMinus';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { OnboardingStates } from '../../types';

interface ExampleTileProps {
  currentState: OnboardingStates;
}

export const ExampleTile = ({ currentState }: ExampleTileProps) => {
  const [action, setAction] = useState<'minimize' | 'close' | null>(null);

  const stepContent = useMemo(() => {
    if (currentState === OnboardingStates.AskTopicName) {
      return {
        title: 'Title',
        description: (
          <>
            <Typography variant="subhead-xl">Soda Recipes</Typography>
            <Typography variant="subhead-lg" color={theme.colors?.utility[700]}>
              Add a descriptive title that summarizes your topic.
            </Typography>
          </>
        ),
      };
    }
    if (currentState === OnboardingStates.AskTopicDescription) {
      return {
        title: 'Prompt',
        description:
          'This topic covers creative soda-based drink recipes. Content should show creators mixing unique drink combinations, revealing innovative ways to use carbonated beverages, or crafting mocktails using soda as a key ingredient.',
      };
    }
    if (currentState === OnboardingStates.PostPriorityInfo) {
      return {
        title: 'Topic Relevancy',
        description: (
          <Box
            component="ul"
            sx={{
              m: 0,
              pl: 7,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <li>
              <Typography variant="subhead-xl">
                Prioritize creators who have over 100,000 followers
              </Typography>
            </li>
            <li>
              <Typography variant="subhead-xl">
                Prioritize videos of creators in the kitchen
              </Typography>
            </li>
            <li>
              <Typography variant="subhead-xl">
                Prioritize videos of creators using Pepsi in their soda recipes
              </Typography>
            </li>
          </Box>
        ),
      };
    }
    if (currentState === OnboardingStates.CommunicationStrategy) {
      return {
        description:
          'Respond with a fun and witty tone. Keep it short and use emojis.',
      };
    }
    if (currentState === OnboardingStates.Keywords) {
      const keywords = [
        'weird food combos',
        'taste test',
        'soda',
        '#soda',
        'drink combo',
        'drinktok',
        '#drinkhack',
        '#foodack',
        'soda recipe',
        '#dirtysoda',
        'dirty soda',
      ];
      return {
        description: (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            {keywords.map((keyword) => (
              <Box
                sx={{
                  padding: theme.spacing(1, 2),
                  borderRadius: 6,
                  background: 'rgba(35, 6, 3, 0.10)',
                }}
              >
                <Typography variant="subhead-xl">{keyword}</Typography>
              </Box>
            ))}
          </Box>
        ),
      };
    }
    if (currentState === OnboardingStates.Signals) {
      return {
        description: (
          <>
            <img
              src="/svg/topic-onboarding-category.svg"
              alt="categories"
              draggable={false}
            />
            {[
              {
                title: 'Ingredients',
                items: [
                  'creamer',
                  'pickle',
                  'protein',
                  'ice cream',
                  'jalapeño',
                ],
              },
              {
                title: 'Occasion',
                items: ['bar', 'beach', 'party', 'sports game', 'movie night'],
              },
              {
                title: 'Soda type',
                items: ['sugar free', 'diet', 'prebiotic', 'probiotic'],
              },
            ].map(({ title, items }) => (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  padding: 2,
                  borderRadius: 3,
                  background: 'rgba(35, 6, 4, 0.04)',
                }}
              >
                <Typography
                  variant="headline-xs"
                  color={theme.colors?.utility['yellow-4']}
                >
                  {title}
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                  {items.map((item) => (
                    <Box
                      sx={{
                        padding: theme.spacing(1, 2),
                        borderRadius: 6,
                        background: 'rgba(35, 6, 3, 0.10)',
                      }}
                    >
                      <Typography variant="subhead-xl">{item}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
          </>
        ),
      };
    }
  }, [currentState]);

  if (action === 'close' || !stepContent) {
    return null;
  }

  return (
    <Box
      sx={{
        maxWidth: 270,
        minWidth: 270,
        padding: 3,
        background: theme.colors?.utility['yellow-1'],
        borderRadius: 4,
        border: '2px solid rgba(250, 243, 236, 0.10)',
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: 0,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="headline-xs" color={theme.colors?.utility[700]}>
          Example {action === 'minimize' ? '' : stepContent?.title}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <IconButton
            disableRipple
            sx={{
              position: 'absolute',
              p: 0,
              color: theme.colors?.utility[800],
              mt: action === 'minimize' ? 3 : 0,
              right: 14,
            }}
            onClick={() => setAction(action === 'minimize' ? null : 'minimize')}
          >
            <IconLinearMinus size={18} />
          </IconButton>
          <IconButton
            disableRipple
            sx={{ p: 0, mt: -1, color: theme.colors?.utility[800] }}
            onClick={() => setAction('close')}
          >
            <IconLinearClose size={9} />
          </IconButton>
        </Box>
      </Box>

      {action !== 'minimize' &&
        (typeof stepContent?.description === 'string' ? (
          <Typography variant="subhead-xl">
            {stepContent?.description}
          </Typography>
        ) : (
          stepContent?.description
        ))}
    </Box>
  );
};
