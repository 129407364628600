import { gql } from '@apollo/client';
import { Box, SxProps } from '@mui/material';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import {
  BrandPlatformParsingSetting,
  BrandPlatformParsingSettingProps,
} from 'features/brand/components/platformParsingSetting';
import {
  ParsingSettingsInputData,
  Platform,
  PlotFeature,
  SocialPostType,
  useGetBrandForBrandParsingSettingsViewQuery,
  useUpdateBrandForBrandParsingSettingsViewMutation,
} from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useEffect, useMemo, useRef, useState } from 'react';

export const BRAND_FRAGMENT_BRAND_PARSING_SETTINGS_VIEW = gql`
  fragment BrandFragmentBrandParsingSettingsView on BrandModel {
    id
    parsingSettings {
      Instagram {
        enabled
        types {
          Photo
          Video
          Carousel
        }
      }
      Tiktok {
        enabled
      }
      Youtube {
        enabled
      }
    }
  }
`;

// eslint-disable-next-line
gql`
  query GetBrandForBrandParsingSettingsView($id: String!) {
    brand(id: $id) {
      id
      ...BrandFragmentBrandParsingSettingsView
    }
  }
  ${BRAND_FRAGMENT_BRAND_PARSING_SETTINGS_VIEW}
`;

// eslint-disable-next-line
gql`
  mutation UpdateBrandForBrandParsingSettingsView($data: UpdateBrandInput!) {
    updateBrand(data: $data) {
      id
      ...BrandFragmentBrandParsingSettingsView
    }
  }
  ${BRAND_FRAGMENT_BRAND_PARSING_SETTINGS_VIEW}
`;

export type BrandParsingSettingsViewProps = {
  brandId: string;
  readOnly?: boolean;
  sx?: SxProps;
  componentsProps?: {
    setting?: Pick<BrandPlatformParsingSettingProps, 'sx' | 'componentsProps'>;
  };
};

export const BrandParsingSettingsView = (
  props: BrandParsingSettingsViewProps,
) => {
  const { brandId, readOnly, sx, componentsProps = {} } = props;

  const { isFeatureEnabled } = useFeatureFlagContext();

  const { data } = useGetBrandForBrandParsingSettingsViewQuery({
    variables: {
      id: brandId,
    },
  });
  const brand = data?.brand;

  // NOTE: Default values should be referenced from defaultParsingSettings in BE
  const brandParsingSettings = useMemo(
    () => ({
      [Platform.Instagram]: {
        enabled: brand?.parsingSettings?.Instagram.enabled ?? false,
        types: {
          [SocialPostType.Photo]:
            brand?.parsingSettings?.Instagram.types?.Photo ?? true,
          [SocialPostType.Video]:
            brand?.parsingSettings?.Instagram.types?.Video ?? true,
          [SocialPostType.Carousel]:
            brand?.parsingSettings?.Instagram.types?.Carousel ?? true,
        },
      },
      [Platform.Tiktok]: {
        enabled: brand?.parsingSettings?.Tiktok.enabled ?? false,
      },
      [Platform.Youtube]: {
        enabled: brand?.parsingSettings?.Youtube.enabled ?? false,
      },
    }),
    [brand],
  );

  const [updateBrand] = useUpdateBrandForBrandParsingSettingsViewMutation();

  // Ensure we only load internalParsingSettings from brand once
  // Otherwise, the useEffect depending on brand would potentially cause infinite loop
  const hasLoadedInternalParsingSettings = useRef(false);
  const [internalParsingSettings, setInternalParsingSettings] =
    useState<ParsingSettingsInputData>(brandParsingSettings);

  const {
    dialog: confirmationDialog,
    onOpen: openConfirmationDialog,
    onClose: closeConfirmationDialog,
  } = useConfirmationDialog();

  const handleTogglePlatform = (platform: Platform, value: boolean) => {
    // If disabling platform, show confirmation dialog
    if (!value) {
      openConfirmationDialog({
        title: `Pause brand from ${platform}?`,
        subtitle: (
          <>
            Pausing will stop collecting new data from {platform}. Don't worry -
            all your existing data will remain available.
            <br />
            <br />
            You can resume collecting new data from {platform} for this brand
            anytime in settings.
          </>
        ),
        confirmText: 'Pause brand',
        cancelText: 'Cancel',
        onConfirm: () => {
          setInternalParsingSettings((prev) => ({
            ...prev,
            [platform]: {
              enabled: value,
            },
          }));
          closeConfirmationDialog();
        },
        onCancel: closeConfirmationDialog,
      });
    } else {
      setInternalParsingSettings((prev) => ({
        ...prev,
        [platform]: {
          ...prev[platform],
          enabled: value,
        },
      }));
    }
  };

  const handleToggleType = (
    platform: Platform,
    type: SocialPostType,
    value: boolean,
  ) => {
    setInternalParsingSettings((prev) => ({
      ...prev,
      [platform]: {
        ...prev[platform],
        types: {
          ...prev[platform].types,
          [type]: value,
        },
      },
    }));
  };

  // Update parsing settings when internalParsingSettings changes
  useEffect(() => {
    if (!brand || !hasLoadedInternalParsingSettings.current) {
      return;
    }

    if (
      JSON.stringify(brandParsingSettings) !==
      JSON.stringify(internalParsingSettings)
    ) {
      updateBrand({
        variables: {
          data: {
            id: brand.id,
            data: {
              parsingSettings: internalParsingSettings,
            },
          },
        },
        optimisticResponse: {
          updateBrand: {
            ...brand,
            parsingSettings: internalParsingSettings,
          },
        },
      });
    }
  }, [internalParsingSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!brand || hasLoadedInternalParsingSettings.current) {
      return;
    }

    setInternalParsingSettings(brandParsingSettings);
    hasLoadedInternalParsingSettings.current = true;
  }, [brandParsingSettings, brand]);

  if (!brand) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          ...sx,
        }}
      >
        <BrandPlatformParsingSetting
          readOnly={readOnly}
          platform={Platform.Instagram}
          types={[
            SocialPostType.Photo,
            SocialPostType.Video,
            SocialPostType.Carousel,
          ]}
          platformChecked={internalParsingSettings.Instagram.enabled ?? false}
          typesChecked={{
            [SocialPostType.Photo]:
              internalParsingSettings.Instagram.types?.Photo ?? true,
            [SocialPostType.Video]:
              internalParsingSettings.Instagram.types?.Video ?? true,
            [SocialPostType.Carousel]:
              internalParsingSettings.Instagram.types?.Carousel ?? true,
          }}
          onTogglePlatform={(value) =>
            handleTogglePlatform(Platform.Instagram, value)
          }
          onToggleType={(type, value) =>
            handleToggleType(Platform.Instagram, type, value)
          }
          {...componentsProps.setting}
        />
        <BrandPlatformParsingSetting
          readOnly={readOnly}
          platform={Platform.Tiktok}
          platformChecked={internalParsingSettings.Tiktok.enabled ?? false}
          onTogglePlatform={(value) =>
            handleTogglePlatform(Platform.Tiktok, value)
          }
          {...componentsProps.setting}
        />
        {isFeatureEnabled(PlotFeature.YoutubeParsing) && (
          <BrandPlatformParsingSetting
            readOnly={readOnly}
            platform={Platform.Youtube}
            platformChecked={internalParsingSettings.Youtube.enabled ?? false}
            onTogglePlatform={(value) =>
              handleTogglePlatform(Platform.Youtube, value)
            }
            {...componentsProps.setting}
          />
        )}
      </Box>
      {confirmationDialog}
    </>
  );
};
