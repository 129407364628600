import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { getCreatorHandleWithPrefix } from 'features/creator/utils';
import { platformIconMap } from 'features/socialPost';
import { CreatorFragmentCreatorHandleChipFragment } from 'graphql/generated';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment CreatorFragmentCreatorHandleChip on CreatorModel {
    id
    handle
    platform
  }
`;

type CreatorHandleChipProps = {
  creators: CreatorFragmentCreatorHandleChipFragment[];
};

export const CreatorHandleChip = ({ creators }: CreatorHandleChipProps) => {
  return (
    <Tooltip
      title={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(3),
            my: 2,
          }}
        >
          {creators.map((creator) => {
            const Icon = platformIconMap[creator.platform];
            return (
              <Typography
                variant="subhead-sm"
                color={theme.colors?.primary.parchment}
                key={creator.id}
                component="div"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: theme.spacing(1),
                }}
              >
                <Icon size={16} color={theme.colors?.primary.parchment} />
                {creator.handle}
              </Typography>
            );
          })}
        </Box>
      }
    >
      <Typography
        variant="body-lg"
        color={theme.colors?.utility[800]}
        display="flex"
        alignItems="center"
        gap={2}
        sx={{
          whiteSpace: 'nowrap',
          mt: 1,
          width: 'fit-content',
          p: theme.spacing(1, 2),
          backgroundColor: 'rgba(35, 6, 3, 0.05)',
          borderRadius: theme.spacing(2),
        }}
      >
        {creators.map((creator) => {
          const Icon = platformIconMap[creator.platform];
          return <Icon size={16} />;
        })}
        ·&nbsp;&nbsp;{creators.length}{' '}
        {creators.length > 1 ? 'accounts' : 'account'}
      </Typography>
    </Tooltip>
  );
};
