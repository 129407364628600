import { gql } from '@apollo/client';
import { useDebounce } from '@dwarvesf/react-hooks';
import { Box, Card, TextField, Typography } from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import { IconOutlineSearchNormal1 } from 'components/icons/components/outline/IconOutlineSearchNormal1';
import {
  CommentEnrichmentType,
  CommentInsightsCategoryType,
  CommentTargetType,
  GetCompetitorCommentInsightsInput,
  SocialPostCommentFragmentCommentInsightsTableFragmentDoc,
  SortOrder,
  useGetCompetitorCommentInsightsForCompetitorCommentInsightsQuery,
} from 'graphql/generated';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { CompetitorCommentInsightsFilters } from './commentInsightsFilters';
import { CommentsInsightsTableForCompetitor } from './commentsInsightsTable/CommentsInsightsTableForCompetitor';
import { SocialMediaListeningCompetitorDetailCommentInsightsProps } from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetCompetitorCommentInsightsForCompetitorCommentInsights(
    $data: GetCompetitorCommentInsightsInput!
    $brandId: String!
  ) {
    getCompetitorCommentInsights(data: $data) {
      meta {
        totalCount
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      data {
        ...SocialPostCommentFragmentCommentInsightsTable
      }
    }
  }
  ${SocialPostCommentFragmentCommentInsightsTableFragmentDoc}
`;

export const CompetitorCommentInsights = ({
  currentBrandId,
  trackingBrandId,
  filters,
}: SocialMediaListeningCompetitorDetailCommentInsightsProps) => {
  const [sortData, setSortData] = useState<{
    key: string;
    order: SortOrder;
  }>();

  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    moment().subtract(7, 'days').toDate(),
    moment().toDate(),
  ]);
  const [selectedTargetTypes, setSelectedTargetTypes] = useState<
    CommentTargetType[]
  >([]);
  const [selectedEnrichmentTypes, setSelectedEnrichmentTypes] = useState<
    CommentEnrichmentType[]
  >([]);
  const [searchStr, setSearchStr] = useState('');
  const debouncedSearchStr = useDebounce(searchStr, 500);

  const payload: GetCompetitorCommentInsightsInput = useMemo(
    () => ({
      ...filters,
      filters: {
        organizationSocialPostCommentLabelIds: selectedLabels,
        categories: selectedCategories,
        commentText: debouncedSearchStr,
        commentTargetTypes: selectedTargetTypes,
        commentEnrichmentTypes: selectedEnrichmentTypes,
      },
      ...(sortData
        ? {
            sortBy: {
              field: sortData?.key,
              order: sortData?.order,
            },
          }
        : {}),
      take: 10,
    }),
    [
      filters,
      selectedLabels,
      selectedCategories,
      debouncedSearchStr,
      selectedTargetTypes,
      selectedEnrichmentTypes,
      sortData,
    ],
  );

  const {
    data: commentInsightsData,
    loading,
    fetchMore,
  } = useGetCompetitorCommentInsightsForCompetitorCommentInsightsQuery({
    variables: {
      brandId: currentBrandId,
      data: {
        ...payload,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Card
      sx={{
        borderRadius: 5,
        my: 2,
        p: 6,
        boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Box width="100%" display="flex" justifyContent="space-between">
        <Typography variant="headline-lg" fontSize={theme.spacing(5)}>
          Comment Insights
        </Typography>
        <CompetitorCommentInsightsFilters
          currentDateRange={dateRange}
          commentInsightsCategoryType={
            CommentInsightsCategoryType.CompetitorCommentInsights
          }
          platforms={filters.platforms || []}
          currentBrandId={currentBrandId}
          trackingBrandId={trackingBrandId}
          hideTopics
          onUpdateFilters={(filters) => {
            setSelectedLabels(filters.labels);
            setSelectedCategories(filters.categories);
            setSelectedProducts(filters.products);
            setDateRange(filters.dateRange);
            setSelectedTargetTypes(filters.commentTargetTypes || []);
            setSelectedEnrichmentTypes(filters.commentEnrichmentTypes || []);
          }}
        />
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
        gap={3}
      >
        <TextField
          variant="outlined"
          value={searchStr}
          onChange={(e) => setSearchStr(e.target.value)}
          InputProps={{
            startAdornment: (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: theme.spacing(6),
                  height: theme.spacing(6),
                  borderRadius: theme.spacing(100),
                  backgroundColor: theme.colors?.utility[250],
                }}
              >
                <IconOutlineSearchNormal1 size={16} />
              </Box>
            ),
          }}
          placeholder="Search"
          sx={{
            my: theme.spacing(4),
            width: '100%',
            borderRadius: theme.spacing(5),
            backgroundColor: theme.colors?.utility[250],
            '& .MuiOutlinedInput-root': {
              borderRadius: `${theme.spacing(5)} !important`,
              border: 'none',
            },
            '& .MuiInputBase-input': {
              ...typography['headline-sm'],
              borderRadius: `${theme.spacing(5)} !important`,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
        />
        <Typography
          textAlign="right"
          variant="body-lg"
          color={theme.colors?.utility[700]}
          style={{
            textDecoration: 'italic',
            minWidth: theme.spacing(22),
            whiteSpace: 'nowrap',
          }}
        >
          {commentInsightsData?.getCompetitorCommentInsights.meta.totalCount}{' '}
          comments
        </Typography>
      </Box>

      <Box
        sx={{
          mt: 6,
          borderBottom: `1px solid ${theme.colors?.utility[275]}`,
        }}
      />

      <CommentsInsightsTableForCompetitor
        sortData={sortData}
        setSortData={setSortData}
        currentBrandId={currentBrandId}
        trackingBrandId={trackingBrandId}
        comments={commentInsightsData?.getCompetitorCommentInsights.data || []}
        isLoading={loading}
        onLoadMore={() => {
          fetchMore({
            variables: {
              data: {
                ...payload,
                after:
                  commentInsightsData?.getCompetitorCommentInsights.pageInfo
                    .endCursor,
              },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              return {
                ...fetchMoreResult,
                getCompetitorCommentInsights: {
                  ...fetchMoreResult.getCompetitorCommentInsights,
                  data: [
                    ...prev.getCompetitorCommentInsights.data,
                    ...fetchMoreResult.getCompetitorCommentInsights.data,
                  ],
                },
              };
            },
          });
        }}
        hasMore={
          !!commentInsightsData?.getCompetitorCommentInsights.pageInfo
            .hasNextPage
        }
      />
    </Card>
  );
};
