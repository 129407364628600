import { SLAAnalyticsFilterByPlatform } from 'features/socialListeningAnalytics';
import { Platform, SocialPostType } from 'graphql/generated';

interface Props {
  brandId: string;
  selectedCreatorIds: string[];
  selectedPlatforms: Platform[];
  selectedPostTypes: SocialPostType[];
  onChange: (value: {
    selectedCreatorIds: string[];
    selectedPlatforms: Platform[];
    selectedPostTypes: SocialPostType[];
  }) => void;
}

export const BrandCompetitorCreatorSelector = ({
  brandId,
  selectedCreatorIds,
  selectedPlatforms,
  selectedPostTypes,
  onChange,
}: Props) => {
  return (
    <>
      <SLAAnalyticsFilterByPlatform
        brandId={brandId}
        onChange={({
          selectedCreatorIds,
          selectedPlatforms,
          selectedTypes,
        }) => {
          onChange({
            selectedCreatorIds,
            selectedPlatforms,
            selectedPostTypes: selectedTypes,
          });
        }}
        selectedCreatorIds={selectedCreatorIds}
        selectedPlatforms={selectedPlatforms}
        selectedTypes={selectedPostTypes}
      />
      {/* <IconButton
        sx={{
          borderRadius: 2,
          bgcolor: theme.colors?.utility[275],
          display: 'flex',
          alignItems: 'center',
          gap: 1.5,
          color: theme.colors?.primary.black,
        }}
        disableRipple
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      >
        <Box
          sx={{
            height: 24,
            width: 24,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 1,
            background: 'rgba(35, 6, 3, 0.05)',
          }}
        >
          <Icon size={16} />
        </Box>
        <Typography variant="subhead-xl">
          {getCreatorHandleWithPrefix(selectedCreator.handle)}
        </Typography>
        <IconLinearArrowDown size={16} color={theme.colors?.utility[700]} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          sx: {
            background: 'rgba(255, 255, 255, 0.80)',
            backdropFilter: 'blur(20px)',
            border: 'none',
            minWidth: 220,
            padding: 6,
            '& ul > :first-child': {
              display: 'none',
            },
          },
          style: {
            boxShadow:
              '0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12)',
          },
        }}
        MenuListProps={{
          sx: {
            ':hover': {
              background: 'transparent',
            },
          },
        }}
      >
        <DropDownMoreMenuItem
          disableRipple
          defaultOpen
          label=""
          componentsProps={{
            dropDownOptions: {
              container: {
                sx: {
                  gap: 2,
                },
              },
            },
          }}
          dropDownOptions={[
            {
              label: Platform.Instagram,
              value: Platform.Instagram,
              renderOption: () =>
                renderPlatformOption(instagramCreators, Platform.Instagram),
            },
            {
              label: '',
              renderOption: () => (
                <Divider
                  sx={{ my: 3, borderColor: theme.colors?.utility[500] }}
                />
              ),
            },
            {
              label: Platform.Tiktok,
              value: Platform.Tiktok,
              renderOption: () =>
                renderPlatformOption(tiktokCreators, Platform.Tiktok),
            },
            ...(isFeatureEnabled(PlotFeature.YoutubeParsing)
              ? [
                  {
                    label: '',
                    renderOption: () => (
                      <Divider
                        sx={{ my: 3, borderColor: theme.colors?.utility[500] }}
                      />
                    ),
                  },
                  {
                    label: Platform.Youtube,
                    value: Platform.Youtube,
                    renderOption: () =>
                      renderPlatformOption(youtubeCreators, Platform.Youtube),
                  },
                ]
              : []),
          ]}
        />
      </Menu> */}
    </>
  );
};
