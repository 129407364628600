import { gql } from '@apollo/client';
import { Box, Chip, Dialog, Grid, IconButton, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import {
  SLARankingGraph,
  SLARankingProductSocialPosts,
} from 'features/socialListeningAnalytics/components';
import {
  SocialMediaListeningFilterByDateRangeButton,
  SocialMediaListeningPostDetailView,
} from 'features/socialMediaListening';
import {
  BrandInboundFiltersInputForCapturedProduct,
  CapturedProductFragmentForSlaBrandInboundRankingProductDialogViewFragment,
  CapturedProductFragmentForTopProductsSectionFragmentDoc,
  CapturedProductGraphDataFragmentForSlaRankingGraphDataFragmentDoc,
  CapturedProductSectionSortField,
  CapturedProductSectionViewType,
  PaginatedBrandInboundFiltersInputForCapturedProductSortField,
  SocialPostFragmentSlaRankingProductSocialPostsFragmentDoc,
  SortOrder,
  useGetCapturedProductsSocialPostsForSlaBrandInboundRankingProductDialogViewQuery,
  useGetCapturedProductStatsForSlaBrandInboundRankingProductDialogViewQuery,
  useGetTopProductsForSlaBrandInboundRankingProductDialogViewQuery,
} from 'graphql/generated';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { AttributesSection, TopProductsSection } from './sections';

// eslint-disable-next-line
gql`
  query GetCapturedProductsSocialPostsForSLABrandInboundRankingProductDialogView(
    $data: PaginatedBrandInboundFiltersInputForCapturedProductSocialPosts!
  ) {
    capturedProductsSocialPostsPaginatedForInbound(data: $data) {
      data {
        ...SocialPostFragmentSLARankingProductSocialPosts
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${SocialPostFragmentSlaRankingProductSocialPostsFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetCapturedProductStatsForSLABrandInboundRankingProductDialogView(
    $data: BrandInboundFiltersInputForCapturedProduct!
  ) {
    capturedProductDataForInboundStats(data: $data) {
      percentChange
      totalEngagement
      totalPostsCount
      totalViewCount
      graphData {
        ...CapturedProductGraphDataFragmentForSLARankingGraphData
      }
      attributeUsage {
        count
        attribute
      }
    }
  }
  ${CapturedProductGraphDataFragmentForSlaRankingGraphDataFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetTopProductsForSLABrandInboundRankingProductDialogView(
    $data: BrandInboundFiltersInputForCapturedProduct!
    $count: Int!
  ) {
    capturedProductsPaginatedForInbound(data: $data) {
      data {
        ...CapturedProductFragmentForTopProductsSection
      }
    }
  }
  ${CapturedProductFragmentForTopProductsSectionFragmentDoc}
`;

// eslint-disable-next-line
gql`
  fragment CapturedProductFragmentForSLABrandInboundRankingProductDialogView on CapturedProductDataForInbound {
    id
    line
    model
    socialPostCount
    brand {
      id
      name
    }
    category {
      id
      name
    }
    attributeUsage {
      attribute
      count
      attributeId
    }
  }
`;

type SLABrandInboundRankingProductDialogViewProps = {
  filters: Omit<BrandInboundFiltersInputForCapturedProduct, 'sortBy'>;
  onClose: () => void;
  capturedProduct: CapturedProductFragmentForSlaBrandInboundRankingProductDialogViewFragment;
  // this is used for showing the dialog in the correct view
  currentViewType: CapturedProductSectionViewType;
};

export const SLABrandInboundRankingProductDialogView = ({
  filters,
  onClose,
  capturedProduct,
  currentViewType,
}: SLABrandInboundRankingProductDialogViewProps) => {
  const { brandId } = filters;
  const [socialPostsSortKey, setSocialPostsSortKey] =
    useState<PaginatedBrandInboundFiltersInputForCapturedProductSortField>(
      PaginatedBrandInboundFiltersInputForCapturedProductSortField.TotalEngagement,
    );
  const [topProductsSortKey, setTopProductsSortKey] =
    useState<CapturedProductSectionSortField>(
      CapturedProductSectionSortField.NumberOfPosts,
    );
  const [currentPostId, setCurrentPostId] = useState('');

  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(7, 'days').toDate(),
    endDate: moment().toDate(),
  });

  useEffect(() => {
    if (filters.dateRange) {
      setDateRange(filters.dateRange);
    }
  }, [filters.dateRange]);

  const { data: productStatesData } =
    useGetCapturedProductStatsForSlaBrandInboundRankingProductDialogViewQuery({
      variables: {
        data: {
          ...filters,
          dateRange,
          sortBy: {
            field: topProductsSortKey,
            order: SortOrder.Desc,
          },
        },
      },
      fetchPolicy: 'cache-and-network',
    });

  const productStates = productStatesData?.capturedProductDataForInboundStats;

  const socialPostsVariables = useMemo(() => {
    const { viewType, ...rest } = filters;
    return {
      data: {
        ...rest,
        dateRange,
        sortBy: {
          field: socialPostsSortKey,
          order: SortOrder.Desc,
        },
      },
    };
  }, [filters, dateRange, socialPostsSortKey]);

  const { data: socialPostsData, fetchMore: fetchMoreSocialPosts } =
    useGetCapturedProductsSocialPostsForSlaBrandInboundRankingProductDialogViewQuery(
      {
        variables: socialPostsVariables,
        fetchPolicy: 'cache-and-network',
      },
    );

  const socialPosts =
    socialPostsData?.capturedProductsSocialPostsPaginatedForInbound.data || [];

  const { data: topProductsData } =
    useGetTopProductsForSlaBrandInboundRankingProductDialogViewQuery({
      variables: {
        data: {
          ...filters,
          dateRange,
          take: 4,
          sortBy: {
            field: CapturedProductSectionSortField.NumberOfPosts,
            order: SortOrder.Desc,
          },
        },
        count: 1,
      },
      skip: currentViewType === CapturedProductSectionViewType.ProductLineModel,
      fetchPolicy: 'cache-and-network',
    });

  const summaryData = useMemo(
    () => [
      {
        label: 'Total post count',
        value: productStates?.totalPostsCount || 0,
        tooltip: 'Total post count',
      },
      {
        label: 'Total view count',
        value: formatBigNumber(productStates?.totalViewCount || 0),
      },
      {
        label: 'Total engagement',
        value: formatBigNumber(productStates?.totalEngagement || 0),
      },
    ],
    [productStates],
  );

  const renderSummary = useCallback((summary, sx) => {
    return (
      <Box
        flexDirection="column"
        borderRadius={2}
        display="flex"
        alignItems="flex-start"
        gap={1}
        pl={4}
        sx={sx}
      >
        <Typography
          variant="headline-sm"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          {summary.label}
          {summary.tooltip && (
            <Tooltip title={summary.tooltip}>
              <Box component="span" display="flex" alignItems="center">
                <IconBoldInfoCircle
                  size={16}
                  color={theme.colors?.utility[600]}
                />
              </Box>
            </Tooltip>
          )}
        </Typography>
        <Typography
          fontSize={theme.spacing(8)}
          variant="headline-xl"
          color={theme.colors?.utility[900]}
          fontWeight={500}
          mt={2}
          letterSpacing="-1.28px"
          className="summary-value"
        >
          {summary.value}
        </Typography>
      </Box>
    );
  }, []);

  const productTitle = useMemo(() => {
    return currentViewType === CapturedProductSectionViewType.ProductLineModel
      ? capturedProduct.line
      : currentViewType === CapturedProductSectionViewType.ProductBrand
      ? capturedProduct.brand?.name
      : capturedProduct.category?.name;
  }, [capturedProduct, currentViewType]);

  const handleBackClick = () => {
    if (currentPostId) {
      setCurrentPostId('');
    } else {
      onClose();
    }
  };

  const isNegativePercentChange = useMemo(() => {
    return productStates?.percentChange
      ? productStates?.percentChange < 0
      : false;
  }, [productStates]);

  return (
    <Dialog
      open
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '90vw',
          height: '90vh',
          backgroundColor: '#FAF3EC88',
          maxWidth: 'unset',
          maxHeight: 'unset',
          backdropFilter: 'blur(20px)',
          borderRadius: 4,
          margin: 'auto',
        },
      }}
    >
      <Box display="flex" flexDirection="column" height="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: theme.colors?.primary.parchment,
            borderBottom: `2px solid rgba(35, 6, 3, 0.10)`,
            p: 4,
          }}
        >
          <Box display="flex" alignItems="center" gap={3}>
            <IconButton
              onClick={handleBackClick}
              disableRipple
              sx={{
                color: theme.colors?.primary.black,
              }}
            >
              <IconOutlineArrowLeft size={20} />
            </IconButton>
            <Typography variant="headline-sm">
              {productTitle}
              {currentViewType ===
                CapturedProductSectionViewType.ProductLineModel &&
                capturedProduct.model &&
                `${capturedProduct.line && ' / '}${capturedProduct.model}`}
            </Typography>
          </Box>

          <IconButton onClick={onClose} disableRipple>
            <IconBoldCloseCircle
              style={{ color: theme.colors?.utility[700] }}
              size={24}
            />
          </IconButton>
        </Box>

        {currentPostId ? (
          <Box
            px={12}
            sx={{
              backgroundColor: theme.colors?.primary.parchment,
              minHeight: theme.spacing(100),
              overflow: 'auto',
            }}
          >
            <Box
              p={4}
              my={8}
              sx={{
                backgroundColor: theme.colors?.primary.white,
                borderRadius: theme.spacing(4),
              }}
            >
              <SocialMediaListeningPostDetailView
                brandId={brandId}
                socialPostId={currentPostId}
                componentProps={{
                  modalView: true,
                }}
              />
            </Box>
          </Box>
        ) : (
          <Box
            p={3}
            flexGrow={1}
            overflow="auto"
            sx={{ backgroundColor: '#FAF3ECCC' }}
          >
            <InfiniteScroll
              loadMore={() => {
                fetchMoreSocialPosts({
                  variables: {
                    data: {
                      ...socialPostsVariables.data,
                      after:
                        socialPostsData
                          ?.capturedProductsSocialPostsPaginatedForInbound
                          .pageInfo.endCursor,
                    },
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return {
                      capturedProductsSocialPostsPaginatedForInbound: {
                        ...fetchMoreResult.capturedProductsSocialPostsPaginatedForInbound,
                        data: [
                          ...prev.capturedProductsSocialPostsPaginatedForInbound
                            .data,
                          ...(fetchMoreResult
                            ?.capturedProductsSocialPostsPaginatedForInbound
                            .data || []),
                        ],
                      },
                    };
                  },
                });
              }}
              hasMore={
                socialPostsData?.capturedProductsSocialPostsPaginatedForInbound
                  .pageInfo.hasNextPage
              }
              threshold={50}
              useWindow={false}
            >
              <Box
                display="flex"
                alignItems="flex-start"
                gap={2}
                mb={3}
                justifyContent="space-between"
                width="100%"
                sx={{
                  p: 4,
                }}
              >
                <Box display="flex" flexDirection="column">
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 3,
                    }}
                  >
                    <Typography
                      variant="headline-xl"
                      fontSize={24}
                      fontWeight={500}
                      letterSpacing="-0.96px"
                    >
                      {productTitle ?? '-'}
                    </Typography>
                    {currentViewType ===
                      CapturedProductSectionViewType.ProductLineModel &&
                      capturedProduct.model && (
                        <Chip
                          label={capturedProduct.model}
                          sx={{
                            background: 'rgba(35, 6, 3, 0.30)',
                            color: theme.colors?.primary.white,
                            ...theme.typography['headline-sm'],
                          }}
                        />
                      )}
                  </Box>

                  <Typography
                    variant="headline-sm"
                    mt={1}
                    color={theme.colors?.utility[700]}
                  >
                    {formatBigNumber(productStates?.totalPostsCount || 0)} posts
                  </Typography>
                </Box>

                <SocialMediaListeningFilterByDateRangeButton
                  onChange={(range) => {
                    if (range[0] && range[1]) {
                      setDateRange({
                        startDate: range[0],
                        endDate: range[1],
                      });
                    }
                  }}
                  selectedDateRange={[dateRange.startDate, dateRange.endDate]}
                  componentProps={{
                    sx: {
                      backgroundColor: theme.colors?.primary.parchment,
                    },
                  }}
                />
              </Box>

              <Box sx={{ m: 4 }}>
                <Grid
                  container
                  spacing={2}
                  mb={3}
                  sx={{
                    backgroundColor: theme.colors?.primary.parchment,
                    borderRadius: 2,
                    p: 4,
                  }}
                >
                  {summaryData.map((summary, index) => (
                    <Grid
                      item
                      lg={3}
                      md={2.5}
                      sm={6}
                      xs={12}
                      key={index}
                      sx={{
                        borderRight: { md: `2px solid rgba(35, 6, 3, 0.07)` },
                      }}
                    >
                      {renderSummary(summary, {})}
                    </Grid>
                  ))}
                  <Grid item xs={12} sm={6} md={4.5} lg={3}>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 3,
                      }}
                    >
                      {renderSummary(
                        {
                          label: 'Percentage change',
                          value: `${
                            productStates?.percentChange === 0
                              ? ''
                              : isNegativePercentChange
                              ? '↓ '
                              : '↑ '
                          }${
                            productStates?.percentChange
                              ? productStates?.percentChange
                                  .toFixed(2)
                                  .replace('-', '')
                              : 0
                          }%`,
                          tooltip: 'Percentage change',
                        },
                        {
                          whiteSpace: 'nowrap',
                          '.summary-value': {
                            color:
                              theme.colors?.utility[
                                productStates?.percentChange === 0
                                  ? '900'
                                  : isNegativePercentChange
                                  ? 'pink-3'
                                  : 'green-3'
                              ],
                          },
                        },
                      )}
                      {productStates?.graphData &&
                        productStates.graphData.length > 1 && (
                          <SLARankingGraph
                            graphData={productStates.graphData}
                            isNegativePercentChange={
                              isNegativePercentChange ?? false
                            }
                            chartHeight={70}
                            chartWidth="100%"
                          />
                        )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {currentViewType ===
                CapturedProductSectionViewType.ProductCategory &&
                !!capturedProduct.attributeUsage.length && (
                  <AttributesSection
                    attributeUsage={capturedProduct.attributeUsage}
                  />
                )}

              {currentViewType !==
                CapturedProductSectionViewType.ProductLineModel &&
                !!topProductsData?.capturedProductsPaginatedForInbound.data
                  .length && (
                  <TopProductsSection
                    topProducts={
                      topProductsData.capturedProductsPaginatedForInbound.data
                    }
                    viewType={currentViewType}
                    selectedSortKey={topProductsSortKey}
                    setSelectedSortKey={setTopProductsSortKey}
                  />
                )}

              <Box
                sx={{
                  borderBottom: `2px solid rgba(35, 6, 3, 0.05)`,
                  my: 6,
                  mx: 2,
                }}
              />

              <SLARankingProductSocialPosts
                socialPosts={socialPosts}
                onPostClick={setCurrentPostId}
                setSelectedSortKey={setSocialPostsSortKey}
                selectedSortKey={socialPostsSortKey}
              />
            </InfiniteScroll>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};
