import { Box, Skeleton, TableCell, TableRow } from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import { theme } from 'styles/theme';

export const BrandCompetitorsListViewSkeleton = () => {
  return (
    <TableRow
      sx={{
        height: theme.spacing(22),
        '&:hover': {
          backgroundColor: theme.colors?.utility[275],
          cursor: 'pointer',
        },
      }}
    >
      <TableCell>
        <Skeleton sx={{ width: theme.spacing(2), height: theme.spacing(10) }} />
      </TableCell>
      <TableCell>
        <Skeleton
          variant="circular"
          sx={{ width: theme.spacing(10), height: theme.spacing(10) }}
        />
      </TableCell>
      <TableCell
        sx={{
          ...typography['headline-sm'],
        }}
      >
        <Skeleton
          sx={{ width: theme.spacing(30), height: theme.spacing(10) }}
        />
      </TableCell>
      <TableCell
        sx={{
          ...typography['body-lg'],
          fontWeight: 600,
        }}
      >
        <Skeleton
          sx={{ width: theme.spacing(20), height: theme.spacing(10) }}
        />
      </TableCell>
      <TableCell
        sx={{
          ...typography['body-lg'],
          fontWeight: 600,
        }}
      >
        <Skeleton
          sx={{ width: theme.spacing(20), height: theme.spacing(10) }}
        />
      </TableCell>
      <TableCell
        sx={{
          ...typography['body-lg'],
          fontWeight: 600,
        }}
      >
        <Skeleton
          sx={{ width: theme.spacing(20), height: theme.spacing(10) }}
        />
      </TableCell>

      <TableCell
        sx={{
          ...typography['body-lg'],
          fontWeight: 500,
        }}
      >
        <Skeleton
          sx={{ width: theme.spacing(20), height: theme.spacing(10) }}
        />
      </TableCell>
      <TableCell
        sx={{
          ...typography['body-lg'],
          fontWeight: 500,
        }}
      >
        <Box display="flex" gap={2} alignItems="center">
          {new Array(3).fill(0).map(() => (
            <Skeleton
              sx={{
                width: Math.floor(Math.random() * (75 - 20 + 1)) + 40,
                height: theme.spacing(18),
              }}
            />
          ))}
        </Box>
      </TableCell>
    </TableRow>
  );
};
