import { gql } from '@apollo/client';
import { Box, SxProps } from '@mui/material';
import { platformIconMap } from 'features/socialPost/constants';
import { SocialPostFragmentSocialPostPlatformFragment } from 'graphql/generated';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  fragment SocialPostFragmentSocialPostPlatform on SocialPostModel {
    id
    platform
  }
`;

type SocialPostPlatformProps = {
  socialPost: SocialPostFragmentSocialPostPlatformFragment;
  componentProps?: {
    sx?: SxProps;
  };
};

export const SocialPostPlatform = (props: SocialPostPlatformProps) => {
  const { socialPost, componentProps } = props;

  const Icon = platformIconMap[socialPost.platform];

  return (
    <Box sx={componentProps?.sx}>
      <Icon color={theme.colors?.primary.white} size={16} />
    </Box>
  );
};
