import { gql } from '@apollo/client';
import { Box, Button, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { RadioMenuItem } from 'components/common/form/Select/RadioMenuItem';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { SLATopCreators } from 'features/socialListeningAnalytics/components/slaTopCreators';
import {
  BrandContentType,
  BrandInboundFiltersInput,
  BrandInboundFiltersInputForTopCreatorsSortField,
  CreatorFragmentSlaTopCreatorsFragmentDoc,
  useGetBrandInboundTopCreatorsForSlaBrandInboundTopCreatorsViewQuery,
} from 'graphql/generated';
import { useState } from 'react';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { SLABrandInboundTopCreatorsSkeleton } from './SLABrandInboundTopCreatorsSkeleton';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetBrandInboundTopCreatorsForSLABrandInboundTopCreatorsView(
    $data: PaginatedBrandInboundFiltersInputForTopCreators!
  ) {
    brandInboundTopCreators(data: $data) {
      data {
        __typename
        id
        ...CreatorFragmentSLATopCreators
      }
    }
  }
  ${CreatorFragmentSlaTopCreatorsFragmentDoc}
`;

type Props = {
  filters: BrandInboundFiltersInput;
  onSelectCreator: (creatorId: string) => void;
};

export const SLABrandInboundTopCreatorsView = ({
  filters,
  onSelectCreator,
}: Props) => {
  const [sortKey, setSortKey] = useState(
    BrandInboundFiltersInputForTopCreatorsSortField.NumberOfBrandMentions,
  );

  const { data, loading } =
    useGetBrandInboundTopCreatorsForSlaBrandInboundTopCreatorsViewQuery({
      variables: {
        data: {
          ...filters,
          sortBy: {
            field: sortKey,
          },
        },
      },
      skip:
        filters.contentType === BrandContentType.Topic &&
        !filters.topicIds?.length,
    });

  const creatorsData = data?.brandInboundTopCreators.data;

  if (
    loading ||
    (filters.contentType === BrandContentType.Topic &&
      !filters.topicIds?.length)
  ) {
    return <SLABrandInboundTopCreatorsSkeleton />;
  }

  return (
    <SLATopCreators
      brandId={filters.brandId}
      creatorsData={(creatorsData || []).map((creator) => {
        return [
          creator,
          sortKey ===
          BrandInboundFiltersInputForTopCreatorsSortField.NumberOfBrandMentions
            ? String(formatBigNumber(creator.numberOfBrandMentions || 0))
            : String(formatBigNumber(creator.followerCount)),
        ];
      })}
      onCreatorIdSelect={onSelectCreator}
      renderFilter={() => (
        <ContextMenu
          sx={{
            '& .context-menu-item': {
              p: 0,
              color: theme.colors?.primary.black,
            },
          }}
          options={[
            {
              renderOption: () => (
                <RadioMenuItem
                  label="Number of posts"
                  value={
                    BrandInboundFiltersInputForTopCreatorsSortField.NumberOfBrandMentions
                  }
                  checked={
                    sortKey ===
                    BrandInboundFiltersInputForTopCreatorsSortField.NumberOfBrandMentions
                  }
                  sx={{
                    p: `${theme.spacing(2, 3)}`,
                  }}
                />
              ),
              onClick: () => {
                setSortKey(
                  BrandInboundFiltersInputForTopCreatorsSortField.NumberOfBrandMentions,
                );
              },
            },
            {
              renderOption: () => (
                <RadioMenuItem
                  label="Number of followers"
                  value={
                    BrandInboundFiltersInputForTopCreatorsSortField.FollowerCount
                  }
                  checked={
                    sortKey ===
                    BrandInboundFiltersInputForTopCreatorsSortField.FollowerCount
                  }
                  sx={{
                    p: `${theme.spacing(2, 3)}`,
                  }}
                />
              ),
              onClick: () => {
                setSortKey(
                  BrandInboundFiltersInputForTopCreatorsSortField.FollowerCount,
                );
              },
            },
          ]}
          renderButton={() => (
            <Button
              sx={{
                backgroundColor: theme.colors?.utility[275],
                color: theme.colors?.primary.black,
                borderRadius: theme.spacing(2),
                display: 'flex',
                gap: 2,
                p: theme.spacing(1, 2),
                alignItems: 'center',
                '&:hover': { backgroundColor: theme.colors?.utility[275] },
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  backgroundColor: 'rgba(35, 6, 3, 0.05);',
                  p: theme.spacing(1),
                  borderRadius: theme.spacing(1),
                }}
              >
                <IconLinearSort size={16} />
              </Box>
              <Typography fontWeight={500} variant="body-xl">
                {sortKey ===
                  BrandInboundFiltersInputForTopCreatorsSortField.NumberOfBrandMentions &&
                  `Number of ${
                    filters.contentType === BrandContentType.Topic
                      ? 'topic'
                      : 'brand'
                  } mentions`}
                {sortKey ===
                  BrandInboundFiltersInputForTopCreatorsSortField.FollowerCount &&
                  'Number of followers'}
              </Typography>
            </Button>
          )}
        />
      )}
    />
  );
};
