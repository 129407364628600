import { Box, IconButton, Menu, Typography } from '@mui/material';
import { IconBoldSetting5 } from 'components/icons/components/bold/IconBoldSetting5';
import {
  SLAAnalyticsFilterByAttributes,
  SLAAnalyticsFilterByBrands,
  SLAAnalyticsFilterByCategory,
  SLAAnalyticsFilterByDemographics,
  SLAAnalyticsFilterByProductLineModel,
  SLAAnalyticsFilterBySource,
} from 'features/socialListeningAnalytics';
import {
  CompetitorCreatorSocialPostContentFilter,
  CompetitorFlatAnalyticFiltersInput,
} from 'graphql/generated';
import { useState } from 'react';
import { theme } from 'styles/theme';

interface BrandCompetitorFilterButtonProps {
  selectedFilters: CompetitorFlatAnalyticFiltersInput;
  onUpdateFilters: (
    filters: Omit<
      CompetitorFlatAnalyticFiltersInput,
      'contentFilter' | 'competitorId' | 'dateRange'
    >,
  ) => void;
  dateRange: { startDate: Date; endDate: Date };
}

export const BrandCompetitorFilterButton = ({
  onUpdateFilters,
  selectedFilters,
  dateRange,
}: BrandCompetitorFilterButtonProps) => {
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const filterOpen = Boolean(filterAnchorEl);
  return (
    <>
      <IconButton
        sx={{
          bgcolor: theme.colors?.utility[275],
          borderRadius: theme.spacing(2),
          p: theme.spacing(2),
          color: theme.colors?.primary.black,
        }}
        disableRipple
        onClick={(e) => {
          setFilterAnchorEl(e.currentTarget);
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: theme.spacing(2),
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              backgroundColor: 'rgba(35, 6, 3, 0.05)',
              borderRadius: theme.spacing(1),
              padding: theme.spacing(1),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IconBoldSetting5
              size={16}
              style={{
                color: theme.colors?.primary.black,
              }}
            />
          </Box>

          <Typography variant="subhead-xl">Filter</Typography>
        </Box>
      </IconButton>
      <Menu
        anchorEl={filterAnchorEl}
        open={filterOpen}
        onClose={() => setFilterAnchorEl(null)}
        PaperProps={{
          sx: {
            minWidth: 260,
            maxWidth: 260,
            padding: 4,
            background: 'rgba(255, 255, 255, 0.80)',
            backdropFilter: 'blur(20px)',
            boxShadow:
              '0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12)',
            border: 'none',
            maxHeight: 500,
            '&::-webkit-scrollbar': {
              width: 0,
            },
          },
        }}
      >
        {selectedFilters.contentFilter ===
          CompetitorCreatorSocialPostContentFilter.Ugc && (
          <>
            <SLAAnalyticsFilterByDemographics
              selectedGender={selectedFilters?.gender ?? []}
              selectedGeneration={selectedFilters?.generation ?? []}
              onChange={({ selectedGender, selectedGeneration }) => {
                onUpdateFilters({
                  gender: selectedGender,
                  generation: selectedGeneration,
                });
              }}
            />

            <SLAAnalyticsFilterBySource
              selectedSources={selectedFilters?.sources ?? []}
              onChange={(selectedSources) => {
                onUpdateFilters({ sources: selectedSources });
              }}
            />
          </>
        )}

        <SLAAnalyticsFilterByBrands
          selectedBrandIds={selectedFilters?.capturedProductBrandIds ?? []}
          onChange={(selectedBrandIds) => {
            onUpdateFilters({
              capturedProductBrandIds: selectedBrandIds,
            });
          }}
          filters={{
            dateRange,
          }}
        />
        <SLAAnalyticsFilterByProductLineModel
          selectedProductLineModels={
            selectedFilters?.capturedProductLineModelInputs ?? []
          }
          onChange={(models) => {
            onUpdateFilters({
              capturedProductLineModelInputs: models,
            });
          }}
          filters={{
            dateRange,
          }}
        />
        <SLAAnalyticsFilterByCategory
          selectedCategoryIds={
            selectedFilters?.capturedProductCategoryIds ?? []
          }
          onChange={(ids) => {
            onUpdateFilters({
              capturedProductCategoryIds: ids,
            });
          }}
          filters={{
            dateRange,
          }}
        />
        <SLAAnalyticsFilterByAttributes
          selectedAttributesIds={
            selectedFilters?.capturedProductAttributeIds ?? []
          }
          onChange={(selectedAttributesIds) => {
            onUpdateFilters({
              capturedProductAttributeIds: selectedAttributesIds,
            });
          }}
          filters={{
            dateRange,
          }}
        />
      </Menu>
    </>
  );
};
