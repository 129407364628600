import { gql } from '@apollo/client';
import {
  SearchTermFragmentForUseBrandSearchTermsForSocialListeningOnboardingFragmentDoc,
  useCreateSearchTermForUseBrandSearchTermsForSocialListeningOnboardingMutation,
} from 'graphql/generated';
import { modifyObject } from 'utils/apollo';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment SearchTermFragmentForUseBrandSearchTermsForSocialListeningOnboarding on SearchTermModel {
    id
    term
    isHashTag
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation CreateSearchTermForUseBrandSearchTermsForSocialListeningOnboarding(
    $data: CreateSearchTermInput!
  ) {
    createSearchTerms(data: $data) {
      id
      ...SearchTermFragmentForUseBrandSearchTermsForSocialListeningOnboarding
    }
  }
  ${SearchTermFragmentForUseBrandSearchTermsForSocialListeningOnboardingFragmentDoc}
`;

export const useBrandSearchTermsForSocialListeningOnboarding = () => {
  const [createSearchTerms] =
    useCreateSearchTermForUseBrandSearchTermsForSocialListeningOnboardingMutation();

  const handleCreateSearchTerms = async (
    topicId: string,
    terms: string[],
    allowAutomaticUpdate = false,
  ) => {
    return createSearchTerms({
      variables: {
        data: {
          terms,
          topicId,
          allowToUpdateSearchTerm: allowAutomaticUpdate,
        },
      },
      update: (cache, { data }) => {
        modifyObject(cache, topicId, 'TopicModel', {
          searchTerms: () => [...(data?.createSearchTerms || [])],
          allowToUpdateSearchTerm: () => allowAutomaticUpdate,
        });
      },
    });
  };

  return {
    searchTermActions: {
      handleCreateSearchTerms,
    },
  };
};
