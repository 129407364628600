import { Box, Grid } from '@mui/material';
import { IconBoldSettings2 } from 'components/icons/components/bold/IconBoldSettings2';
import { IconOutlineCalendar1 } from 'components/icons/components/outline/IconOutlineCalendar1';
import {
  BrandCompetitorContentTypeSelector,
  BrandCompetitorCreatorSelector,
  BrandCompetitorFilterButton,
  BrandCompetitorInfo,
  BrandCompetitorInfoSkeleton,
} from 'features/brandCompetitors';
import { CompetitorCreatorDemographics } from 'features/brandCompetitors/components/competitorCreatorDemographics';
import { CompetitorTopCreators } from 'features/brandCompetitors/components/competitorTopCreators';
import {
  SLAAnalyticsSelectedFilterItem,
  SLAAnalyticsSelectedFilterItemType,
} from 'features/socialListeningAnalytics/components/slaAnalyticsSelectedFilterItem';
import {
  SLPageBrandSelector,
  SocialMediaListeningFilterByDateRangeButton,
} from 'features/socialMediaListening';
import { BrandParsedHours } from 'features/socialMediaListening/components/brandParsedHours';
import {
  CompetitorCreatorSocialPostContentFilter,
  CompetitorFlatAnalyticFiltersInput,
  SocialPostGenderDemographic,
  SocialPostGenerationDemographic,
  useGetCompetitorForBrandCompetitorDetailQuery,
} from 'graphql/generated';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { BrandCompetitorGraph } from '../../components/brandCompetitorGraph';
import { CompetitorCommentInsights } from '../../components/commentInsights';
import { CompetitorSentiment } from '../../components/competitorSentiment';
import { CompetitorSummary } from '../../components/competitorSummary/CompetitorSummary';
import { CompetitorTopHashtags } from '../../components/competitorTopHashtags';
import { CompetitorTopPosts } from '../../components/competitorTopPosts';
import { BrandCompetitorCapturedProductsView } from '../brandCompetitorCapturedProducts';

type Props = {
  currentBrandId: string;
  competitorId: string;
};

export const BrandCompetitorDetailView = ({
  currentBrandId,
  competitorId,
}: Props) => {
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const creatorId = params.get('creatorId');

  const [selectedFilters, setSelectedFilters] =
    useState<CompetitorFlatAnalyticFiltersInput>({
      competitorId,
      contentFilter: CompetitorCreatorSocialPostContentFilter.Owned,
      dateRange: {
        startDate: moment().subtract(7, 'days').startOf('day').toDate(),
        endDate: moment().startOf('day').toDate(),
      },
      gender: Object.values(SocialPostGenderDemographic).filter(
        (g) => g !== SocialPostGenderDemographic.NotSpecified,
      ),
      generation: Object.values(SocialPostGenerationDemographic).filter(
        (g) =>
          ![
            SocialPostGenerationDemographic.NotSpecified,
            SocialPostGenerationDemographic.GenAlpha,
          ].includes(g),
      ),
    });

  const updateFilters = useCallback(
    (newFilters: Partial<CompetitorFlatAnalyticFiltersInput>) => {
      setSelectedFilters((prevFilters) => {
        const updatedFilters = { ...prevFilters, ...newFilters };
        if (JSON.stringify(updatedFilters) !== JSON.stringify(prevFilters)) {
          return updatedFilters;
        }
        return prevFilters;
      });
    },
    [setSelectedFilters],
  );

  const renderSelectedFilters = useMemo(() => {
    const selectedFilterTypes: {
      type: SLAAnalyticsSelectedFilterItemType;
      length: number;
    }[] = [
      {
        type: 'demographic',
        length:
          (selectedFilters.gender?.length ||
            selectedFilters.generation?.length) ??
          0,
      },
      { type: 'source', length: selectedFilters.sources?.length ?? 0 },
      {
        type: 'capturedProductBrand',
        length: selectedFilters.capturedProductBrandIds?.length ?? 0,
      },
      {
        type: 'capturedProductProductLineModel',
        length: selectedFilters.capturedProductLineModelInputs?.length ?? 0,
      },
      {
        type: 'capturedProductAttribute',
        length: selectedFilters.capturedProductAttributeIds?.length ?? 0,
      },
      {
        type: 'capturedProductCategory',
        length: selectedFilters.capturedProductCategoryIds?.length ?? 0,
      },
    ];

    if (!selectedFilterTypes.some((filter) => filter.length)) {
      return null;
    }
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        {selectedFilterTypes.map(({ type, length }) => {
          if (!length) return null;

          return (
            <SLAAnalyticsSelectedFilterItem
              key={type}
              selectedFilters={selectedFilters}
              onChange={(filters) => {
                updateFilters({
                  ...selectedFilters,
                  capturedProductAttributeIds:
                    filters.capturedProductAttributeIds,
                  capturedProductBrandIds: filters.capturedProductBrandIds,
                  capturedProductCategoryIds:
                    filters.capturedProductCategoryIds,
                  capturedProductLineModelInputs:
                    filters.capturedProductLineModelInputs,
                  sources: filters.sources,
                  gender: filters.gender,
                  generation: filters.generation,
                });
              }}
              type={type}
            />
          );
        })}
      </Box>
    );
  }, [selectedFilters, updateFilters]);

  const { data: competitorData, loading: loadingCompetitorData } =
    useGetCompetitorForBrandCompetitorDetailQuery({
      variables: {
        competitorId,
      },
    });

  return (
    <Box mt={16} width="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={4}
        px={8}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          my={2}
        >
          <SLPageBrandSelector
            generateUrl={(brandId) =>
              PlotRoutes.socialListeningCompetitors(brandId)
            }
          />
        </Box>
        <Box marginLeft="auto" display="flex" alignItems="center" columnGap={2}>
          <BrandParsedHours />
          {currentBrandId && (
            <Box
              borderRadius={10}
              bgcolor={theme.colors?.utility[275]}
              padding={3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: 'center',
              }}
              component="button"
              onClick={() => {
                navigate(
                  PlotRoutes.socialListeningSettings({
                    brandId: currentBrandId,
                  }),
                );
              }}
            >
              <IconBoldSettings2 size={22} color={theme.colors?.utility[600]} />
            </Box>
          )}
        </Box>
      </Box>

      <Box px={8}>
        {loadingCompetitorData || !competitorData ? (
          <BrandCompetitorInfoSkeleton />
        ) : (
          <BrandCompetitorInfo competitor={competitorData.competitor} />
        )}
      </Box>

      <Box
        sx={{
          borderBottom: `1px solid ${theme.colors?.utility[300]}`,
          borderTop: `1px solid ${theme.colors?.utility[300]}`,
          position: 'sticky',
          top: 64,
          zIndex: 10,
          backgroundColor: theme.colors?.utility[100],
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          width: '100%',
          flexDirection: 'column',
          p: theme.spacing(4, 8),
          my: 6,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <BrandCompetitorContentTypeSelector
            creatorProfilePictureUrl={
              competitorData?.competitor.trackingBrand?.creators[0]
                ?.profilePictureUrl || ''
            }
            selectedContentType={selectedFilters.contentFilter}
            onChange={(contentFilter) => updateFilters({ contentFilter })}
          />
          <Box display="flex" alignItems="center" gap={4}>
            {competitorData?.competitor && (
              <BrandCompetitorCreatorSelector
                brandId={competitorData?.competitor.trackingBrand.id}
                selectedCreatorIds={selectedFilters.creatorIds || []}
                selectedPlatforms={selectedFilters.platforms || []}
                selectedPostTypes={selectedFilters.postTypes || []}
                onChange={(value) => {
                  updateFilters({
                    creatorIds: value.selectedCreatorIds,
                    platforms: value.selectedPlatforms,
                    postTypes: value.selectedPostTypes,
                  });
                }}
              />
            )}
            <SocialMediaListeningFilterByDateRangeButton
              onChange={(range) => {
                if (range[0] && range[1]) {
                  updateFilters({
                    dateRange: {
                      startDate: range[0],
                      endDate: range[1],
                    },
                  });
                }
              }}
              selectedDateRange={[
                selectedFilters.dateRange?.startDate,
                selectedFilters.dateRange?.endDate,
              ]}
              componentProps={{
                startIcon: (
                  <Box
                    sx={{
                      height: 24,
                      width: 24,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 1,
                      background: 'rgba(35, 6, 3, 0.05)',
                    }}
                  >
                    <IconOutlineCalendar1 size={16} />
                  </Box>
                ),
              }}
            />
            <BrandCompetitorFilterButton
              onUpdateFilters={updateFilters}
              dateRange={{
                startDate: selectedFilters.dateRange.startDate,
                endDate: selectedFilters.dateRange.endDate,
              }}
              selectedFilters={selectedFilters}
            />
          </Box>
        </Box>
        <Box pt={4}>{renderSelectedFilters}</Box>
      </Box>

      <Box px={8} display="flex" flexDirection="column" gap={6}>
        <Grid container spacing={6} columns={12}>
          <Grid item xs={12} lg={4}>
            <Box display="flex" flexDirection="column" gap={6} width="100%">
              <CompetitorSummary
                filters={{
                  brandId: currentBrandId,
                  ...selectedFilters,
                }}
              />
              {competitorData?.competitor.trackingBrand && (
                <CompetitorTopHashtags
                  filters={selectedFilters}
                  trackingBrand={competitorData.competitor.trackingBrand}
                  currentBrandId={currentBrandId}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} lg={8}>
            <CompetitorTopPosts
              brandId={currentBrandId}
              filters={selectedFilters}
            />
          </Grid>
        </Grid>

        <Grid container rowSpacing={8} columns={12}>
          {(selectedFilters.contentFilter ===
            CompetitorCreatorSocialPostContentFilter.Ugc ||
            creatorId) && (
            <Grid item xs={12}>
              {competitorData?.competitor.trackingBrand && (
                <CompetitorTopCreators
                  filters={selectedFilters}
                  currentBrandId={currentBrandId}
                  trackingBrand={competitorData.competitor.trackingBrand}
                />
              )}
            </Grid>
          )}
          {selectedFilters.contentFilter ===
            CompetitorCreatorSocialPostContentFilter.Ugc && (
            <Grid item xs={12}>
              <CompetitorCreatorDemographics
                filters={selectedFilters}
                onChange={(data) => {
                  updateFilters({
                    gender: data.selectedGender,
                    generation: data.selectedGeneration,
                  });
                }}
              />
            </Grid>
          )}
        </Grid>

        <BrandCompetitorCapturedProductsView
          filters={{
            ...selectedFilters,
            brandId: currentBrandId,
          }}
          onSelectFilter={(data) => {
            updateFilters({
              ...data,
            });
          }}
        />

        <BrandCompetitorGraph filters={selectedFilters} />

        <CompetitorSentiment
          currentBrandId={currentBrandId}
          trackingBrandId={competitorData?.competitor.trackingBrand?.id || ''}
          filters={selectedFilters}
          hideInPosts={
            selectedFilters.contentFilter ===
            CompetitorCreatorSocialPostContentFilter.Owned
          }
        />

        <CompetitorCommentInsights
          currentBrandId={currentBrandId}
          trackingBrandId={competitorData?.competitor.trackingBrand?.id || ''}
          filters={selectedFilters}
        />
      </Box>
    </Box>
  );
};
