import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import {
  useBrandDataForSocialListeningOnboarding,
  useTopicDataForSocialListeningOnboarding,
} from 'features/socialMediaListening/hooks';
import {
  Platform,
  PlotFeature,
  useSubmitInitialTopicParsingForSocialMediaListeningTopicOnboardingViewMutation,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { theme } from 'styles/theme';
import { SocialMediaListeningOnboardingAddAnotherTopic } from './sections/addAnotherTopic';
import { SocialMediaListeningOnboardingCommunicationStrategy } from './sections/communicationStrategy';
import { SocialMediaListeningOnboardingDotIndicator } from './sections/dotIndicator';
import { ExampleTile } from './sections/exampleTile';
import { SocialMediaListeningOnboardingKeywords } from './sections/keywords';
import { SocialMediaListeningOnboardingCompleted } from './sections/onboardingCompleted';
import { SocialMediaListeningOnboardingPostHistoryPreference } from './sections/postHistoryPreference';
import { SocialMediaListeningOnboardingPostPriorityInfo } from './sections/postPriorityInfo';
import { SocialMediaListeningOnboardingSentimentSubjects } from './sections/sentimentSubjects';
import { SocialMediaListeningOnboardingSignals } from './sections/signals';
import { SocialMediaListeningOnboardingTopicDescription } from './sections/topicDescription';
import { SocialMediaListeningOnboardingTopicName } from './sections/topicName';
import { SocialMediaListeningOnboardingTopicParsingSettings } from './sections/topicParsingSettings';
import { OnboardingStates } from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation SubmitInitialTopicParsingForSocialMediaListeningTopicOnboardingView(
    $topicId: String!
    $daysCount: Int!
    $includeTiktok: Boolean
    $includeInstagram: Boolean
  ) {
    submitInitialTopicParsing(
      topicId: $topicId
      daysCount: $daysCount
      includeTiktok: $includeTiktok
      includeInstagram: $includeInstagram
    ) {
      message
      success
    }
  }
`;

export const SocialMediaListeningTopicOnboardingView = () => {
  const { brandId = '' } = useParams();
  const navigate = useNavigate();

  const [submitInitialTopicParsing, { loading: submittingTopicData }] =
    useSubmitInitialTopicParsingForSocialMediaListeningTopicOnboardingViewMutation();

  const { isFeatureEnabled } = useFeatureFlagContext();
  const creatorTrackingEnabled = isFeatureEnabled(PlotFeature.CreatorTracking);
  const isProjectInboundEnabled = isFeatureEnabled(PlotFeature.ProjectInbound);

  const { brand } = useBrandDataForSocialListeningOnboarding({ brandId });
  const {
    topicActions,
    queries: { getAIGeneratedTopicPrompt },
  } = useTopicDataForSocialListeningOnboarding({
    topics: brand?.topics || [],
  });
  const { handleAddEngagementTopic, saving: savingTopicData } = topicActions;

  const [createdTopicId, setCreatedTopicId] = useState('');
  const [topicDescription, setTopicDescription] = useState('');
  const [prioritizationInfo, setPrioritizationInfo] = useState('');
  const [communicationStrategy, setCommunicationStrategy] = useState('');

  const [currentState, setCurrentState] = useState<OnboardingStates>(
    OnboardingStates.AskTopicName,
  );
  const [latestOnboardingState, setLatestOnboardingState] = useState(
    OnboardingStates.AskTopicName,
  );

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (
      createdTopicId &&
      currentState === OnboardingStates.AskTopicDescription
    ) {
      setLoading(true);
      getAIGeneratedTopicPrompt(createdTopicId)
        .then((d) => {
          if (!topicDescription) {
            setTopicDescription(
              d.data?.aiGeneratedTopicPrompt.relevantContent || '',
            );
          }

          if (!prioritizationInfo) {
            setPrioritizationInfo(
              d.data?.aiGeneratedTopicPrompt.priorityContent || '',
            );
          }

          if (!communicationStrategy) {
            setCommunicationStrategy(
              d.data?.aiGeneratedTopicPrompt.engagementPlan || '',
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [brand, currentState]); // eslint-disable-line react-hooks/exhaustive-deps

  const Sequence = [
    OnboardingStates.AskTopicName,
    OnboardingStates.AskTopicDescription,
    OnboardingStates.PostPriorityInfo,
    OnboardingStates.CommunicationStrategy,
    OnboardingStates.Keywords,
    ...(isProjectInboundEnabled ? [OnboardingStates.Signals] : []),
    ...(isProjectInboundEnabled ? [OnboardingStates.SentimentSubjects] : []),
    OnboardingStates.ParsingSettings,
    OnboardingStates.PostHistoryPreference,
    ...(creatorTrackingEnabled ? [OnboardingStates.AddAnotherTopic] : []),
    OnboardingStates.OnboardingComplete,
  ];

  const onNextClick = () => {
    const currentIndex = Sequence.indexOf(currentState);
    if (currentIndex === Sequence.length - 1) {
      navigate(PlotRoutes.socialListening());
    } else {
      setCurrentState(Sequence[currentIndex + 1]);
      if (Sequence.indexOf(latestOnboardingState) < currentIndex + 1) {
        setLatestOnboardingState(Sequence[currentIndex + 1]);
      }
    }
  };

  const onSubmitTopicPrompt = async () => {
    if (!createdTopicId) return;

    await handleAddEngagementTopic(
      {
        communicationStrategy,
        prioritizationInfo,
        topicDescription,
      },
      brand?.id || '',
      createdTopicId,
    );

    onNextClick();
  };

  const handleSubmitInitialTopicParsing = async (daysCount: number) => {
    await submitInitialTopicParsing({
      variables: {
        topicId: createdTopicId,
        daysCount,
        includeTiktok:
          brand?.creators?.some((c) => c.platform === Platform.Tiktok) || false,
        includeInstagram:
          brand?.creators?.some((c) => c.platform === Platform.Instagram) ||
          false,
      },
    });
    onNextClick();
  };

  return (
    <Box
      height="100vh"
      width="100vw"
      sx={{
        backgroundColor: theme.colors?.utility.blueberry,
      }}
    >
      <SocialMediaListeningOnboardingDotIndicator
        latestOnboardingState={latestOnboardingState}
        onboardingSequence={Sequence}
        currentState={currentState}
        onSelectState={setCurrentState}
      />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        {currentState === OnboardingStates.AskTopicName && (
          <SocialMediaListeningOnboardingTopicName
            currentTopicId={createdTopicId}
            updateCurrentTopicId={setCreatedTopicId}
            brandId={brand?.id || ''}
            topics={brand?.topics || []}
            onNext={onNextClick}
          />
        )}
        {currentState === OnboardingStates.AskTopicDescription && (
          <SocialMediaListeningOnboardingTopicDescription
            loading={loading}
            value={topicDescription}
            setValue={setTopicDescription}
            onNext={onNextClick}
          />
        )}
        {currentState === OnboardingStates.PostPriorityInfo && (
          <SocialMediaListeningOnboardingPostPriorityInfo
            value={prioritizationInfo}
            setValue={setPrioritizationInfo}
            onNext={() => {
              onNextClick();
            }}
          />
        )}
        {currentState === OnboardingStates.CommunicationStrategy && (
          <SocialMediaListeningOnboardingCommunicationStrategy
            savingData={savingTopicData}
            type="topic"
            value={communicationStrategy}
            setValue={setCommunicationStrategy}
            onNext={() => {
              onSubmitTopicPrompt();
            }}
          />
        )}
        {currentState === OnboardingStates.Keywords && (
          <SocialMediaListeningOnboardingKeywords
            topic={brand?.topics.find((t) => t.id === createdTopicId)}
            onNext={onNextClick}
          />
        )}
        {currentState === OnboardingStates.Signals && (
          <SocialMediaListeningOnboardingSignals
            topicId={createdTopicId}
            onNext={onNextClick}
          />
        )}
        {currentState === OnboardingStates.SentimentSubjects && (
          <SocialMediaListeningOnboardingSentimentSubjects
            currentTopicId={createdTopicId}
            brandId={brandId}
            onNext={onNextClick}
          />
        )}
        {currentState === OnboardingStates.ParsingSettings && (
          <SocialMediaListeningOnboardingTopicParsingSettings
            topicId={createdTopicId}
            onNext={onSubmitTopicPrompt}
          />
        )}
        {currentState === OnboardingStates.PostHistoryPreference && (
          <SocialMediaListeningOnboardingPostHistoryPreference
            savingData={submittingTopicData}
            onNext={handleSubmitInitialTopicParsing}
          />
        )}
        {currentState === OnboardingStates.AddAnotherTopic && (
          <SocialMediaListeningOnboardingAddAnotherTopic
            brandId={brand?.id || ''}
            onNext={onNextClick}
            onPrev={() =>
              setCurrentState(OnboardingStates.PostHistoryPreference)
            }
          />
        )}
        {currentState === OnboardingStates.OnboardingComplete && (
          <SocialMediaListeningOnboardingCompleted />
        )}
      </Box>
      {/* example tile */}
      {![
        OnboardingStates.AddAnotherTopic,
        OnboardingStates.PostHistoryPreference,
        OnboardingStates.OnboardingComplete,
      ].includes(currentState) && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            m: 3,
          }}
        >
          <ExampleTile currentState={currentState} />
        </Box>
      )}
    </Box>
  );
};
