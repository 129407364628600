import { SxProps } from '@mui/material';
import { theme } from 'styles/theme';

export const TableHeaders: {
  label: React.ReactNode;
  key: string;
  canSort?: boolean;
  sx?: SxProps;
}[] = [
  {
    label: '#',
    key: '#',
  },
  {
    label: 'Logo',
    key: 'Logo',
  },
  {
    label: 'Brand',
    key: 'Brand',
  },
  {
    label: 'Followers',
    key: 'followerCount',
    canSort: true,
  },
  {
    label: 'Engagement rate',
    key: 'averageEngagementRate',
    canSort: true,
  },
  {
    label: 'Average view count',
    key: 'averageVideoViews',
    canSort: true,
  },
  {
    label: 'Average likes',
    key: 'averageLikes',
    canSort: true,
  },
  {
    label: 'Top Posts',
    key: 'Top Posts',
  },
];
