import { gql } from '@apollo/client';
import { Box, IconButton, Popover, Typography } from '@mui/material';
import { IconLinearFilterSquare } from 'components/icons/components/linear/IconLinearFilterSquare';
import { IconLinearNote } from 'components/icons/components/linear/IconLinearNote';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  fragment CapturedProductFragmentForViewProductPopover on CapturedProductDataForInbound {
    id
    brand {
      id
      name
    }
    category {
      id
      name
    }
  }
`;

interface ViewProductPopoverProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onViewProduct: () => void;
  onViewInsights: () => void;
}

export const ViewProductPopover = ({
  anchorEl,
  onClose,
  onViewInsights,
  onViewProduct,
}: ViewProductPopoverProps) => {
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: -12,
        horizontal: 'left',
      }}
      PaperProps={{
        sx: {
          borderRadius: 3,
          background: 'rgba(255, 255, 255, 0.90)',
          boxShadow:
            '0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12)',
          padding: theme.spacing(4, 2),
          minWidth: 266,
        },
      }}
    >
      {[
        {
          icon: IconLinearFilterSquare,
          label: 'View products',
          onClick: onViewProduct,
        },
        {
          icon: IconLinearNote,
          label: 'View insights',
          onClick: onViewInsights,
        },
      ].map((item) => {
        const Icon = item.icon;
        return (
          <IconButton
            sx={{
              borderRadius: 3,
              padding: 3,
              color: theme.colors?.primary.black,
              gap: 2,
              width: '100%',
              justifyContent: 'flex-start',
              ':hover': {
                backgroundColor: 'rgba(35, 6, 3, 0.1)',
              },
            }}
            onClick={item.onClick}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 1,
                background: 'rgba(35, 6, 3, 0.05)',
                height: 24,
                width: 24,
              }}
            >
              <Icon size={16} />
            </Box>
            <Typography variant="headline-xs">{item.label}</Typography>
          </IconButton>
        );
      })}
    </Popover>
  );
};
