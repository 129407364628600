import { useMemo } from 'react';
import { gql } from '@apollo/client';
import {
  Box,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { IconCustomSort } from 'components/icons/components/custom/IconCustomSort';
import { IconOutlineImage } from 'components/icons/components/outline/IconOutlineImage';
import { HighlightedTextRenderer } from 'features/search';
import { SocialPostThumbnail } from 'features/socialPost';
import {
  CapturedProductAttributeUsageFragmentForSlaRankingTableAttributesFragmentDoc,
  CapturedProductFragmentForSlaBrandInboundRankingProductDialogViewFragmentDoc,
  CapturedProductFragmentForSlaRankingTableFragment,
  CapturedProductFragmentForViewProductPopoverFragmentDoc,
  CapturedProductGraphDataFragmentForSlaRankingGraphDataFragmentDoc,
  CapturedProductSectionSortBy,
  CapturedProductSectionSortField,
  CapturedProductSectionViewType,
  SocialPostFragmentSocialPostThumbnailFragmentDoc,
  SortOrder,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { SLARankingGraph } from '../SLARankingGraph';
import { SLARankingTableAttributes } from './SLARankingTableAttributes';
import {
  stickyTableColumnStyles,
  StyledHoveredTableCellTypography,
} from './styles';

// eslint-disable-next-line
gql`
  fragment CapturedProductFragmentForSLARankingTable on CapturedProductDataForInbound {
    id
    line
    model
    percentChange
    socialPostCount
    totalViewCount
    brand {
      id
      name
    }
    category {
      id
      name
    }
    attributeUsage {
      ...CapturedProductAttributeUsageFragmentForSLARankingTableAttributes
    }
    graphData {
      ...CapturedProductGraphDataFragmentForSLARankingGraphData
    }
    previewPosts(count: $count) {
      ...SocialPostFragmentSocialPostThumbnail
    }
    ...CapturedProductFragmentForSLABrandInboundRankingProductDialogView
    ...CapturedProductFragmentForViewProductPopover
  }
  ${SocialPostFragmentSocialPostThumbnailFragmentDoc}
  ${CapturedProductFragmentForSlaBrandInboundRankingProductDialogViewFragmentDoc}
  ${CapturedProductFragmentForViewProductPopoverFragmentDoc}
  ${CapturedProductGraphDataFragmentForSlaRankingGraphDataFragmentDoc}
  ${CapturedProductAttributeUsageFragmentForSlaRankingTableAttributesFragmentDoc}
`;

enum ActionType {
  Sorting = 'sorting',
  Editing = 'editing',
}

type Props = {
  products: CapturedProductFragmentForSlaRankingTableFragment[];
  viewType: CapturedProductSectionViewType;
  sortInfo: {
    field: CapturedProductSectionSortField;
    order: SortOrder;
    setSortBy: (sortBy: CapturedProductSectionSortBy) => void;
  };
  searchTerm: string;
  loading?: boolean;
  scrollDirection: 'left' | 'right' | null;
  setDialogProduct: (
    product: CapturedProductFragmentForSlaRankingTableFragment | null,
  ) => void;
  setViewProductAnchorEl: (element: HTMLElement | null) => void;
  setViewProductType: (type: CapturedProductSectionViewType | null) => void;
  stopEvents: (e: React.MouseEvent) => void;
  onSelectFilter: (filter: any) => void;
  filters: {
    [key: string]: any;
  };
  showFilterSelectionToast: (message: string) => void;
  searchedTerm?: string;
};

export const SLARankingTableContent = ({
  products,
  viewType,
  sortInfo,
  searchTerm,
  loading = false,
  scrollDirection,
  setDialogProduct,
  setViewProductAnchorEl,
  setViewProductType,
  stopEvents,
  onSelectFilter,
  filters,
  showFilterSelectionToast,
  searchedTerm = '',
}: Props) => {
  const headCells = useMemo(() => {
    return [
      ...(viewType === CapturedProductSectionViewType.ProductLineModel
        ? [
            {
              id: 'productLineModel',
              label: 'Product Line & Model',
              actionType: ActionType.Editing,
            },
          ]
        : []),
      ...(viewType !== CapturedProductSectionViewType.ProductCategory
        ? [{ id: 'brand', label: 'Brand', actionType: ActionType.Editing }]
        : []),
      ...(viewType !== CapturedProductSectionViewType.ProductBrand
        ? [
            {
              id: 'category',
              label: 'Category',
              actionType: ActionType.Editing,
            },
            {
              id: 'topAttributes',
              label: 'Top attributes',
              actionType: ActionType.Editing,
            },
          ]
        : []),
      { id: 'graph', label: 'Graph' },
      {
        id: CapturedProductSectionSortField.PercentChange,
        label: '% change',
        actionType: ActionType.Sorting,
        tooltip: (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              maxWidth: 232,
            }}
          >
            <Typography
              variant="subhead-sm"
              color={theme.colors?.primary.parchment}
            >
              % change
            </Typography>
            <Typography
              variant="subhead-sm"
              color={theme.colors?.primary.parchment}
            >
              The percentage increase or decrease in post volume during your
              selected timeframe.
            </Typography>
          </Box>
        ),
      },
      {
        id: CapturedProductSectionSortField.NumberOfPosts,
        label: 'Number of posts',
        actionType: ActionType.Sorting,
      },
      {
        id: CapturedProductSectionSortField.ViewCount,
        label: 'View count',
        actionType: ActionType.Sorting,
      },
      { id: 'post', label: 'Post' },
    ];
  }, [viewType]);

  return (
    <Table
      sx={{
        minWidth:
          products.length &&
          viewType === CapturedProductSectionViewType.ProductLineModel
            ? '100vw'
            : 'max-content',
        overflowX: 'auto',
      }}
      stickyHeader
    >
      <TableHead>
        <TableRow>
          {headCells.map((headCell, index) => (
            <TableCell
              key={headCell.id}
              sx={{
                padding: 2.5,
                borderBottom: `1px solid ${theme.colors?.utility[400]}`,
                ...(index >= headCells.length - 1 && {
                  width: '200px',
                }),
                ...(index === 0 &&
                  scrollDirection && {
                    ...stickyTableColumnStyles,
                    zIndex: 1000,
                  }),
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  variant="headline-xs"
                  color={theme.colors?.utility[700]}
                  display="flex"
                  alignItems="center"
                  gap={2}
                  whiteSpace="nowrap"
                >
                  {headCell.label}
                  {headCell.tooltip && (
                    <Tooltip title={headCell.tooltip}>
                      <Box display="flex" alignItems="center">
                        <IconBoldInfoCircle
                          size={16}
                          color={theme.colors?.utility[700]}
                          style={{
                            rotate: '180deg',
                            width: 17,
                          }}
                        />
                      </Box>
                    </Tooltip>
                  )}
                </Typography>
                {headCell.actionType && (
                  <IconButton
                    sx={{
                      p: 0,
                      color: theme.colors?.utility[600],
                    }}
                    disableRipple
                  >
                    {headCell.actionType === ActionType.Sorting && (
                      <IconCustomSort
                        size={16}
                        onClick={() => {
                          sortInfo.setSortBy({
                            field:
                              headCell.id as CapturedProductSectionSortField,
                            order:
                              sortInfo.order === SortOrder.Desc
                                ? SortOrder.Asc
                                : SortOrder.Desc,
                          });
                        }}
                      />
                    )}
                  </IconButton>
                )}
              </Box>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody sx={{ overflowY: 'auto', maxHeight: 100 }}>
        {products.length ? (
          products.map((product) => {
            const isNegativePercentChange = product.percentChange < 0;
            return (
              <TableRow
                sx={{
                  cursor: 'pointer',
                  '& td': {
                    padding: 2.5,
                    ...theme.typography['subhead-xl'],
                    borderBottom: 'none',
                    ':first-child': {
                      borderRadius: '8px 0 0 8px',
                      ...(scrollDirection && {
                        ...stickyTableColumnStyles,
                      }),
                    },
                    ':last-child': {
                      borderRadius: '0 8px 8px 0',
                    },
                  },
                  ':hover': {
                    backgroundColor: theme.colors?.utility[275],
                  },
                }}
                onClick={() => {
                  setDialogProduct(product);
                }}
                // TODO: Add context menu when mutation is ready
                // onContextMenu={(e) => {
                //   stopEvents(e);
                //   setContextMenuAnchorPosition({
                //     top: e.clientY,
                //     left: e.clientX,
                //   });
                // }}
              >
                {viewType ===
                  CapturedProductSectionViewType.ProductLineModel && (
                  <TableCell
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    {product.line || product.model ? (
                      <>
                        <HighlightedTextRenderer
                          text={product.line ?? '-'}
                          match={searchTerm}
                        />
                        <br />
                        <Typography
                          component="div"
                          variant="subhead-xl"
                          color={theme.colors?.utility[700]}
                          mt={1}
                        >
                          <HighlightedTextRenderer
                            text={product.model ?? '-'}
                            match={searchTerm}
                          />
                        </Typography>
                      </>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                )}
                {viewType !==
                  CapturedProductSectionViewType.ProductCategory && (
                  <TableCell
                    {...(viewType ===
                      CapturedProductSectionViewType.ProductLineModel && {
                      onClick: (e) => {
                        stopEvents(e);
                        setViewProductAnchorEl(e.currentTarget);
                        setViewProductType(
                          CapturedProductSectionViewType.ProductBrand,
                        );
                        setDialogProduct(product);
                      },
                    })}
                  >
                    <StyledHoveredTableCellTypography
                      isActive={
                        viewType ===
                        CapturedProductSectionViewType.ProductLineModel
                      }
                    >
                      <HighlightedTextRenderer
                        text={product.brand?.name ?? '-'}
                        match={searchTerm}
                      />
                    </StyledHoveredTableCellTypography>
                  </TableCell>
                )}
                {viewType !== CapturedProductSectionViewType.ProductBrand && (
                  <>
                    <TableCell
                      {...(viewType ===
                        CapturedProductSectionViewType.ProductLineModel && {
                        onClick: (e) => {
                          stopEvents(e);
                          setViewProductAnchorEl(e.currentTarget);
                          setViewProductType(
                            CapturedProductSectionViewType.ProductCategory,
                          );
                          setDialogProduct(product);
                        },
                      })}
                    >
                      <StyledHoveredTableCellTypography
                        isActive={
                          viewType ===
                          CapturedProductSectionViewType.ProductLineModel
                        }
                      >
                        <HighlightedTextRenderer
                          text={product.category?.name ?? '-'}
                          match={searchTerm}
                        />
                      </StyledHoveredTableCellTypography>
                    </TableCell>
                    <TableCell>
                      <SLARankingTableAttributes
                        attributeUsage={product.attributeUsage}
                        searchedTerm={searchedTerm}
                        onSelectAttribute={(attribute) => {
                          onSelectFilter({
                            capturedProductAttributeIds: [
                              ...(filters.capturedProductAttributeIds ?? []),
                              attribute.attributeId,
                            ],
                          });
                          showFilterSelectionToast(
                            `Filter by Attributes: ${attribute.attribute}`,
                          );
                        }}
                      />
                    </TableCell>
                  </>
                )}

                <TableCell>
                  {product.graphData.length > 1 ? (
                    <SLARankingGraph
                      graphData={product.graphData}
                      isNegativePercentChange={isNegativePercentChange}
                    />
                  ) : (
                    '-'
                  )}
                </TableCell>
                <TableCell
                  style={{
                    color:
                      theme.colors?.utility[
                        product?.percentChange === 0
                          ? '900'
                          : isNegativePercentChange
                          ? 'pink-3'
                          : 'green-3'
                      ],
                    fontWeight: 600,
                  }}
                >
                  {product?.percentChange === 0
                    ? ''
                    : isNegativePercentChange
                    ? '↓ '
                    : '↑ '}
                  {product.percentChange.toFixed(2).replace('-', '')}%
                </TableCell>
                <TableCell>
                  {formatBigNumber(product.socialPostCount)}
                </TableCell>
                <TableCell>{formatBigNumber(product.totalViewCount)}</TableCell>
                <TableCell>
                  {product.previewPosts.length ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2.5,
                      }}
                    >
                      {product.previewPosts.map((post) => (
                        <SocialPostThumbnail
                          socialPost={post}
                          sx={{
                            width: theme.spacing(5),
                            height: theme.spacing(6),
                            borderRadius: theme.spacing(1),
                            backgroundColor: theme.colors?.utility[300],
                            p: 0,
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setDialogProduct(product);
                          }}
                          componentProps={{
                            hideCreatedTime: true,
                            hidePlatform: true,
                            thumbnailFallback: <IconOutlineImage size={24} />,
                          }}
                        />
                      ))}
                    </Box>
                  ) : (
                    '-'
                  )}
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell
              colSpan={headCells.length}
              sx={{
                borderBottom: 'none',
                padding: 0,
              }}
            >
              {loading ? (
                <Skeleton
                  sx={{
                    width: '100%',
                    height: theme.spacing(20),
                  }}
                />
              ) : (
                <Typography
                  component="div"
                  sx={{
                    ...theme.typography['subhead-xl'],
                    color: theme.colors?.utility[700],
                    height: theme.spacing(20),
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  No data found
                </Typography>
              )}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
