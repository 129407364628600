import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Button, Card, Grid, Radio, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { SentimentThemeCardWithDateRange } from 'features/socialMediaListening/views/analytics/inbound/sections/sentimentThemeCard/SentimentThemeCardWithDateRange';
import {
  Sentiment,
  SentimentMedium,
  useGetCompetitorSentimentMetricsForCompetitorSentimentQuery,
} from 'graphql/generated';
import { useCallback, useEffect, useState } from 'react';
import { theme } from 'styles/theme';
import { CompetitorSentimentThemes } from '../competitorSentimentThemes';
import { CompetitorSentimentDetailsModal } from './competitorSentimentDetailsModal';
import { CompetitorSentimentSkeleton } from './CompetitorSentimentSkeleton';
import { SocialMediaListeningCompetitorDetailSentimentProps } from './types';

export const CompetitorSentiment = ({
  filters,
  currentBrandId,
  trackingBrandId,
  hideInPosts,
}: SocialMediaListeningCompetitorDetailSentimentProps) => {
  const [medium, setMedium] = useState<SentimentMedium>(
    hideInPosts ? SentimentMedium.InComments : SentimentMedium.InPosts,
  );
  const [sentiment, setSentiment] = useState(Sentiment.Positive);
  const [selectedTheme, setSelectedTheme] = useState('');

  const themeDialogDisclosure = useDisclosure();

  useEffect(() => {
    setMedium(
      hideInPosts && medium === SentimentMedium.InPosts
        ? SentimentMedium.InComments
        : medium,
    );
  }, [hideInPosts]); // eslint-disable-line

  const generatePayload = useCallback(
    (sentiment: Sentiment) => ({
      ...filters,
      filters: {
        medium,
        sentiment,
      },
    }),
    [filters, medium],
  );

  const {
    data: positiveSentimentMetricsData,
    loading: positiveSentimentMetricsLoading,
  } = useGetCompetitorSentimentMetricsForCompetitorSentimentQuery({
    variables: {
      data: generatePayload(Sentiment.Positive),
    },
  });

  const {
    data: neutralSentimentMetricsData,
    loading: neutralSentimentMetrics,
  } = useGetCompetitorSentimentMetricsForCompetitorSentimentQuery({
    variables: {
      data: generatePayload(Sentiment.Neutral),
    },
  });

  const {
    data: negativeSentimentMetricsData,
    loading: negativeSentimentMetricsLoading,
  } = useGetCompetitorSentimentMetricsForCompetitorSentimentQuery({
    variables: {
      data: generatePayload(Sentiment.Negative),
    },
  });

  const isLoading =
    positiveSentimentMetricsLoading ||
    neutralSentimentMetrics ||
    negativeSentimentMetricsLoading;

  if (isLoading) {
    return <CompetitorSentimentSkeleton />;
  }

  return (
    <Box>
      <Card
        sx={{
          borderRadius: 5,
          p: 6,
          boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Box width="100%" display="flex" justifyContent="space-between">
          <Typography variant="headline-lg" fontSize={theme.spacing(5)}>
            Sentiment
          </Typography>
          <Box display="flex" gap={3} alignItems="center">
            <Box display="flex" alignItems="center" gap={3}>
              <ContextMenu
                sx={{
                  '& .MuiMenuItem-root': {
                    p: 0,
                    color: theme.colors?.primary.black,
                  },
                }}
                options={[
                  ...(!hideInPosts
                    ? [
                        {
                          renderOption: () => (
                            <Box display="flex" alignItems="center">
                              <Radio
                                checked={medium === SentimentMedium.InPosts}
                              />
                              <Typography variant="body-lg">
                                In Posts
                              </Typography>
                            </Box>
                          ),
                          onClick: () => {
                            setMedium(SentimentMedium.InPosts);
                          },
                        },
                      ]
                    : []),
                  {
                    renderOption: () => (
                      <Box display="flex" alignItems="center">
                        <Radio
                          checked={medium === SentimentMedium.InComments}
                        />
                        <Typography variant="body-lg">In Comments</Typography>
                      </Box>
                    ),
                    onClick: () => {
                      setMedium(SentimentMedium.InComments);
                    },
                  },
                ]}
                renderButton={() => (
                  <Button
                    sx={{
                      backgroundColor: theme.colors?.utility[275],
                      color: theme.colors?.primary.black,
                      borderRadius: theme.spacing(2),
                      display: 'flex',
                      gap: 2,
                      p: theme.spacing(1, 2),
                      alignItems: 'center',
                      '&:hover': {
                        backgroundColor: theme.colors?.utility[275],
                      },
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        backgroundColor: 'rgba(35, 6, 3, 0.05);',
                        p: theme.spacing(1),
                        borderRadius: theme.spacing(1),
                      }}
                    >
                      <IconLinearSort size={16} />
                    </Box>
                    <Typography fontWeight={500} variant="body-xl">
                      {medium === SentimentMedium.InPosts
                        ? 'In Posts'
                        : 'In Comments'}
                    </Typography>
                  </Button>
                )}
              />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            mt: 6,
            borderBottom: `1px solid ${theme.colors?.utility[275]}`,
          }}
        />

        <Grid container spacing={3} columns={12} mt={6}>
          <Grid item xs={4}>
            <SentimentThemeCardWithDateRange
              chartData={{
                title: 'Positive',
                subTitle:
                  'Favorable comments or discussions about your topic, brand, or product on social.',
                chartDataPoints:
                  positiveSentimentMetricsData?.getCompetitorSentimentMetrics
                    ?.sentimentMetrics.chartDataPoints || [],
                averageCount:
                  positiveSentimentMetricsData?.getCompetitorSentimentMetrics
                    ?.sentimentMetrics.totalCount || 0,
              }}
              sentiment={Sentiment.Positive}
              dateRange={[
                filters.dateRange.startDate,
                filters.dateRange.endDate,
              ]}
              componentProps={{
                containerSx: {
                  boxShadow: 'none',
                  border: 'none',
                  mx: 4,
                },
                mainColor: theme.colors?.utility['green-4'],
                highlightColor: 'rgba(224, 228, 202, 0.50)',
              }}
              renderTableData={() => (
                <CompetitorSentimentThemes
                  claimMetrics={
                    positiveSentimentMetricsData?.getCompetitorSentimentMetrics
                      ?.claimMetrics || []
                  }
                  onClickTheme={(theme) => {
                    setSentiment(Sentiment.Positive);
                    setSelectedTheme(theme);
                    themeDialogDisclosure.onOpen();
                  }}
                  componentProps={{
                    mainColor: theme.colors?.utility['green-4'],
                    highlightColor: 'rgba(224, 228, 202, 0.50)',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <SentimentThemeCardWithDateRange
              chartData={{
                title: 'Neutral',
                subTitle:
                  'Neither positive nor negative discussions about your topic, brand or product on social.',
                chartDataPoints:
                  neutralSentimentMetricsData?.getCompetitorSentimentMetrics
                    ?.sentimentMetrics.chartDataPoints || [],
                averageCount:
                  neutralSentimentMetricsData?.getCompetitorSentimentMetrics
                    ?.sentimentMetrics.totalCount || 0,
              }}
              sentiment={Sentiment.Neutral}
              dateRange={[
                filters.dateRange.startDate,
                filters.dateRange.endDate,
              ]}
              componentProps={{
                containerSx: {
                  boxShadow: 'none',
                  border: 'none',
                  mx: 4,
                },
                mainColor: theme.colors?.utility['blue-4'],
                highlightColor: 'rgba(202, 223, 228, 0.50)',
              }}
              renderTableData={() => (
                <CompetitorSentimentThemes
                  claimMetrics={
                    neutralSentimentMetricsData?.getCompetitorSentimentMetrics
                      ?.claimMetrics || []
                  }
                  onClickTheme={(theme) => {
                    setSentiment(Sentiment.Neutral);
                    setSelectedTheme(theme);
                    themeDialogDisclosure.onOpen();
                  }}
                  componentProps={{
                    mainColor: theme.colors?.utility['blue-4'],
                    highlightColor: 'rgba(202, 223, 228, 0.50)',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <SentimentThemeCardWithDateRange
              chartData={{
                title: 'Negative',
                subTitle:
                  'Unfavorable comments or discussions about your topic, brand, or product on social.',
                chartDataPoints:
                  negativeSentimentMetricsData?.getCompetitorSentimentMetrics
                    ?.sentimentMetrics.chartDataPoints || [],
                averageCount:
                  negativeSentimentMetricsData?.getCompetitorSentimentMetrics
                    ?.sentimentMetrics.totalCount || 0,
              }}
              sentiment={Sentiment.Negative}
              dateRange={[
                filters.dateRange.startDate,
                filters.dateRange.endDate,
              ]}
              componentProps={{
                containerSx: {
                  boxShadow: 'none',
                  border: 'none',
                  mx: 4,
                },
                mainColor: theme.colors?.utility['pink-4'],
                highlightColor: 'rgba(228, 202, 202, 0.50)',
              }}
              renderTableData={() => (
                <CompetitorSentimentThemes
                  claimMetrics={
                    negativeSentimentMetricsData?.getCompetitorSentimentMetrics
                      ?.claimMetrics || []
                  }
                  onClickTheme={(theme) => {
                    setSentiment(Sentiment.Negative);
                    setSelectedTheme(theme);
                    themeDialogDisclosure.onOpen();
                  }}
                  componentProps={{
                    mainColor: theme.colors?.utility['pink-4'],
                    highlightColor: 'rgba(228, 202, 202, 0.50)',
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Card>
      <Typography
        variant="subhead-xl"
        color={theme.colors?.utility[600]}
        ml="auto"
        width="100%"
        textAlign="right"
        display="block"
        mt={2}
      >
        {/* TODO:Competitor: update timestamp here */}
        {/* Last updated {moment().subtract(1, 'days').fromNow()} */}
      </Typography>

      <CompetitorSentimentDetailsModal
        {...themeDialogDisclosure}
        dateRange={[filters.dateRange.startDate, filters.dateRange.endDate]}
        medium={medium}
        sentiment={sentiment}
        currentTheme={selectedTheme}
        competitorId={filters.competitorId}
        currentBrandId={currentBrandId}
        trackingBrandId={trackingBrandId}
        platform={filters.platforms || []}
      />
    </Box>
  );
};
