import { gql } from '@apollo/client';
import { EditableSection } from 'components/common/EditableSection';
import { RichTextEditor } from 'components/common/form/RichTextEditor';
import {
  PostFragmentPostDescriptionFragment,
  PostPermission,
  useUpdatePostForPostDescriptionMutation,
} from 'graphql/generated';
import { debounce } from 'lodash';
import { CSSProperties, useMemo } from 'react';
import { PostDescriptionReadonly } from './PostDescriptionReadonly';

export const POST_FRAGMENT_POST_DESCRIPTION = gql`
  fragment PostFragmentPostDescription on PostModel {
    id
    description
    myPermissions
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdatePostForPostDescription($data: UpdatePostInput!) {
    updatePost(data: $data) {
      id
      ...PostFragmentPostDescription
    }
  }
  ${POST_FRAGMENT_POST_DESCRIPTION}
`;

export type PostDescriptionProps = {
  post: PostFragmentPostDescriptionFragment;
  style?: CSSProperties;
};

export const PostDescription = (props: PostDescriptionProps) => {
  const { post, style } = props;

  const [updatePost] = useUpdatePostForPostDescriptionMutation();

  const onChange = useMemo(
    () =>
      debounce((value: string) => {
        updatePost({
          variables: {
            data: {
              postId: post.id,
              data: {
                description: value,
              },
            },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            updatePost: {
              ...post,
              description: value,
            },
          },
        });
      }, 300),
    [post.id], // eslint-disable-line
  );

  const canEdit = post.myPermissions?.includes(PostPermission.Update);

  if (!canEdit) {
    return <PostDescriptionReadonly post={post} style={style} />;
  }

  return (
    <EditableSection
      renderReadOnly={() => (
        <PostDescriptionReadonly post={post} style={style} />
      )}
      renderEdit={() => (
        <RichTextEditor
          defaultContent={post.description}
          style={style}
          placeholder="Description"
          autoFocus
          hideToolBar
          onChange={(value) => onChange(value.text ? value.html : '')}
        />
      )}
    />
  );
};
