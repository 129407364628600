import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { TopicParsingSettingsView } from 'features/topic';
import {
  TopicFragmentTopicParsingSettingsViewFragmentDoc,
  useGetTopicForSmlOnboardingTopicParsingSettingsQuery,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { RenderNextButton } from '../renderNextButton/RenderNextButton';

// eslint-disable-next-line
gql`
  query GetTopicForSMLOnboardingTopicParsingSettings($id: String!) {
    topic(id: $id) {
      id
      ...TopicFragmentTopicParsingSettingsView
    }
  }
  ${TopicFragmentTopicParsingSettingsViewFragmentDoc}
`;

type SocialMediaListeningOnboardingTopicParsingSettingsProps = {
  topicId?: string;
  onNext: () => void;
};

export const SocialMediaListeningOnboardingTopicParsingSettings = ({
  topicId,
  onNext,
}: SocialMediaListeningOnboardingTopicParsingSettingsProps) => {
  const { data } = useGetTopicForSmlOnboardingTopicParsingSettingsQuery({
    variables: { id: topicId || '' },
    skip: !topicId,
    fetchPolicy: 'cache-only',
  });
  const topic = data?.topic;
  const canGoNext =
    topic?.parsingSettings?.Instagram.enabled ||
    topic?.parsingSettings?.Tiktok.enabled ||
    topic?.parsingSettings?.Youtube.enabled;

  const onSubmit = async () => {
    onNext();
  };

  if (!topicId) {
    return null;
  }

  return (
    <Box
      position="relative"
      width="60vw"
      display="flex"
      gap={4}
      flexDirection="column"
      color={theme.colors?.primary.parchment}
      sx={{ overflowY: 'auto', pb: 4 }}
    >
      <Typography variant="headline-lg" fontSize={theme.spacing(7)}>
        Choose your platforms
      </Typography>
      <Typography variant="subhead-xl" mb={8}>
        Choose platforms for data collection. Each platform added will use more
        hours from your account.
      </Typography>

      <TopicParsingSettingsView key={topicId} topicId={topicId} />

      <RenderNextButton onNextClick={onSubmit} disabled={!canGoNext} />
    </Box>
  );
};
