import { Box, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconCustomSparkles } from 'components/icons/components/custom/IconCustomSparkles';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { useUserContext } from 'contexts/users/User.context';
import { SentimentColorsSx } from 'features/socialMediaListening/views/postDetail/consts';
import { Sentiment } from 'graphql/generated';
import { useEffect, useState } from 'react';
import { theme } from 'styles/theme';
import { ExpandableText } from '../expandableText';
import { SentimentIcon } from '../sentimentIcon';
import { PostBrandSentimentsProps } from './types';

export const PostBrandSentiments = ({
  socialPost,
  brandId,
}: PostBrandSentimentsProps) => {
  const { user } = useUserContext();
  const defaultBrand = user?.organization.socialListeningBrands?.[0];
  const [selectedBrandId, setSelectedBrandId] = useState(defaultBrand?.id);

  // remove brands that already exist with same name
  const brands = socialPost.brandAnalysis
    ?.filter((x) => x.isConfident)
    .map((brandClaim) => brandClaim.brand)
    .filter(
      (brand, index, self) =>
        self.findIndex(
          (t) => t.name.toLowerCase() === brand.name.toLowerCase(),
        ) === index,
    );

  useEffect(() => {
    if (brandId) {
      if (socialPost.brandAnalysis.some((x) => x.brand?.id === brandId)) {
        setSelectedBrandId(brandId);
      }
    }
  }, [brandId, socialPost.brandAnalysis]);

  const currentBrandAnalysis =
    socialPost.brandAnalysis.find((x) => x.brand?.id === selectedBrandId) ||
    socialPost.brandAnalysis?.[0];

  if (!currentBrandAnalysis) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      sx={{
        width: '100%',
        borderTop: `1px solid ${theme.colors?.utility[400]}`,
        pr: 2,
        pt: 6,
        pb: 3,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Typography
          variant="body-lg"
          fontWeight={600}
          color={theme.colors?.utility[700]}
          display="flex"
          alignItems="center"
        >
          <IconCustomSparkles size={14} />
          <Box component="span" pl={2}>
            Brand sentiment
          </Box>
        </Typography>
        {brands.length > 1 ? (
          <ContextMenu
            options={brands.map((b) => ({
              renderOption: () => (
                <Box display="flex" gap={2} alignItems="center">
                  <Typography
                    variant="body-lg"
                    fontWeight={600}
                    color={theme.colors?.utility[900]}
                    textTransform="capitalize"
                  >
                    {b?.name.toLowerCase()}
                  </Typography>
                </Box>
              ),
              onClick: () => setSelectedBrandId(b?.id),
            }))}
            renderButton={() => (
              <Box
                display="flex"
                gap={1}
                alignItems="center"
                sx={{ cursor: 'pointer' }}
              >
                <Typography
                  variant="body-lg"
                  fontWeight={600}
                  color={theme.colors?.utility[900]}
                  textTransform="capitalize"
                >
                  {currentBrandAnalysis?.brand?.name.toLowerCase()}
                </Typography>
                <IconLinearArrowDown size={16} />
              </Box>
            )}
          />
        ) : (
          <Typography
            variant="body-lg"
            fontWeight={600}
            color={theme.colors?.utility[900]}
            textTransform="capitalize"
          >
            {currentBrandAnalysis?.brand?.name.toLowerCase()}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          width: 'fit-content',
          gap: theme.spacing(2),
          alignItems: 'center',
          p: theme.spacing(1, 2),
          borderRadius: 2,
          ...SentimentColorsSx.Default,
          ...(currentBrandAnalysis?.sentimentScore != null &&
          currentBrandAnalysis?.sentimentScore < 0
            ? SentimentColorsSx.NEGATIVE
            : {}),
          ...(currentBrandAnalysis?.sentimentScore != null &&
          currentBrandAnalysis?.sentimentScore > 0
            ? SentimentColorsSx.POSITIVE
            : {}),
        }}
      >
        <SentimentIcon
          size={16}
          sentiment={
            currentBrandAnalysis?.sentimentTone === Sentiment.Positive ||
            (currentBrandAnalysis?.sentimentScore != null &&
              currentBrandAnalysis?.sentimentScore > 0)
              ? Sentiment.Positive
              : currentBrandAnalysis?.sentimentTone === Sentiment.Negative ||
                (currentBrandAnalysis?.sentimentScore != null &&
                  currentBrandAnalysis?.sentimentScore < 0)
              ? Sentiment.Negative
              : Sentiment.Neutral
          }
        />
        <Typography variant="body-lg" fontWeight={600}>
          {(currentBrandAnalysis?.sentimentTone === Sentiment.Positive ||
            (currentBrandAnalysis?.sentimentScore != null &&
              currentBrandAnalysis?.sentimentScore > 0)) &&
            'Overall Positive'}
          {(currentBrandAnalysis?.sentimentTone === Sentiment.Negative ||
            (currentBrandAnalysis?.sentimentScore != null &&
              currentBrandAnalysis?.sentimentScore < 0)) &&
            'Overall Negative'}
          {(currentBrandAnalysis?.sentimentTone === Sentiment.Neutral ||
            currentBrandAnalysis?.sentimentScore === 0) &&
            `No sentiment for ${currentBrandAnalysis?.brand?.name}`}
        </Typography>
      </Box>

      <ExpandableText
        key={currentBrandAnalysis?.brand?.id}
        text={currentBrandAnalysis?.brandClaims.map((claim) => (
          <Box
            sx={{
              ...{ color: SentimentColorsSx.Default.color },
              ...(claim?.sentimentScore < 0
                ? { color: SentimentColorsSx.NEGATIVE.color }
                : {}),
              ...(claim?.sentimentScore > 0
                ? { color: SentimentColorsSx.POSITIVE.color }
                : {}),
            }}
          >
            <Typography variant="body-xl" fontWeight={500}>
              • {claim.claim}
            </Typography>
          </Box>
        ))}
        collapsedHeight={64}
      />
    </Box>
  );
};
