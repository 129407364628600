import { Box, SxProps } from '@mui/material';
import { useJuiceboxSelectContext } from 'features/juicebox';
import { getNoteColorFromColor } from 'features/note/utils';
import {
  PostContextMenu,
  PostCreator,
  PostIcon,
  PostNewBadge,
  PostPreview,
  PostReactionList,
  PostTitle,
  PostUnreadCommentBadge,
} from 'features/post/components';
import { SearchItemSearchTypeIndicator } from 'features/search';
import {
  PostFragmentPostMasonryCardViewFragment,
  PostType,
  SearchHitType,
} from 'graphql/generated';
import { useMemo } from 'react';
import { theme } from 'styles/theme/theme';
import { StyledRightClickContextMenuWrapper } from './styles';

export type PostMasonryCardViewProps = {
  post: PostFragmentPostMasonryCardViewFragment;
  currentCollectionId?: string;
  sx?: SxProps;
  isSmartSearch?: boolean;
  componentsProps?: {
    disableContextMenu?: boolean;
  };
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

const renderSmartSearchIcon = (isSmartSearch: boolean) => (
  <Box
    display="flex"
    position="absolute"
    left={theme.spacing(3.5)}
    top={theme.spacing(3.75)}
  >
    <SearchItemSearchTypeIndicator
      searchHitType={
        isSmartSearch ? SearchHitType.Smart : SearchHitType.Regular
      }
      size={theme.spacing(4)}
    />
  </Box>
);

export const PostMasonryCardView = (props: PostMasonryCardViewProps) => {
  const {
    post,
    currentCollectionId,
    isSmartSearch = false,
    sx,
    componentsProps = {},
    onClick,
  } = props;

  const postColor = useMemo(() => getNoteColorFromColor(post.color), [post]);
  const { onSelectPost } = useJuiceboxSelectContext();

  return (
    <StyledRightClickContextMenuWrapper
      disabled={componentsProps.disableContextMenu}
      sx={{
        minHeight: 150,
        ':hover': {
          '.post-overlay': {
            background:
              'linear-gradient(180deg, rgba(15, 15, 15, 0.4) 0.99%, rgba(15, 15, 15, 0.00) 100%)',
          },
          '.post-title-container': {
            opacity: 1,
            pointerEvents: 'auto',
          },
        },
        ...sx,
      }}
      renderMenu={(props) => (
        <PostContextMenu
          post={post}
          renderButton={false}
          currentCollectionId={currentCollectionId}
          onSelectPost={() => {
            onSelectPost(post);
          }}
          {...props}
        />
      )}
      onClick={onClick}
    >
      {renderSmartSearchIcon(isSmartSearch)}
      <Box
        style={{
          height: '100%',
          minHeight: 'inherit',
          display: 'flex',
          position: 'relative',
        }}
      >
        <PostPreview post={post} disableMouseEvents />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          p: 4,
          color: postColor
            ? postColor.iconColor
            : theme.colors?.primary.parchment,
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
        {...(post.type !== PostType.Note ? { className: 'post-overlay' } : {})}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: 2,
          }}
        >
          <PostCreator post={post} sx={{ pointerEvents: 'auto' }} />
          {post.type !== PostType.Note && (
            <Box
              className="post-title-container"
              sx={{
                opacity: 0,
                pointerEvents: 'none',
              }}
            >
              <PostTitle
                post={post}
                variant="popover"
                sx={{
                  color: theme.colors?.primary.parchment,
                  ':hover': {
                    borderBottom: `1px dashed ${theme.colors?.utility[600]}`,
                  },
                }}
              />
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <PostNewBadge post={post} />
          <PostUnreadCommentBadge post={post} />
          <PostIcon post={post} size={20} />
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          px: 4,
          pb: 3,
          pt: 4.5,
          overflow: 'auto',
          width: '100%',
          backgroundImage:
            'linear-gradient(180deg, rgba(35, 6, 3, 0.00) 0%, rgba(35, 6, 3, 0.80) 100%)',
        }}
      >
        <PostReactionList
          post={post}
          variant="hide-zero"
          sx={{
            gap: 1.5,
          }}
          componentsProps={{
            reactionButton: {
              sx: {
                px: 2.5,
                py: 1,
                color: theme.colors?.primary.parchment,
                bgcolor: 'rgba(250, 243, 236, 0.1)',
                borderColor: 'rgba(250, 243, 236, 0.2)',
              },
            },
          }}
        />
      </Box>
    </StyledRightClickContextMenuWrapper>
  );
};
