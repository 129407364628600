import { Box, SxProps } from '@mui/material';
import { useRenderPostReportedSnackbar } from 'features/socialMediaListening/hooks/useRenderPostReportedSnackbar';
import { useSearchParams } from 'react-router-dom';
import { SocialMediaListeningPostCardView } from './postCard/SocialMediaListeningPostCardView';
import { SocialMediaListeningPostsGridViewProps } from './types';

export const SocialMediaListeningPostsGridView = ({
  brandId,
  socialPosts,
  searchCriteria,
  sortBy,
}: SocialMediaListeningPostsGridViewProps) => {
  const [params] = useSearchParams();
  const newSocialPostIds = (params.get('newSocialPostIds') || '').split('|');
  const {
    renderToast: renderToastForPostReported,
    showToast: showToastForPostReported,
  } = useRenderPostReportedSnackbar();

  if (!socialPosts) return null;

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 4,
        justifyContent: 'center',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        '@media (min-width: 1300px)': {
          gridTemplateColumns: `repeat(auto-fill,
            'minmax(380px, 1fr))`,
        },
        ...newSocialPostIds.reduce((acc, id) => {
          if (socialPosts.some((post) => post.id === id)) {
            // @ts-ignore
            acc[`& .social-post-card-${id}`] = {
              bgcolor: '#F8E8CA80',
            };
          }

          return acc;
        }, {} as SxProps),
      }}
    >
      {socialPosts.map((post, index) => (
        <SocialMediaListeningPostCardView
          brandId={brandId}
          key={post.id}
          post={post}
          searchCriteria={searchCriteria}
          itemPosition={(index + 1).toString()}
          className={`social-post-card-${post.id}`}
          onCardDeleted={() => {
            showToastForPostReported();
          }}
          sortBy={sortBy}
        />
      ))}
      {renderToastForPostReported()}
    </Box>
  );
};
