import { Box, Typography } from '@mui/material';
import Lottie from 'react-lottie';
import { theme } from 'styles/theme';
import analyticsLoading from '../../lotties/analyticsLoading.json';

const analyticsLoadingText = [
  'Loading mentions',
  'AI doing its thing',
  'Finding superfans',
  'Content hunting',
];

export const SLALoadingState = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        gap: 4,
      }}
    >
      <Lottie
        options={{
          animationData: analyticsLoading,
        }}
        isPaused={false}
        height={26}
        style={{
          borderRadius: theme.spacing(3),
        }}
      />
      <Typography
        variant="headline-md"
        fontSize={20}
        color={theme.colors?.utility[600]}
      >
        {
          analyticsLoadingText[
            Math.floor(Math.random() * analyticsLoadingText.length)
          ]
        }
      </Typography>
    </Box>
  );
};
