import { gql } from '@apollo/client';
import { Box, Checkbox, SxProps, TableCell } from '@mui/material';
import { useJuiceboxSelectContext } from 'features/juicebox/contexts';
import { PostFragmentJuiceboxTablePostTableRowCellSelectIconFragment } from 'graphql/generated';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment PostFragmentJuiceboxTablePostTableRowCellSelectIcon on PostModel {
    id
  }
`;

export type JuiceboxTablePostTableRowCellSelectIconProps = {
  post: PostFragmentJuiceboxTablePostTableRowCellSelectIconFragment;
  sx?: SxProps;
};

export const JuiceboxTablePostTableRowCellSelectIcon = (
  props: JuiceboxTablePostTableRowCellSelectIconProps,
) => {
  const { post, sx = {} } = props;

  const { selectedPostIds, onSelectPost } = useJuiceboxSelectContext();

  return (
    <TableCell sx={{ textAlign: 'right', ...sx }}>
      <Box
        sx={{
          ...(!selectedPostIds.includes(post.id) && {
            opacity: 0,
            pointerEvents: 'none',
          }),
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
        className="post-table-row-cell-select-icon"
      >
        <Checkbox
          checked={selectedPostIds.includes(post.id)}
          size="small"
          sx={{
            p: 0,
          }}
          disableRipple
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onSelectPost(post, e.shiftKey);
          }}
        />
      </Box>
    </TableCell>
  );
};
