import { IconBoldInstagram } from 'components/icons/components/bold/IconBoldInstagram';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import { IconCustomYoutubeShorts } from 'components/icons/components/custom/IconCustomYoutubeShorts';
import { Platform } from 'graphql/generated';

export const platformIconMap = {
  [Platform.Instagram]: IconBoldInstagram,
  [Platform.Tiktok]: IconCustomTiktok,
  [Platform.Youtube]: IconCustomYoutubeShorts,
};

export const platformLabelMap = {
  [Platform.Instagram]: 'Instagram',
  [Platform.Tiktok]: 'Tiktok',
  [Platform.Youtube]: 'YouTube Shorts',
};
