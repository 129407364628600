import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldInfoCircleAlt } from 'components/icons/components/bold/IconBoldInfoCircleAlt';
import { IconLinearArrowDown1 } from 'components/icons/components/linear/IconLinearArrowDown1';
import { IconLinearArrowRight3 } from 'components/icons/components/linear/IconLinearArrowRight3';
import { IconLinearArrowUp } from 'components/icons/components/linear/IconLinearArrowUp';
import { useUserContext } from 'contexts/users/User.context';
import { TopicBreakdownPopoverView } from 'features/topic';
import { useGetBrandsDataForWeeklySnapShotSummaryQuery } from 'graphql/generated';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  query GetBrandsDataForWeeklySnapShotSummary(
    $brandId: String!
    $data: BrandHoursParsedInput
  ) {
    brand(id: $brandId) {
      id
      brandMentionedStats {
        count
        percentageChange
        previousCount
      }
      outboundCommentsStats {
        count
        previousCount
        percentageChange
      }
      totalHoursParsedCount(data: $data)
    }
  }
`;

const TitleWithInfoTooltip = ({
  title,
  info,
}: {
  title: string;
  info: string;
}) => {
  return (
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      <Typography variant="headline-sm" color={theme.colors?.utility[700]}>
        {title}
      </Typography>
      <Tooltip title={info}>
        <Box component="span" display="flex" alignItems="center">
          <IconBoldInfoCircleAlt size={16} color={theme.colors?.utility[700]} />
        </Box>
      </Tooltip>
    </Box>
  );
};

const DefaultBrandMentionedStats = {
  count: 0,
  percentageChange: 0,
  previousCount: 0,
};

type Props = {
  brandId: string;
};

export const SocialMediaListeningWeeklySnapShotSummary = ({
  brandId,
}: Props) => {
  const { orgBilling } = useUserContext();
  const { data: brandData } = useGetBrandsDataForWeeklySnapShotSummaryQuery({
    variables: {
      brandId,
      data: {
        startDate: moment().startOf('month').toDate(),
        endDate: moment().endOf('month').toDate(),
      },
    },
    skip: !brandId,
  });

  const brand = brandData?.brand;

  const brandMentionedStats =
    brand?.brandMentionedStats || DefaultBrandMentionedStats;
  const outboundCommentsStats =
    brand?.outboundCommentsStats || DefaultBrandMentionedStats;
  const hourLimit = orgBilling?.brandHoursParseLimit || 0;

  const showParsingWarning =
    brand && hourLimit && brand.totalHoursParsedCount >= hourLimit * 0.8;

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        gap: 8,
        backgroundColor: theme.colors?.utility[250],
        p: theme.spacing(6, 9),
        borderRadius: 4,
      }}
    >
      <Box
        sx={{ position: 'relative', flex: 1, flexDirection: 'column', gap: 2 }}
      >
        <TitleWithInfoTooltip
          title="Number of brand mentions"
          info="Number of brand mentions"
        />
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            gap: 2,
            mt: 4,
            flexDirection: 'column',
          }}
        >
          <Typography variant="headline-xl" color={theme.colors?.primary.black}>
            {brandMentionedStats.count}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              width: '100%',
            }}
          >
            {brandMentionedStats.percentageChange >= 0 && (
              <IconLinearArrowUp
                size={16}
                color={theme.colors?.utility['green-3']}
              />
            )}
            {brandMentionedStats.percentageChange < 0 && (
              <IconLinearArrowDown1
                size={16}
                color={theme.colors?.utility['pink-3']}
              />
            )}
            <Typography
              color={theme.colors?.utility[700]}
              variant="body-lg"
              flex={1}
            >
              <Box
                component="span"
                color={
                  brandMentionedStats.percentageChange >= 0
                    ? theme.colors?.utility['green-3']
                    : theme.colors?.utility['pink-3']
                }
              >
                {parseFloat(brandMentionedStats.percentageChange.toFixed(1))}%
              </Box>{' '}
              vs last week
            </Typography>
          </Box>
        </Box>

        <Link to={PlotRoutes.socialListeningAnalyticsInbound(brandId)}>
          <Box
            sx={{ cursor: 'pointer' }}
            display="flex"
            alignItems="center"
            gap={1}
            position="absolute"
            right={0}
            top={0}
            mr={8}
          >
            <Typography variant="headline-xs">View</Typography>
            <IconLinearArrowRight3 size={16} />
          </Box>
        </Link>
      </Box>

      <Box
        sx={{
          width: '1px',
          borderRight: `1px solid ${theme.colors?.utility[300]}`,
        }}
      />

      <Box
        sx={{ position: 'relative', flex: 1, flexDirection: 'column', gap: 2 }}
      >
        <TitleWithInfoTooltip
          title="Number of outbound comments"
          info="Number of outbound comments"
        />
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            gap: 2,
            mt: 4,
            flexDirection: 'column',
          }}
        >
          <Typography variant="headline-xl" color={theme.colors?.primary.black}>
            {outboundCommentsStats.count}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              width: '100%',
            }}
          >
            <IconLinearArrowDown1
              size={16}
              color={theme.colors?.utility['pink-3']}
            />
            <Typography
              color={theme.colors?.utility[700]}
              variant="body-lg"
              flex={1}
            >
              <Box component="span" color={theme.colors?.utility['pink-3']}>
                {parseFloat(
                  Number(outboundCommentsStats.percentageChange).toFixed(1),
                )}
                %
              </Box>{' '}
              vs last week
            </Typography>
          </Box>
        </Box>
        <Link to={PlotRoutes.socialListeningAnalyticsOutbound(brandId)}>
          <Box
            sx={{ cursor: 'pointer' }}
            display="flex"
            alignItems="center"
            gap={1}
            position="absolute"
            right={0}
            top={0}
            mr={8}
          >
            <Typography variant="headline-xs">View</Typography>
            <IconLinearArrowRight3 size={16} />
          </Box>
        </Link>
      </Box>

      <Box
        sx={{
          width: '1px',
          borderRight: `1px solid ${theme.colors?.utility[300]}`,
        }}
      />

      <Box
        sx={{ position: 'relative', flex: 1, flexDirection: 'column', gap: 2 }}
      >
        <TitleWithInfoTooltip
          title="Hours of video parsed"
          info="Hours of video parsed"
        />
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            gap: 2,
            mt: 4,
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            variant="headline-xl"
            color={
              showParsingWarning
                ? theme.colors?.utility['pink-3']
                : theme.colors?.primary.black
            }
            display="flex"
            alignItems="center"
            gap={4}
          >
            {brand?.totalHoursParsedCount || '-'}

            {showParsingWarning ? (
              <TitleWithInfoTooltip
                title=""
                info="You're approaching the limit of number of hours parsed. Go to settings to pause parsing."
              />
            ) : null}
          </Typography>
          <TopicBreakdownPopoverView />
        </Box>
      </Box>
    </Box>
  );
};
