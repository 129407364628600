import { gql } from '@apollo/client';
import { Box, SxProps } from '@mui/material';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import {
  TopicPlatformParsingSetting,
  TopicPlatformParsingSettingProps,
} from 'features/topic/components';
import {
  ParsingSettingsInputData,
  Platform,
  PlotFeature,
  SocialPostType,
  useGetTopicForTopicParsingSettingsViewQuery,
  useUpdateTopicForTopicParsingSettingsViewMutation,
} from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useEffect, useMemo, useRef, useState } from 'react';

export const TOPIC_FRAGMENT_TOPIC_PARSING_SETTINGS_VIEW = gql`
  fragment TopicFragmentTopicParsingSettingsView on TopicModel {
    id
    parsingSettings {
      Instagram {
        enabled
        types {
          Photo
          Video
          Carousel
        }
      }
      Tiktok {
        enabled
      }
      Youtube {
        enabled
      }
    }
  }
`;

// eslint-disable-next-line
gql`
  query GetTopicForTopicParsingSettingsView($id: String!) {
    topic(id: $id) {
      id
      ...TopicFragmentTopicParsingSettingsView
    }
  }
  ${TOPIC_FRAGMENT_TOPIC_PARSING_SETTINGS_VIEW}
`;

// eslint-disable-next-line
gql`
  mutation UpdateTopicForTopicParsingSettingsView($data: UpdateTopicInput!) {
    updateTopic(data: $data) {
      id
      ...TopicFragmentTopicParsingSettingsView
    }
  }
  ${TOPIC_FRAGMENT_TOPIC_PARSING_SETTINGS_VIEW}
`;

export type TopicParsingSettingsViewProps = {
  topicId: string;
  readOnly?: boolean;
  sx?: SxProps;
  componentsProps?: {
    setting?: Pick<TopicPlatformParsingSettingProps, 'sx' | 'componentsProps'>;
  };
};

export const TopicParsingSettingsView = (
  props: TopicParsingSettingsViewProps,
) => {
  const { topicId, readOnly, sx, componentsProps = {} } = props;

  const { isFeatureEnabled } = useFeatureFlagContext();

  const { data } = useGetTopicForTopicParsingSettingsViewQuery({
    variables: {
      id: topicId,
    },
  });
  const topic = data?.topic;

  // NOTE: Default values should be referenced from defaultParsingSettings in BE
  const topicParsingSettings = useMemo(
    () => ({
      [Platform.Instagram]: {
        enabled: topic?.parsingSettings?.Instagram.enabled ?? false,
        types: {
          [SocialPostType.Photo]:
            topic?.parsingSettings?.Instagram.types?.Photo ?? true,
          [SocialPostType.Video]:
            topic?.parsingSettings?.Instagram.types?.Video ?? true,
          [SocialPostType.Carousel]:
            topic?.parsingSettings?.Instagram.types?.Carousel ?? true,
        },
      },
      [Platform.Tiktok]: {
        enabled: topic?.parsingSettings?.Tiktok.enabled ?? false,
      },
      [Platform.Youtube]: {
        enabled: topic?.parsingSettings?.Youtube.enabled ?? false,
      },
    }),
    [topic],
  );

  const [updateTopic] = useUpdateTopicForTopicParsingSettingsViewMutation();

  // Ensure we only load internalParsingSettings from topic once
  // Otherwise, the useEffect depending on topic would potentially cause infinite loop
  const hasLoadedInternalParsingSettings = useRef(false);
  const [internalParsingSettings, setInternalParsingSettings] =
    useState<ParsingSettingsInputData>(topicParsingSettings);

  const {
    dialog: confirmationDialog,
    onOpen: openConfirmationDialog,
    onClose: closeConfirmationDialog,
  } = useConfirmationDialog();

  const handleTogglePlatform = (platform: Platform, value: boolean) => {
    // If disabling platform, show confirmation dialog
    if (!value) {
      openConfirmationDialog({
        title: `Pause topic from ${platform}?`,
        subtitle: (
          <>
            Pausing will stop collecting new data from {platform}. Don't worry -
            all your existing data will remain available.
            <br />
            <br />
            You can resume collecting new data from {platform} for this topic
            anytime in settings.
          </>
        ),
        confirmText: 'Pause topic',
        cancelText: 'Cancel',
        onConfirm: () => {
          setInternalParsingSettings((prev) => ({
            ...prev,
            [platform]: {
              enabled: value,
            },
          }));
          closeConfirmationDialog();
        },
        onCancel: closeConfirmationDialog,
      });
    } else {
      setInternalParsingSettings((prev) => ({
        ...prev,
        [platform]: {
          ...prev[platform],
          enabled: value,
        },
      }));
    }
  };

  const handleToggleType = (
    platform: Platform,
    type: SocialPostType,
    value: boolean,
  ) => {
    setInternalParsingSettings((prev) => ({
      ...prev,
      [platform]: {
        ...prev[platform],
        types: {
          ...prev[platform].types,
          [type]: value,
        },
      },
    }));
  };

  // Update parsing settings when internalParsingSettings changes
  useEffect(() => {
    if (!topic || !hasLoadedInternalParsingSettings.current) {
      return;
    }

    if (
      JSON.stringify(topicParsingSettings) !==
      JSON.stringify(internalParsingSettings)
    ) {
      updateTopic({
        variables: {
          data: {
            topicId: topic.id,
            data: {
              parsingSettings: internalParsingSettings,
            },
          },
        },
        optimisticResponse: {
          updateTopic: {
            ...topic,
            parsingSettings: internalParsingSettings,
          },
        },
      });
    }
  }, [internalParsingSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!topic || hasLoadedInternalParsingSettings.current) {
      return;
    }

    setInternalParsingSettings(topicParsingSettings);
    hasLoadedInternalParsingSettings.current = true;
  }, [topicParsingSettings, topic]);

  if (!topic) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          ...sx,
        }}
      >
        <TopicPlatformParsingSetting
          readOnly={readOnly}
          platform={Platform.Instagram}
          types={[
            SocialPostType.Photo,
            SocialPostType.Video,
            SocialPostType.Carousel,
          ]}
          platformChecked={internalParsingSettings.Instagram.enabled ?? false}
          typesChecked={{
            [SocialPostType.Photo]:
              internalParsingSettings.Instagram.types?.Photo ?? true,
            [SocialPostType.Video]:
              internalParsingSettings.Instagram.types?.Video ?? true,
            [SocialPostType.Carousel]:
              internalParsingSettings.Instagram.types?.Carousel ?? true,
          }}
          onTogglePlatform={(value) =>
            handleTogglePlatform(Platform.Instagram, value)
          }
          onToggleType={(type, value) =>
            handleToggleType(Platform.Instagram, type, value)
          }
          {...componentsProps.setting}
        />
        <TopicPlatformParsingSetting
          readOnly={readOnly}
          platform={Platform.Tiktok}
          platformChecked={internalParsingSettings.Tiktok.enabled ?? false}
          onTogglePlatform={(value) =>
            handleTogglePlatform(Platform.Tiktok, value)
          }
          {...componentsProps.setting}
        />
        {isFeatureEnabled(PlotFeature.YoutubeParsing) && (
          <TopicPlatformParsingSetting
            readOnly={readOnly}
            platform={Platform.Youtube}
            platformChecked={internalParsingSettings.Youtube.enabled ?? false}
            onTogglePlatform={(value) =>
              handleTogglePlatform(Platform.Youtube, value)
            }
            {...componentsProps.setting}
          />
        )}
      </Box>
      {confirmationDialog}
    </>
  );
};
