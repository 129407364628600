import { gql } from '@apollo/client';
import { Box, Checkbox, IconButton, SxProps, TableCell } from '@mui/material';
import { IconLinearArrowRight } from 'components/icons/components/linear/IconLinearArrowRight';
import { useJuiceboxSelectContext } from 'features/juicebox/contexts';
import { CollectionFragmentJuiceboxTableCollectionTableRowCellSelectIconFragment } from 'graphql/generated';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment CollectionFragmentJuiceboxTableCollectionTableRowCellSelectIcon on CollectionModel {
    id
    isDisabled
  }
`;

export type JuiceboxTableCollectionTableRowCellSelectIconProps = {
  collection: CollectionFragmentJuiceboxTableCollectionTableRowCellSelectIconFragment;
  sx?: SxProps;

  isExpanded?: boolean;
  onToggleExpand?: (expanded: boolean) => void;
};

export const JuiceboxTableCollectionTableRowCellSelectIcon = (
  props: JuiceboxTableCollectionTableRowCellSelectIconProps,
) => {
  const { collection, sx = {}, isExpanded, onToggleExpand } = props;

  const { selectedCollectionIds, onSelectCollection } =
    useJuiceboxSelectContext();

  return (
    <TableCell
      sx={{ textAlign: 'right', ...sx }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          ...(!selectedCollectionIds.includes(collection.id) && {
            opacity: 0,
            pointerEvents: 'none',
          }),
        }}
        className="collection-table-row-cell-select-icon"
      >
        <Checkbox
          checked={selectedCollectionIds.includes(collection.id)}
          size="small"
          sx={{
            p: 0,
          }}
          disableRipple
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onSelectCollection(collection, e.shiftKey);
          }}
        />
      </Box>
      {onToggleExpand && (
        <IconButton
          disableRipple
          onClick={(e) => {
            onToggleExpand(!isExpanded);
          }}
          sx={{
            transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)',
          }}
        >
          <IconLinearArrowRight size={16} />
        </IconButton>
      )}
    </TableCell>
  );
};
