import { Box, IconButton, Menu, Typography } from '@mui/material';
import { CheckboxMenuItem } from 'components/common/form/Select';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import { IconLinearMonitorMobile } from 'components/icons/components/linear/IconLinearMonitorMobile';
import { IconOutlineArrowDown2 } from 'components/icons/components/outline/IconOutlineArrowDown2';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { platformIconMap, platformLabelMap } from 'features/socialPost';
import { Platform, PlotFeature } from 'graphql/generated';
import { useState } from 'react';
import { theme } from 'styles/theme';

type Props = {
  selectedPlatforms: Platform[];
  onPlatformSelect: (platform: Platform[]) => void;
};

export const BrandCompetitorsPlatformSelector = ({
  selectedPlatforms,
  onPlatformSelect,
}: Props) => {
  const { isFeatureEnabled } = useFeatureFlagContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const options = [
    {
      label: platformLabelMap[Platform.Tiktok],
      value: Platform.Tiktok,
      Icon: platformIconMap[Platform.Tiktok],
    },
    {
      label: platformLabelMap[Platform.Instagram],
      value: Platform.Instagram,
      Icon: platformIconMap[Platform.Instagram],
    },
    ...(isFeatureEnabled(PlotFeature.YoutubeParsing)
      ? [
          {
            label: platformLabelMap[Platform.Youtube],
            value: Platform.Youtube,
            Icon: platformIconMap[Platform.Youtube],
          },
        ]
      : []),
  ];

  return (
    <Box>
      <IconButton
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          justifyContent: 'center',
          bgcolor: theme.colors?.utility[275],
          borderRadius: theme.spacing(2),
          p: theme.spacing(2, 3.5),
          color: theme.colors?.primary.black,
        }}
        disableRipple
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      >
        <IconLinearMonitorMobile size={16} />

        <Typography variant="subhead-xl" ml={1}>
          Platforms
        </Typography>
        {!!selectedPlatforms.length && (
          <Typography variant="subhead-xl" color={theme.colors?.utility[700]}>
            ({selectedPlatforms.length})
          </Typography>
        )}

        <IconOutlineArrowDown2 size={16} color={theme.colors?.utility[700]} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            minWidth: 244,
            padding: 4,
            background: 'rgba(255, 255, 255, 0.80)',
            backdropFilter: 'blur(20px)',
            boxShadow:
              '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
            border: 'none',
            '& ul > :first-child': {
              display: 'none',
            },
          },
        }}
      >
        <DropDownMoreMenuItem
          disableRipple
          // eslint-disable-next-line
          label={<></>}
          defaultOpen
          componentsProps={{
            dropDownOptions: {
              container: {
                sx: {
                  gap: 2,
                },
              },
            },
          }}
          dropDownOptions={options.map((option) => ({
            label: option.label,
            value: option.value,
            renderOption: () => (
              <CheckboxMenuItem
                key={option.value}
                value={option.value}
                checked={selectedPlatforms.includes(option.value)}
                label={
                  <Box display="flex" gap={2} alignItems="center">
                    <option.Icon size={16} color={theme.colors?.utility[700]} />
                    <Typography variant="headline-xs">
                      {option.label}
                    </Typography>
                  </Box>
                }
                onClick={() => {
                  const value = option.value;
                  let newPlatforms = selectedPlatforms;
                  if (selectedPlatforms.includes(value)) {
                    newPlatforms = selectedPlatforms.filter((p) => p !== value);
                  } else {
                    newPlatforms = [...selectedPlatforms, value];
                  }
                  onPlatformSelect(newPlatforms);
                }}
                checkboxPosition="end"
              />
            ),
            isChecked: selectedPlatforms.includes(option.value),
          }))}
        />
      </Menu>
    </Box>
  );
};
