import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { HighlightedTextRenderer } from 'features/search';
import { CapturedProductAttributeUsageFragmentForSlaRankingTableAttributesFragment } from 'graphql/generated';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';

// eslint-disable-next-line
gql`
  fragment CapturedProductAttributeUsageFragmentForSLARankingTableAttributes on CapturedProductAttributeUsage {
    attribute
    attributeId
    count
  }
`;

type SLARankingTableAttributesProps = {
  attributeUsage: CapturedProductAttributeUsageFragmentForSlaRankingTableAttributesFragment[];
  onSelectAttribute: (
    attribute: CapturedProductAttributeUsageFragmentForSlaRankingTableAttributesFragment,
  ) => void;
  searchedTerm: string;
};

export const SLARankingTableAttributes = ({
  attributeUsage,
  onSelectAttribute,
  searchedTerm,
}: SLARankingTableAttributesProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      {!attributeUsage.length
        ? '-'
        : attributeUsage.slice(0, 2).map((attribute) => (
            <Typography
              variant="subhead-xl"
              component="span"
              sx={{
                ':hover': {
                  textDecoration: 'underline',
                },
                width: 'fit-content',
              }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onSelectAttribute(attribute);
              }}
            >
              <HighlightedTextRenderer
                text={attribute.attribute}
                match={searchedTerm}
              />{' '}
              {attribute.count > 0 && (
                <Typography
                  component="span"
                  variant="subhead-xl"
                  color={theme.colors?.utility[600]}
                >
                  ({formatBigNumber(attribute.count)})
                </Typography>
              )}
            </Typography>
          ))}
      {attributeUsage.length > 2 && (
        <Tooltip
          placement="bottom-start"
          title={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                maxHeight: 240,
                overflowX: 'auto',
                '::-webkit-scrollbar': {
                  width: theme.spacing(1), // Width of the scrollbar
                },
                '::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.colors?.utility[600], // Thumb color
                },
                '::-webkit-scrollbar-track': {
                  backgroundColor: theme.colors?.primary.parchment, // Track color
                },
              }}
            >
              {attributeUsage.slice(2).map((attribute) => (
                <Typography
                  variant="subhead-sm"
                  color={theme.colors?.primary.parchment}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onSelectAttribute(attribute);
                  }}
                  sx={{
                    ':hover': {
                      textDecoration: 'underline',
                    },
                    width: 'fit-content',
                    cursor: 'pointer',
                  }}
                >
                  <HighlightedTextRenderer
                    text={attribute.attribute}
                    match={searchedTerm}
                  />
                </Typography>
              ))}
            </Box>
          }
        >
          <Typography variant="subhead-xl" color={theme.colors?.utility[700]}>
            +{attributeUsage.length - 2} more
          </Typography>
        </Tooltip>
      )}
    </Box>
  );
};
