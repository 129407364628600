import { gql } from '@apollo/client';
import { Box, Button, Card, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { RadioMenuItem } from 'components/common/form/Select/RadioMenuItem';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import {
  BrandInboundFiltersInput,
  BrandInboundFiltersInputForSentimentMetrics,
  Sentiment,
  SentimentMedium,
  useGetBrandInboundSentimentMetricsQuery,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import { theme } from 'styles/theme';
import { SLABrandInboundSentimentsView } from './SLABrandInboundSentimentsView';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetBrandInboundSentimentMetrics(
    $data: BrandInboundFiltersInputForSentimentMetrics!
  ) {
    brandInboundSentimentMetrics(data: $data) {
      sentimentMetrics {
        averageCountPerDay
        chartDataPoints {
          Y
          X
        }
        totalCount
      }
      claimMetrics {
        numberOfMentions
        attribute
      }
    }
  }
`;

type Props = {
  filters: BrandInboundFiltersInput;
  hideInPosts?: boolean;
};

export const SLABrandInboundSentimentsContainerView = ({
  filters,
  hideInPosts,
}: Props) => {
  const [medium, setMedium] = useState<SentimentMedium>(
    hideInPosts ? SentimentMedium.InComments : SentimentMedium.InPosts,
  );

  useEffect(() => {
    setMedium(
      hideInPosts && medium === SentimentMedium.InPosts
        ? SentimentMedium.InComments
        : medium,
    );
  }, [hideInPosts]);

  const generatePayload = (
    sentiment: Sentiment,
  ): BrandInboundFiltersInputForSentimentMetrics => ({
    ...filters,
    sentiment,
    medium,
  });

  const {
    data: positiveSentimentMetricsData,
    loading: positiveSentimentMetricsLoading,
  } = useGetBrandInboundSentimentMetricsQuery({
    variables: {
      data: generatePayload(Sentiment.Positive),
    },
  });

  const {
    data: neutralSentimentMetricsData,
    loading: neutralSentimentMetrics,
  } = useGetBrandInboundSentimentMetricsQuery({
    variables: {
      data: generatePayload(Sentiment.Neutral),
    },
  });

  const {
    data: negativeSentimentMetricsData,
    loading: negativeSentimentMetricsLoading,
  } = useGetBrandInboundSentimentMetricsQuery({
    variables: {
      data: generatePayload(Sentiment.Negative),
    },
  });

  const isLoading =
    positiveSentimentMetricsLoading ||
    neutralSentimentMetrics ||
    negativeSentimentMetricsLoading;

  return (
    <Box my={6}>
      <Card
        sx={{
          borderRadius: 5,
          p: 6,
          boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Box width="100%" display="flex" justifyContent="space-between">
          <Typography variant="headline-lg" fontSize={theme.spacing(5)}>
            Sentiment
          </Typography>
          <Box display="flex" gap={3} alignItems="center">
            <Box display="flex" alignItems="center" gap={3}>
              <ContextMenu
                sx={{
                  '& .context-menu-item': {
                    p: 0,
                    color: theme.colors?.primary.black,
                  },
                }}
                options={[
                  ...(!hideInPosts
                    ? [
                        {
                          renderOption: () => (
                            <RadioMenuItem
                              label="In Posts"
                              value={SentimentMedium.InPosts}
                              checked={medium === SentimentMedium.InPosts}
                              sx={{
                                p: `${theme.spacing(2, 3)}`,
                              }}
                            />
                          ),
                          onClick: () => {
                            setMedium(SentimentMedium.InPosts);
                          },
                        },
                      ]
                    : []),
                  {
                    renderOption: () => (
                      <RadioMenuItem
                        label="In Comments"
                        value={SentimentMedium.InComments}
                        checked={medium === SentimentMedium.InComments}
                        sx={{
                          p: `${theme.spacing(2, 3)}`,
                        }}
                      />
                    ),
                    onClick: () => {
                      setMedium(SentimentMedium.InComments);
                    },
                  },
                ]}
                renderButton={() => (
                  <Button
                    sx={{
                      backgroundColor: theme.colors?.utility[275],
                      color: theme.colors?.primary.black,
                      borderRadius: theme.spacing(2),
                      display: 'flex',
                      gap: 2,
                      p: theme.spacing(1, 2),
                      alignItems: 'center',
                      '&:hover': {
                        backgroundColor: theme.colors?.utility[275],
                      },
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        backgroundColor: 'rgba(35, 6, 3, 0.05);',
                        p: theme.spacing(1),
                        borderRadius: theme.spacing(1),
                      }}
                    >
                      <IconLinearSort size={16} />
                    </Box>
                    <Typography fontWeight={500} variant="body-xl">
                      {medium === SentimentMedium.InPosts
                        ? 'In Posts'
                        : 'In Comments'}
                    </Typography>
                  </Button>
                )}
              />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            mt: 6,
            borderBottom: `1px solid ${theme.colors?.utility[275]}`,
          }}
        />

        <SLABrandInboundSentimentsView filters={filters} medium={medium} />
      </Card>
    </Box>
  );
};
