import { Box, Typography } from '@mui/material';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { useCustomHeaderContext } from 'components/layouts/CustomHeader/contexts/CustomHeader.context';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useUserContext } from 'contexts/users/User.context';
import { BrandCompetitorDetailView } from 'features/brandCompetitors/views/brandCompetitorDetail';
import { SocialListeningUserPermissionsView } from 'features/socialListeningPermissions';
import { PlotFeature } from 'graphql/generated';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';

export const SocialMediaListeningCompetitorDetailPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { competitorId = '', brandId = '' } = useParams();
  const { user } = useUserContext();

  const {
    setHeaderMenuOptions,
    setRenderTitle,
    addRenderMenuExtraLeft,
    removeRenderMenuExtraLeft,
  } = useCustomHeaderContext();
  const { isFeatureEnabled, loading } = useFeatureFlagContext();

  useEffect(() => {
    if (!loading) {
      const _isFeatureEnabled = isFeatureEnabled(
        PlotFeature.CompetitorAnalysis,
      );
      if (
        !_isFeatureEnabled &&
        !user?.socialListeningBrands.find((b) => b.id === brandId)
      ) {
        navigate(PlotRoutes.home());
      }
    }
  }, [isFeatureEnabled, loading]); // eslint-disable-line
  useEffect(() => {
    const renderMenuExtraLeft = () => (
      <SocialListeningUserPermissionsView brandId={brandId} />
    );
    addRenderMenuExtraLeft(renderMenuExtraLeft, 1);

    setRenderTitle(() => (
      <Box
        component="button"
        onClick={() => navigate(-1)}
        display="flex"
        alignItems="center"
        gap={4}
        sx={{
          cursor: 'pointer',
          textAlign: 'left',
          overflow: 'hidden',
          flex: 1,
        }}
      >
        <IconOutlineArrowLeft
          size={24}
          color={theme.colors?.primary.black}
          style={{ flexShrink: 0 }}
        />
        <Typography
          variant="headline-sm"
          color={theme.colors?.primary.black}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          📣 Social Listening
        </Typography>
      </Box>
    ));
    setHeaderMenuOptions({
      copyLink: false,
      search: true,
      inbox: true,
    });

    return () => {
      removeRenderMenuExtraLeft(renderMenuExtraLeft);
    };
  }, [location.pathname]); // eslint-disable-line

  if (!competitorId) {
    navigate('/');
  }

  return (
    <BrandCompetitorDetailView
      currentBrandId={brandId}
      competitorId={competitorId}
    />
  );
};
