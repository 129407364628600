import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material';
import { CustomStackedBarChart } from 'components/common/CustomStackedBarChart';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { useUserContext } from 'contexts/users/User.context';
import {
  BrandBreakdownDialogView,
  BrandMoreContextMenu,
  BrandPauseContextMenu,
} from 'features/brand';
import { TopicMoreContextMenu, TopicPauseContextMenu } from 'features/topic';
import {
  BrandFragmentBrandMoreContextMenuFragmentDoc,
  BrandFragmentBrandPauseContextMenuFragmentDoc,
  InternalOrganizationRole,
  TopicFragmentTopicMoreContextMenuFragmentDoc,
  TopicFragmentTopicPauseContextMenuFragmentDoc,
  useAggregatedBrandDurationUsageForBrandDurationUsageDialogViewQuery,
  useUpdateHourUsageThresholdAlertForBrandDurationUsageDialogViewMutation,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import _ from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { UpdateHourLimitDialog } from './UpdateHourLimitDialog';

// eslint-disable-next-line
gql`
  mutation UpdateHourUsageThresholdAlertForBrandDurationUsageDialogView(
    $data: UpdateSocialListeningHourUsageThresholdAlertInput!
  ) {
    updateSocialListeningHourUsageThresholdAlert(data: $data) {
      id
    }
  }
`;

// eslint-disable-next-line
gql`
  query AggregatedBrandDurationUsageForBrandDurationUsageDialogView(
    $filters: AggregatedBrandDurationUsageFilters!
  ) {
    aggregatedBrandDurationUsage(filters: $filters) {
      durationUsed
      totalTopicsCount
      topics {
        durationUsed
        topic {
          id
          name
          ...TopicFragmentTopicMoreContextMenu
          ...TopicFragmentTopicPauseContextMenu
        }
      }
      brand {
        id
        name
        creators {
          id
          profilePictureUrl
        }
        ...BrandFragmentBrandPauseContextMenu
        ...BrandFragmentBrandMoreContextMenu
      }
    }
  }
  ${BrandFragmentBrandPauseContextMenuFragmentDoc}
  ${BrandFragmentBrandMoreContextMenuFragmentDoc}
  ${TopicFragmentTopicMoreContextMenuFragmentDoc}
  ${TopicFragmentTopicPauseContextMenuFragmentDoc}
`;

export const BrandDurationUsageDialogView = () => {
  const brandBreakdownDialog = useDisclosure();
  const updateHourLimitDialog = useDisclosure();
  const hourUsageLimitDialog = useDisclosure();
  const navigate = useNavigate();
  const { orgBilling, user } = useUserContext();
  const analytics = useAnalytics();

  const [updateHourUsageThresholdAlert] =
    useUpdateHourUsageThresholdAlertForBrandDurationUsageDialogViewMutation();

  const { data: brandData } =
    useAggregatedBrandDurationUsageForBrandDurationUsageDialogViewQuery({
      variables: {
        filters: {
          dateRange: {
            startDate: moment().startOf('month').toDate(),
            endDate: moment().endOf('month').toDate(),
          },
        },
      },
      fetchPolicy: 'cache-and-network',
      skip: !hourUsageLimitDialog.isOpen,
    });

  const {
    totalHours,
    usagePercentage,
    topBrands,
    hoursUsed,
    showTopicDirectly,
  } = useMemo(() => {
    const brands = brandData?.aggregatedBrandDurationUsage ?? [];
    const hoursUsed = brands.reduce(
      (acc, brand) => acc + Math.ceil(brand.durationUsed / 3600),
      0,
    );

    // return only top 3 brands where durationUsed is highest
    let topBrands = _.orderBy(brands, 'durationUsed', 'desc').slice(0, 3);
    const totalHours = orgBilling?.brandHoursParseLimit || 0;
    const showTopicDirectly = topBrands.length === 1;

    if (showTopicDirectly) {
      const clonedTopBrands = _.cloneDeep(topBrands);
      clonedTopBrands[0].topics = _.orderBy(
        clonedTopBrands[0].topics,
        'durationUsed',
        'desc',
      ).slice(0, 3);
      topBrands = clonedTopBrands;
    }

    const usagePercentage = (hoursUsed / totalHours) * 100;

    return {
      totalHours,
      usagePercentage: usagePercentage < 100 ? usagePercentage : 100,
      topBrands,
      hoursUsed,
      brands,
      showTopicDirectly,
    };
  }, [
    orgBilling?.brandHoursParseLimit,
    brandData?.aggregatedBrandDurationUsage,
  ]);

  useEffect(() => {
    if (!user || !orgBilling) return;

    if (
      orgBilling.hasReachedBrandUsageLimit &&
      user.role === InternalOrganizationRole.Admin
    ) {
      const dialogAlertThresholdPercentage =
        orgBilling.socialListeningHourUsageThresholdAlert
          .dialogAlertThresholdPercentage;

      // we need to show the dialog once within these range
      const ranges = [
        { min: 50, max: 59, threshold: 50 },
        { min: 70, max: 79, threshold: 70 },
        { min: 80, max: 89, threshold: 80 },
        { min: 90, max: 99, threshold: 90 },
        { min: 100, max: 100, threshold: 100 },
      ];

      // If the user has already reached the dialog alert threshold, don't show the dialog
      const range = ranges.find(
        (r) =>
          usagePercentage >= r.min &&
          usagePercentage <= r.max &&
          dialogAlertThresholdPercentage < r.threshold,
      );

      if (range && totalHours !== 0) {
        hourUsageLimitDialog.onOpen();
      }
    }
    // eslint-disable-next-line
  }, [usagePercentage, user, orgBilling]);

  const topicChartData = useMemo(() => {
    if (!showTopicDirectly) return [];

    const topicData = topBrands[0].topics.map((topic) => ({
      label: topic.topic.name,
      value: Math.ceil(topic.durationUsed / 3600),
    }));

    return [
      ...topicData,
      {
        label: 'Remaining',
        value: Math.max(totalHours - hoursUsed, 0),
        color: 'transparent',
      },
    ];
  }, [topBrands, totalHours, hoursUsed, showTopicDirectly]);

  const onUpdateHourUsageThresholdAlert = useCallback(() => {
    const data = {
      dialogAlertThresholdPercentage: Math.floor(usagePercentage),
    };

    updateHourUsageThresholdAlert({
      variables: {
        data: {
          data,
        },
      },
    });
  }, [updateHourUsageThresholdAlert, usagePercentage]);

  const onCloseDialog = useCallback(() => {
    onUpdateHourUsageThresholdAlert();
    hourUsageLimitDialog.onClose();
  }, [onUpdateHourUsageThresholdAlert, hourUsageLimitDialog]);

  const onAgreeOverageCharges = useCallback(() => {
    analytics.track(EventName.SocialListeningOverageChargesAgreed, {
      page: 'UpdateHourLimitDialog',
      user,
      usagePercentage,
      // date of when the user agreed to the overage charges
      agreedAt: moment().toDate(),
    });
  }, [user, usagePercentage]); // eslint-disable-line

  const progressBarColor =
    usagePercentage >= 90
      ? 'pink-3'
      : usagePercentage >= 80
      ? 'orange-3'
      : usagePercentage >= 70
      ? 'yellow-3'
      : 'green-3';

  return (
    <>
      <Dialog
        open={hourUsageLimitDialog.isOpen}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 6,
            background: 'rgba(250, 243, 236)',
            padding: theme.spacing(6, 8),
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            maxWidth: 520,
          },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            sx={{
              p: 0,
            }}
            onClick={onCloseDialog}
          >
            <IconBoldCloseCircle color={theme.colors?.utility[600]} />
          </IconButton>
        </Box>
        <DialogTitle sx={{ padding: 0 }}>
          <Typography
            variant="headline-xl"
            fontSize={28}
            fontWeight={500}
            sx={{
              lineHeight: 'normal',
              letterSpacing: '-1.12px',
            }}
          >
            {usagePercentage === 100
              ? '⚠️ You’ve hit 100% of monthly hours used.'
              : `Approaching limit: ${Math.floor(
                  usagePercentage,
                )}% of monthly hours used.`}
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ padding: 0 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="subhead-xl" color={theme.colors?.utility[900]}>
              Total hours parsed:{' '}
              <Typography
                variant="headline-sm"
                color={
                  usagePercentage === 100
                    ? theme.colors?.utility['pink-3']
                    : theme.colors?.primary.black
                }
              >
                {hoursUsed}/{totalHours}
              </Typography>
            </Typography>
            {showTopicDirectly ? (
              <CustomStackedBarChart
                data={topicChartData}
                renderTooltipContent={(i) => {
                  return `${i.label}: ${i.value} hour${i.value > 1 ? 's' : ''}`;
                }}
              />
            ) : (
              <LinearProgress
                variant="determinate"
                value={usagePercentage}
                sx={{
                  height: 20,
                  borderRadius: 1,
                  backgroundColor: theme.colors?.primary.white,
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: theme.colors?.utility[progressBarColor],
                  },
                }}
              />
            )}
          </Box>

          <Divider sx={{ my: 6, borderColor: 'rgba(35, 6, 3, 0.10)' }} />

          <Typography variant="headline-md" color={theme.colors?.utility[1100]}>
            {showTopicDirectly ? 'Topics' : 'Brands'} with the highest hour
            usage:
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: theme.spacing(3, 4),
              borderRadius: 2,
              background: 'rgba(35, 6, 3, 0.05)',
              my: 4,
            }}
          >
            {showTopicDirectly
              ? topBrands[0].topics.map(({ durationUsed, topic }, i) => (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        ':hover': {
                          '.actions': {
                            opacity: 1,
                          },
                        },
                      }}
                    >
                      <Box
                        key={i}
                        sx={{
                          display: 'flex',
                          gap: 2,
                          flexDirection: 'column',
                        }}
                      >
                        <Typography
                          variant="subhead-xl"
                          color={theme.colors?.primary.black}
                        >
                          {topic.name}
                        </Typography>
                        <Typography
                          variant="subhead-lg"
                          color={theme.colors?.utility[800]}
                        >
                          {Math.ceil(durationUsed / 3600)} hours parsed
                        </Typography>
                      </Box>

                      <Box
                        className="actions"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          opacity: 0,
                          justifyContent: 'center',
                        }}
                      >
                        <TopicPauseContextMenu topic={topic} disablePortal />
                        <TopicMoreContextMenu topic={topic} disablePortal />
                      </Box>
                    </Box>
                    {i + 1 !== topBrands[0].topics.length && (
                      <Divider
                        sx={{
                          my: 4,
                        }}
                      />
                    )}
                  </>
                ))
              : topBrands.map((usage, index) => (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        ':hover': {
                          '.actions': {
                            opacity: 1,
                          },
                        },
                      }}
                    >
                      <Box
                        key={index}
                        sx={{
                          display: 'flex',
                          gap: 2,
                          flexDirection: 'column',
                        }}
                      >
                        <Box sx={{ display: 'flex', gap: 2 }}>
                          <Avatar
                            sx={{ width: 20, height: 20 }}
                            src=""
                            alt={
                              usage.brand.creators?.[0]?.profilePictureUrl ||
                              usage.brand.creators?.[1]?.profilePictureUrl ||
                              ''
                            }
                          />
                          <Typography
                            variant="subhead-xl"
                            color={theme.colors?.primary.black}
                          >
                            {usage.brand.name}
                          </Typography>
                        </Box>
                        <Typography
                          variant="subhead-lg"
                          color={theme.colors?.utility[800]}
                        >
                          {Math.ceil(usage.durationUsed / 3600)} hours parsed •{' '}
                          {usage.totalTopicsCount} topics
                        </Typography>
                      </Box>

                      <Box
                        className="actions"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          opacity: 0,
                          justifyContent: 'center',
                        }}
                      >
                        <BrandPauseContextMenu
                          brand={usage.brand}
                          disablePortal
                        />
                        <BrandMoreContextMenu
                          brand={usage.brand}
                          disablePortal
                        />
                      </Box>
                    </Box>
                    {index + 1 !== topBrands.length && (
                      <Divider
                        sx={{ my: 4, borderColor: theme.colors?.utility[500] }}
                      />
                    )}
                  </>
                ))}
          </Box>
          {usagePercentage === 100 ? (
            <Typography variant="subhead-xl" color={theme.colors?.utility[900]}>
              Your topic parsing allowance has been reached. Remove or pause
              topics to avoid additional charges, or continue parsing with{' '}
              <b>overage fees of $150 for 25 extra hours,</b> billed at the end
              of this quarter.
            </Typography>
          ) : (
            <Typography variant="subhead-xl" color={theme.colors?.utility[900]}>
              Review your topic settings—usage over your monthly limit includes
              additional charges
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              if (usagePercentage === 100) {
                onAgreeOverageCharges();
              } else {
                updateHourLimitDialog.onOpen();
              }
              onCloseDialog();
            }}
            sx={{
              color: theme.colors?.primary.black,
            }}
          >
            {usagePercentage === 100 ? 'Continue' : 'Skip for now'}
          </Button>
          <Button
            variant="primary-alt"
            onClick={() => {
              if (showTopicDirectly) {
                navigate(PlotRoutes.socialListeningSettings());
              } else {
                brandBreakdownDialog.onOpen();
              }
              onCloseDialog();
            }}
            sx={{
              borderRadius: 2,
            }}
          >
            Update topics
          </Button>
        </DialogActions>
      </Dialog>
      {brandBreakdownDialog.isOpen && (
        <BrandBreakdownDialogView
          onClose={brandBreakdownDialog.onClose}
          open={brandBreakdownDialog.isOpen}
        />
      )}
      <UpdateHourLimitDialog
        open={updateHourLimitDialog.isOpen}
        onAgreeOverageCharges={() => {
          onAgreeOverageCharges();
          updateHourLimitDialog.onClose();
        }}
      />
    </>
  );
};
