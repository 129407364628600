import { gql } from '@apollo/client';
import {
  CollectionFragmentJuiceboxTableCollectionTableRowCellActivityFragmentDoc,
  CollectionFragmentJuiceboxTableCollectionTableRowCellNameFragmentDoc,
  CollectionFragmentJuiceboxTableCollectionTableRowCellPermissionFragmentDoc,
  CollectionFragmentJuiceboxTableCollectionTableRowCellPreviewFragmentDoc,
  CollectionFragmentJuiceboxTableCollectionTableRowCellSelectIconFragmentDoc,
  CollectionFragmentJuiceboxTableCollectionTableRowFragment,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import {
  JuiceboxTableCollectionTableRowCellActivity,
  JuiceboxTableCollectionTableRowCellName,
  JuiceboxTableCollectionTableRowCellPermission,
  JuiceboxTableCollectionTableRowCellPreview,
  JuiceboxTableCollectionTableRowCellSelectIcon,
} from './cells';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment CollectionFragmentJuiceboxTableCollectionTableRow on CollectionModel {
    id
    ...CollectionFragmentJuiceboxTableCollectionTableRowCellSelectIcon
    ...CollectionFragmentJuiceboxTableCollectionTableRowCellActivity
    ...CollectionFragmentJuiceboxTableCollectionTableRowCellName
    ...CollectionFragmentJuiceboxTableCollectionTableRowCellPermission
    ...CollectionFragmentJuiceboxTableCollectionTableRowCellPreview
  }
  ${CollectionFragmentJuiceboxTableCollectionTableRowCellSelectIconFragmentDoc}
  ${CollectionFragmentJuiceboxTableCollectionTableRowCellActivityFragmentDoc}
  ${CollectionFragmentJuiceboxTableCollectionTableRowCellNameFragmentDoc}
  ${CollectionFragmentJuiceboxTableCollectionTableRowCellPermissionFragmentDoc}
  ${CollectionFragmentJuiceboxTableCollectionTableRowCellPreviewFragmentDoc}
`;

export type JuiceboxTableCollectionTableRowProps = {
  collection: CollectionFragmentJuiceboxTableCollectionTableRowFragment;

  expanded?: boolean;
  onToggleExpand?: (expanded: boolean) => void;

  isRenaming: boolean;
  setIsRenaming: (value: boolean) => void;
};

export const JuiceboxTableCollectionTableRow = (
  props: JuiceboxTableCollectionTableRowProps,
) => {
  const { collection, expanded, onToggleExpand } = props;

  return (
    <>
      <JuiceboxTableCollectionTableRowCellSelectIcon
        collection={collection}
        sx={{
          display: 'flex',
          minWidth: theme.spacing(10),
          border: 'none',
          flex: 0.2,
          mr: 2,
        }}
        isExpanded={expanded}
        onToggleExpand={onToggleExpand}
      />
      <JuiceboxTableCollectionTableRowCellName
        collection={collection}
        sx={{
          display: 'flex',
          flex: 6,
          width: '35%',
          minWidth: theme.spacing(50),
          maxWidth: theme.spacing(120),
          border: 'none',
        }}
        isRenaming={props.isRenaming}
        setIsRenaming={props.setIsRenaming}
      />
      <JuiceboxTableCollectionTableRowCellPreview
        collection={collection}
        sx={{
          display: 'flex',
          flex: 6,
          width: '20%',
          minWidth: theme.spacing(50),
          border: 'none',
        }}
      />
      <JuiceboxTableCollectionTableRowCellPermission
        collection={collection}
        sx={{
          display: 'flex',
          flex: 4,
          width: '20%',
          minWidth: theme.spacing(50),
          border: 'none',
        }}
      />
      <JuiceboxTableCollectionTableRowCellActivity
        collection={collection}
        sx={{
          display: 'flex',
          flex: 4,
          width: '20%',
          minWidth: theme.spacing(50),
          border: 'none',
        }}
      />
    </>
  );
};
