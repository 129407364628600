import { gql } from '@apollo/client';
import { Box, SxProps } from '@mui/material';
import { MoreMenuItem } from 'components/common/Menu/MoreMenuItem';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { IconLinearAddCircle } from 'components/icons/components/linear/IconLinearAddCircle';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { useIsCollectionSavedToContentCalendar } from 'features/collection/hooks';
import {
  CollectionFragmentCollectionBreadcrumbsFragmentDoc,
  CollectionFragmentCollectionListItemViewFragmentDoc,
  CollectionFragmentCollectionMenuItemViewFragment,
  CollectionFragmentCollectionMenuItemViewFragmentDoc,
  CollectionFragmentCollectionSavedToContentCalendarFragmentDoc,
  useGetCollectionForCollectionMenuItemViewLazyQuery,
} from 'graphql/generated';
import { ReactNode } from 'react';
import { theme } from 'styles/theme';
import {
  CollectionBreadcrumbs,
  CollectionBreadcrumbsProps,
} from '../../components';
import {
  CollectionListItemView,
  CollectionListItemViewProps,
} from '../listItem';
import {
  CollectionAutocompleteMenu,
  CollectionAutocompleteMenuProps,
} from './CollectionAutocompleteMenu';
import { COLLECTION_MENU_ITEM_ADD_NEW_VALUE_KEY } from './CollectionMenuItemAddNewView';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment CollectionFragmentCollectionMenuItemView on CollectionModel {
    id
    myPermissions
    ...CollectionFragmentCollectionListItemView
    ...CollectionFragmentCollectionBreadcrumbs
    ...CollectionFragmentCollectionSavedToContentCalendar
  }
  ${CollectionFragmentCollectionListItemViewFragmentDoc}
  ${CollectionFragmentCollectionBreadcrumbsFragmentDoc}
  ${CollectionFragmentCollectionSavedToContentCalendarFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetCollectionForCollectionMenuItemView($collectionId: String!) {
    collection(id: $collectionId) {
      ...CollectionFragmentCollectionMenuItemView
    }
  }
  ${CollectionFragmentCollectionMenuItemViewFragmentDoc}
`;

const menuItemStyles: SxProps = {
  borderRadius: theme.spacing(3),
  minWidth: 270,
  justifyContent: 'space-between',
};

export type CollectionMenuItemViewProps = {
  collection: CollectionFragmentCollectionMenuItemViewFragment;
  selectedCollectionIds?: string[];
  onClick?: (
    selectedCollection: CollectionFragmentCollectionMenuItemViewFragment,
  ) => void;
  shouldShowBreadcrumbsForRoot?: boolean;
  hideSelectIcon?: boolean;
  selectIconPosition?: 'start' | 'end';
  renderSelectIcon?: (isSelected: boolean) => ReactNode;
  componentProps?: {
    menu?: {
      sx?: SxProps;
    };
    menuItems?: {
      sx?: SxProps;
    };
    listItem?: Pick<
      CollectionListItemViewProps,
      'sx' | 'isSmartSearchResult' | 'renderName'
    >;
    autoCompleteMenu?: Pick<
      CollectionAutocompleteMenuProps,
      'noOptionsText' | 'filterOptions'
    >;
    breadcrumbs?: Pick<
      CollectionBreadcrumbsProps,
      | 'delimiter'
      | 'ellipseAtLength'
      | 'ellipse'
      | 'hideCurrentCollection'
      | 'componentProps'
    >;
  };
};

export const CollectionMenuItemView = (props: CollectionMenuItemViewProps) => {
  const {
    collection,
    selectedCollectionIds = [],
    onClick,
    shouldShowBreadcrumbsForRoot,
    selectIconPosition = 'end',
    hideSelectIcon,
    renderSelectIcon,
    componentProps,
  } = props;

  const { calendarIcon } = useIsCollectionSavedToContentCalendar({
    collection,
  });

  const { triggerCommand } = useCommandContext();

  // this is used when existing collection is selected from the collection creation dialog
  const [getCollectionById] =
    useGetCollectionForCollectionMenuItemViewLazyQuery();

  const isSelected = !!selectedCollectionIds?.includes(collection.id);

  const handleOnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClick?.(collection);
  };

  const handleRenderSelectIcon = () => {
    return hideSelectIcon ? null : renderSelectIcon ? (
      renderSelectIcon(isSelected)
    ) : isSelected ? (
      <IconBoldTickCircle size={16} />
    ) : (
      <IconLinearAddCircle size={16} />
    );
  };

  return (
    <MoreMenuItem
      disableRipple
      label={
        <CollectionListItemView
          collection={collection}
          renderName={
            shouldShowBreadcrumbsForRoot
              ? () => {
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        width: '100%',
                      }}
                    >
                      {calendarIcon}
                      <Box
                        sx={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          width: '100%',
                        }}
                      >
                        <CollectionBreadcrumbs
                          collection={collection}
                          delimiter="/"
                          ellipseAtLength={3}
                          componentProps={{
                            breadcrumbContainer: {
                              sx: {
                                gap: theme.spacing(0, 0.5),
                                flexWrap: 'nowrap',
                              },
                            },
                            text: {
                              lastLocationSx: {
                                color: theme.colors?.primary.black,
                              },
                              sx: {
                                width: '100%',
                              },
                            },
                          }}
                          sx={{
                            width: '100%',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            gap: theme.spacing(0, 1),
                          }}
                          onCollectionClick={() => {}}
                          {...componentProps?.breadcrumbs}
                        />
                      </Box>
                    </Box>
                  );
                }
              : undefined
          }
          {...componentProps?.listItem}
        />
      }
      startIcon={selectIconPosition === 'start' && handleRenderSelectIcon()}
      endIcon={selectIconPosition === 'end' && handleRenderSelectIcon()}
      onClick={handleOnClick}
      MenuProps={{
        sx: { ...componentProps?.menu?.sx },
      }}
      sx={{
        ...menuItemStyles,
        ...componentProps?.menuItems?.sx,
      }}
    >
      <Box
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <CollectionAutocompleteMenu
          {...props}
          {...props.componentProps?.autoCompleteMenu}
          filters={{
            postIds: [],
            parentCollectionId: collection.id,
          }}
          onChange={(event, value, reason, details) => {
            const { option } = details || {};
            if (
              option?.value === COLLECTION_MENU_ITEM_ADD_NEW_VALUE_KEY &&
              !option.disabled
            ) {
              triggerCommand(COMMAND_TYPE.CREATE_COLLECTION, {
                initialValues: {
                  name: option.label,
                  posts: [],
                  ...(collection ? { parentCollection: collection } : {}),
                },
                onCompleted: (collection) => {
                  onClick?.(collection);
                },
                onSelectExistingCollection: (collectionId) => {
                  if (onClick) {
                    getCollectionById({
                      variables: {
                        collectionId,
                      },
                      onCompleted: (data) => {
                        if (data.collection) {
                          onClick(data.collection);
                        }
                      },
                    });
                  }
                },
              });
            }
          }}
        />
      </Box>
    </MoreMenuItem>
  );
};
