import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Card, Grid } from '@mui/material';
import { SLASentimentClaims } from 'features/socialListeningAnalytics/components/slaSentimentClaims';
import { SentimentThemeCardWithDateRange } from 'features/socialMediaListening/views/analytics/inbound/sections/sentimentThemeCard/SentimentThemeCardWithDateRange';
import {
  BrandInboundFiltersInput,
  BrandInboundFiltersInputForSentimentMetrics,
  Sentiment,
  SentimentMedium,
  useGetBrandInboundSentimentMetricsQuery,
} from 'graphql/generated';
import { useState } from 'react';
import { theme } from 'styles/theme';
import { SLABrandInboundSentimentClaimModalView } from '../slaBrandInboundSentimentClaimModal/SLABrandInboundSentimentClaimModalView';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetBrandInboundSentimentMetrics(
    $data: BrandInboundFiltersInputForSentimentMetrics!
  ) {
    brandInboundSentimentMetrics(data: $data) {
      sentimentMetrics {
        averageCountPerDay
        chartDataPoints {
          Y
          X
        }
        totalCount
      }
      claimMetrics {
        numberOfMentions
        attribute
      }
    }
  }
`;

type Props = {
  filters: BrandInboundFiltersInput;
  medium: SentimentMedium;
};

export const SLABrandInboundSentimentsView = ({ filters, medium }: Props) => {
  const [sentiment, setSentiment] = useState(Sentiment.Positive);
  const [selectedClaim, setSelectedClaim] = useState('');

  const themeDialogDisclosure = useDisclosure();

  const generatePayload = (
    sentiment: Sentiment,
  ): BrandInboundFiltersInputForSentimentMetrics => ({
    ...filters,
    sentiment,
    medium,
  });

  const { data: positiveSentimentMetricsData } =
    useGetBrandInboundSentimentMetricsQuery({
      variables: {
        data: generatePayload(Sentiment.Positive),
      },
    });

  const { data: neutralSentimentMetricsData } =
    useGetBrandInboundSentimentMetricsQuery({
      variables: {
        data: generatePayload(Sentiment.Neutral),
      },
    });

  const { data: negativeSentimentMetricsData } =
    useGetBrandInboundSentimentMetricsQuery({
      variables: {
        data: generatePayload(Sentiment.Negative),
      },
    });

  return (
    <>
      <Card
        sx={{
          borderRadius: 5,
          p: 6,
          boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Grid container spacing={3} columns={12} mt={6}>
          <Grid item xs={4}>
            <SentimentThemeCardWithDateRange
              chartData={{
                title: 'Positive',
                subTitle:
                  'Favorable comments or discussions about your topic, brand, or product on social.',
                chartDataPoints:
                  positiveSentimentMetricsData?.brandInboundSentimentMetrics
                    ?.sentimentMetrics.chartDataPoints || [],
                averageCount:
                  positiveSentimentMetricsData?.brandInboundSentimentMetrics
                    ?.sentimentMetrics.totalCount || 0,
              }}
              sentiment={Sentiment.Positive}
              dateRange={[
                filters.dateRange.startDate,
                filters.dateRange.endDate,
              ]}
              componentProps={{
                containerSx: {
                  boxShadow: 'none',
                  border: 'none',
                  mx: 4,
                },
                mainColor: theme.colors?.utility['green-4'],
                highlightColor: 'rgba(224, 228, 202, 0.50)',
              }}
              renderTableData={() => (
                <SLASentimentClaims
                  claimMetrics={
                    positiveSentimentMetricsData?.brandInboundSentimentMetrics
                      ?.claimMetrics || []
                  }
                  onClickTheme={(theme) => {
                    setSentiment(Sentiment.Positive);
                    setSelectedClaim(theme);
                    themeDialogDisclosure.onOpen();
                  }}
                  componentProps={{
                    mainColor: theme.colors?.utility['green-4'],
                    highlightColor: 'rgba(224, 228, 202, 0.50)',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <SentimentThemeCardWithDateRange
              chartData={{
                title: 'Neutral',
                subTitle:
                  'Neither positive nor negative discussions about your topic, brand or product on social.',
                chartDataPoints:
                  neutralSentimentMetricsData?.brandInboundSentimentMetrics
                    ?.sentimentMetrics.chartDataPoints || [],
                averageCount:
                  neutralSentimentMetricsData?.brandInboundSentimentMetrics
                    ?.sentimentMetrics.totalCount || 0,
              }}
              sentiment={Sentiment.Neutral}
              dateRange={[
                filters.dateRange.startDate,
                filters.dateRange.endDate,
              ]}
              componentProps={{
                containerSx: {
                  boxShadow: 'none',
                  border: 'none',
                  mx: 4,
                },
                mainColor: theme.colors?.utility['blue-4'],
                highlightColor: 'rgba(202, 223, 228, 0.50)',
              }}
              renderTableData={() => (
                <SLASentimentClaims
                  claimMetrics={
                    neutralSentimentMetricsData?.brandInboundSentimentMetrics
                      ?.claimMetrics || []
                  }
                  onClickTheme={(theme) => {
                    setSentiment(Sentiment.Neutral);
                    setSelectedClaim(theme);
                    themeDialogDisclosure.onOpen();
                  }}
                  componentProps={{
                    mainColor: theme.colors?.utility['blue-4'],
                    highlightColor: 'rgba(202, 223, 228, 0.50)',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <SentimentThemeCardWithDateRange
              chartData={{
                title: 'Negative',
                subTitle:
                  'Unfavorable comments or discussions about your topic, brand, or product on social.',
                chartDataPoints:
                  negativeSentimentMetricsData?.brandInboundSentimentMetrics
                    ?.sentimentMetrics.chartDataPoints || [],
                averageCount:
                  negativeSentimentMetricsData?.brandInboundSentimentMetrics
                    ?.sentimentMetrics.totalCount || 0,
              }}
              sentiment={Sentiment.Negative}
              dateRange={[
                filters.dateRange.startDate,
                filters.dateRange.endDate,
              ]}
              componentProps={{
                containerSx: {
                  boxShadow: 'none',
                  border: 'none',
                  mx: 4,
                },
                mainColor: theme.colors?.utility['pink-4'],
                highlightColor: 'rgba(228, 202, 202, 0.50)',
              }}
              renderTableData={() => (
                <SLASentimentClaims
                  claimMetrics={
                    negativeSentimentMetricsData?.brandInboundSentimentMetrics
                      ?.claimMetrics || []
                  }
                  onClickTheme={(theme) => {
                    setSentiment(Sentiment.Negative);
                    setSelectedClaim(theme);
                    themeDialogDisclosure.onOpen();
                  }}
                  componentProps={{
                    mainColor: theme.colors?.utility['pink-4'],
                    highlightColor: 'rgba(228, 202, 202, 0.50)',
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Card>
      <SLABrandInboundSentimentClaimModalView
        {...themeDialogDisclosure}
        filters={filters}
        medium={medium}
        sentiment={sentiment}
        selectedAttribute={selectedClaim}
      />
    </>
  );
};
