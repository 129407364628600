import { gql } from '@apollo/client';
import { Box, Button, Dialog, MenuItem, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { DatePicker } from 'components/common/DatePicker';
import { toast } from 'components/common/Toast';
import { IconBoldTick } from 'components/icons/components/bold/IconBoldTick';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { IconLinearArrowRight } from 'components/icons/components/linear/IconLinearArrowRight';
import { useUserContext } from 'contexts/users/User.context';
import {
  ContentIdeaFragmentContentIdeaReminderModalFragment,
  useUpdateContentIdeaFieldValueForContentIdeaFieldMutation,
} from 'graphql/generated';
import moment from 'moment';
import { useMemo, useRef, useState } from 'react';
import { ToastPosition } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { theme } from 'styles/theme';

export const SESSION_STORAGE_HAS_SKIPPED_ADDING_REMINDER =
  'hasSkippedAddingReminder';

export const CONTENT_IDEA_FRAGMENT_CONTENT_IDEA_REMINDER_MODAL = gql`
  fragment ContentIdeaFragmentContentIdeaReminderModal on ContentIdeaModel {
    id
    dueDate {
      id
      value {
        id
        date
      }
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateContentIdeaFieldValueForContentIdeaReminderModal(
    $data: UpdateContentIdeaFieldValueInput!
  ) {
    updateContentIdeaFieldValue(data: $data) {
      id
      value {
        id
        date
      }
    }
  }
`;

export type ContentIdeaReminderModalProps = {
  contentIdea: ContentIdeaFragmentContentIdeaReminderModalFragment;
  open: boolean;
  onClose: () => void;
};

export const ContentIdeaReminderModal = (
  props: ContentIdeaReminderModalProps,
) => {
  const { contentIdea, open, onClose } = props;

  const navigate = useNavigate();

  const { user } = useUserContext();
  const hasConnectedPhoneNumber = Boolean(user?.phoneNumber);
  const hasSkippedAddingReminderForThisSession =
    sessionStorage.getItem(SESSION_STORAGE_HAS_SKIPPED_ADDING_REMINDER) ===
    'true';

  const [updateContentIdeaFieldValue] =
    useUpdateContentIdeaFieldValueForContentIdeaFieldMutation();

  const presetOptions = useMemo(
    () => [
      {
        label: 'An hour from now',
        value: moment().add(1, 'hours'),
      },
      {
        label: 'Evening today',
        value: moment().hour(19).second(0).minute(0),
      },
      {
        label: 'Tomorrow',
        value: moment().add(1, 'days'),
      },
    ],
    [],
  );

  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(
    presetOptions[0].value,
  );
  const hasSelectedOneOfThePresetOptions = useMemo(
    () =>
      selectedDate &&
      presetOptions.some((option) => option.value.isSame(selectedDate)),
    [presetOptions, selectedDate],
  );

  const onSelect = (date: moment.Moment) => {
    setSelectedDate(date);
  };

  const onSubmit = async () => {
    if (selectedDate) {
      await updateContentIdeaFieldValue({
        variables: {
          data: {
            contentIdeaFieldValueId: contentIdea.dueDate.id,
            data: {
              value: {
                date: selectedDate.toISOString(),
              },
              timeZone: moment.tz.guess(),
            },
          },
        },
      });

      toast({
        position: 'bottom-center' as ToastPosition,
        shouldShowCloseButton: false,
        sx: {
          bgcolor: theme.colors?.primary.black,
          color: theme.colors?.primary.white,
          borderRadius: 50,
          py: 2,
          px: 3,
          alignItems: 'center',
          '.MuiTypography-root': {
            color: theme.colors?.primary.white,
          },
          gap: 1,
        },
        icon: <IconBoldTickCircle size={20} />,
        message: 'Reminder set',
      });

      onClose();
    }
  };

  /**
   * NOTE: THIS IS A TEMPORARY FIX
   * DatePicker component is not working properly with Dialog component.
   * The date popover is not being dimissed when clicking outside (onto the dialog)
   */
  const closePickerRef = useRef<() => void>();

  const renderRegisterToTextPromptUI = () => {
    return (
      <>
        <Box component="img" src="/images/cc-notification.png" />
        <Typography variant="headline-xl" fontSize={28} mb={2}>
          Want to get reminders for when to post?
        </Typography>
        <Typography variant="subhead-xl" mb={6}>
          You'll receive a text reminder along with the final assets at the
          scheduled posting time.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
          }}
        >
          <Button
            type="button"
            variant="primary-alt"
            onClick={() => {
              navigate(PlotRoutes.registerIdeas(), {
                state: {
                  variant: 'contentCalendar',
                },
              });
            }}
            disabled={!selectedDate}
          >
            Yes, text me.
          </Button>
          <Button
            type="button"
            variant="text"
            onClick={() => {
              sessionStorage.setItem(
                SESSION_STORAGE_HAS_SKIPPED_ADDING_REMINDER,
                'true',
              );
              onClose();
            }}
          >
            Skip
          </Button>
        </Box>
      </>
    );
  };

  const renderReminderPickingUI = () => {
    return (
      <>
        <Typography variant="headline-xl" fontSize={28} mb={2}>
          When should we remind you to post?
        </Typography>
        <Typography variant="subhead-xl" mb={6}>
          You'll receive a text reminder at this time to post.
        </Typography>
        <Box display="flex" flexDirection="column" mx={-3} gap={2}>
          {presetOptions.map((option, index) => {
            const isSelected = selectedDate?.isSame(option.value);

            return (
              <MenuItem
                key={index}
                component="div"
                onClick={() => onSelect(option.value)}
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 4,
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                  <Typography variant="subhead-lg">{option.label}</Typography>
                  <Typography
                    variant="subhead-lg"
                    color={theme.colors?.utility[700]}
                  >
                    {option.value.format(`ddd, DD MMM, HH:mm A`)}
                  </Typography>
                </Box>
                {isSelected && <IconBoldTick size={16} />}
              </MenuItem>
            );
          })}
          <DatePicker
            value={selectedDate}
            onChange={(date) => {
              if (date) {
                setSelectedDate(date);
              }
            }}
            renderInput={({ inputRef, closePicker, togglePicker }) => {
              closePickerRef.current = closePicker;

              return (
                <MenuItem
                  component="div"
                  ref={inputRef}
                  onClick={(e) => {
                    e.stopPropagation();
                    togglePicker();
                  }}
                >
                  <Box
                    sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}
                  >
                    <Typography
                      variant="subhead-lg"
                      sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 4,
                      }}
                    >
                      Custom
                    </Typography>
                    {!hasSelectedOneOfThePresetOptions && selectedDate && (
                      <Typography
                        variant="subhead-lg"
                        color={theme.colors?.utility[700]}
                      >
                        {selectedDate.format(`ddd, DD MMM, HH:mm A`)}
                      </Typography>
                    )}
                  </Box>
                  <IconLinearArrowRight size={20} />
                </MenuItem>
              );
            }}
            includeTimeByDefault
            closeOnSelect={false}
            PopperProps={{
              placement: 'right',
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            mt: 6,
          }}
        >
          <Button type="button" variant="text" onClick={onClose}>
            Skip
          </Button>
          <Button
            type="button"
            variant="primary-alt"
            onClick={onSubmit}
            disabled={!selectedDate}
          >
            Confirm
          </Button>
        </Box>
      </>
    );
  };

  return (
    <Dialog
      open={open && !hasSkippedAddingReminderForThisSession}
      onClose={onClose}
      PaperProps={{
        sx: {
          p: 8,
          bgcolor: '#FAF3ECCC',
          backdropFilter: 'blur(20px)',
          maxWidth: 480,
        },
      }}
      // NOTE: Part of the ^^^ temporary fix.
      // Use onClickCapture here because onClick will override the Dialog's onClick handling
      // (which is used to close the dialog when clicking outside of it)
      onClickCapture={(e) => {
        if (e.currentTarget?.contains(e.target as Node)) {
          closePickerRef.current?.();
        }
      }}
    >
      {!hasConnectedPhoneNumber
        ? renderRegisterToTextPromptUI()
        : renderReminderPickingUI()}
    </Dialog>
  );
};
