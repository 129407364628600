import { Box, Divider, Grid, Skeleton } from '@mui/material';
import { theme } from 'styles/theme';

interface SocialMediaListeningPostDetailViewSkeletonProps {
  isModalView: boolean;
}

export const SocialMediaListeningPostDetailViewSkeleton = ({
  isModalView,
}: SocialMediaListeningPostDetailViewSkeletonProps) => {
  return (
    <Box mt={isModalView ? 0 : 16} mx="auto">
      <Box py={isModalView ? 0 : 16}>
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            md={isModalView ? 12 : 8}
            lg={isModalView ? 12 : 7}
            mx="auto"
          >
            <Box display="flex" flexDirection="column" gap={4}>
              {/* Topic detail */}
              <Skeleton
                variant="rounded"
                height={140}
                width="100%"
                sx={{
                  borderRadius: 4,
                }}
              />

              <Box
                sx={{
                  backgroundColor: 'rgba(246, 229, 216, 0.25)',
                  borderRadius: 4,
                  p: 9,
                  display: 'flex',
                  gap: 4,
                  flexDirection: 'column',
                }}
              >
                <Box width="100%" display="flex" justifyContent="space-between">
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <Skeleton variant="text" height={20} width={130} />
                    <Skeleton variant="circular" height={16} width={16} />
                  </Box>

                  <Box display="flex" gap={4} alignItems="center">
                    <Skeleton variant="rounded" height={26} width={107} />
                    <Skeleton variant="rounded" height={26} width={107} />
                  </Box>
                </Box>
                <Box display="flex" gap={4}>
                  <Box flex={2} sx={{ overflow: 'hidden' }}>
                    <Skeleton
                      variant="rounded"
                      height={550}
                      width="100%"
                      sx={{
                        borderRadius: 4,
                      }}
                    />
                  </Box>
                  <Box flex={3} height={600} sx={{ overflow: 'auto' }}>
                    <Box display="flex" flexDirection="column" gap={4}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        <Skeleton variant="text" height={20} width={50} />
                        <Skeleton variant="rounded" height={16} width={16} />
                      </Box>

                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 6 }}
                      >
                        <Skeleton variant="text" height={17} width={100} />
                        <Skeleton variant="text" height={17} width={100} />
                        <Skeleton variant="text" height={17} width={100} />
                        <Skeleton variant="text" height={17} width={150} />
                      </Box>
                      <Divider
                        sx={{ borderColor: theme.colors?.utility[300] }}
                      />

                      <Box
                        sx={{
                          borderRadius: 4,
                          background: 'rgba(240, 183, 134, 0.15)',
                          padding: theme.spacing(4, 6),
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 4,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 4,
                            }}
                          >
                            <Skeleton
                              variant="circular"
                              height={44}
                              width={44}
                            />
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <Skeleton
                                variant="text"
                                height={23}
                                width={150}
                              />
                              <Skeleton
                                variant="text"
                                height={18}
                                width={200}
                              />
                            </Box>
                          </Box>

                          <Skeleton variant="rounded" height={34} width={104} />
                        </Box>
                        <Box>
                          {Array.from({ length: 3 }).map((_, index) => (
                            <Skeleton
                              variant="text"
                              height={16}
                              width={index <= 1 ? '100%' : '50%'}
                              key={index}
                            />
                          ))}
                        </Box>

                        <Box display="flex" gap={3}>
                          {Array.from({ length: 4 }).map((_, index) => (
                            <Skeleton
                              variant="rounded"
                              height={18}
                              width={40}
                              key={index}
                            />
                          ))}
                        </Box>
                      </Box>

                      <Divider
                        sx={{
                          borderColor: theme.colors?.utility[400],
                        }}
                      />
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 3,
                        }}
                      >
                        <Skeleton variant="text" height={18} width={111} />
                        <Skeleton variant="rounded" height={26} width={197} />
                      </Box>

                      <Divider
                        sx={{
                          borderColor: theme.colors?.utility[400],
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
