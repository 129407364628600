import { Box, IconButton, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { ContextMenuProps } from 'components/common/ContextMenu/types';
import { IconLinearMore } from 'components/icons/components/linear/IconLinearMore';
import { IconOutlineTrash } from 'components/icons/components/outline/IconOutlineTrash';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { theme } from 'styles/theme';

type SLAProductContextMenuProps = Omit<
  ContextMenuProps,
  'options' | 'renderButton'
> &
  Partial<Pick<ContextMenuProps, 'renderButton'>> & {
    options?: {
      delete?: boolean | (() => void);
    };
    onAfterDelete?: () => void;
  };

export const SLAProductContextMenu = (props: SLAProductContextMenuProps) => {
  const {
    options = {
      delete: true,
    },
    onAfterDelete,
    ...rest
  } = props;

  // TODO: update this with real data
  const canDelete = true;

  const {
    dialog: deleteProductDialog,
    onOpen: openDeleteProductConfirmationDialog,
  } = useConfirmationDialog();

  const menuOptions = [
    ...(options?.delete === false
      ? []
      : [
          {
            title: 'Remove',
            icon: IconOutlineTrash,
            color: theme.colors?.utility['pink-3'],
            onClick: () =>
              openDeleteProductConfirmationDialog({
                title: (
                  <Typography
                    variant="headline-lg"
                    color={theme.colors?.primary.black}
                  >
                    Delete product?
                  </Typography>
                ),
                subtitle: (
                  <Box
                    sx={{
                      display: 'grid',
                      gap: 3,
                    }}
                  >
                    <Typography
                      variant="subhead-xl"
                      color={theme.colors?.primary.black}
                    >
                      Deleting this will permanently remove the product.
                    </Typography>
                    <Typography
                      variant="subhead-xl"
                      color={theme.colors?.primary.black}
                    >
                      Do you want to continue?
                    </Typography>
                  </Box>
                ),
                confirmText: 'Yes, continue to delete.',
                onConfirm: () => {
                  // TODO: integrate delete mutation
                  onAfterDelete?.();
                },
              }),
            disabled: !canDelete,
            closeOnClick: false,
          },
        ]),
  ];

  return (
    <>
      <ContextMenu
        onClick={(e) => {
          e.stopPropagation();
        }}
        renderButton={
          rest.renderButton === false
            ? false
            : rest.renderButton
            ? rest.renderButton
            : () => (
                <IconButton>
                  <IconLinearMore
                    style={{
                      color: theme.colors?.primary.black,
                      transform: `rotate(90deg)`,
                    }}
                  />
                </IconButton>
              )
        }
        options={menuOptions.map((option) => {
          const Icon = option.icon;
          return {
            renderOption: () => (
              <Box display="flex" gap={2} alignItems="center">
                <Box
                  sx={{
                    padding: 1,
                    borderRadius: 1,
                    background: 'rgba(35, 6, 3, 0.05)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {Icon && <Icon size={16} color={option.color} />}
                </Box>
                <Typography variant="headline-xs" color={option.color}>
                  {option.title}
                </Typography>
              </Box>
            ),
            onClick: option.onClick,
            disabled: option.disabled,
            closeOnClick: option.closeOnClick,
          };
        })}
        MenuListProps={{
          sx: {
            width: 266,
            gap: `${theme.spacing(2)} !important`,
          },
        }}
        disableRestoreFocus
        PaperProps={{
          style: {
            background: `rgba(255, 255, 255, 0.80)`,
            backdropFilter: `blur(20px)`,
            border: 'none',
          },
        }}
        {...rest}
      />
      {deleteProductDialog}
    </>
  );
};
