import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { typography } from 'components/common/Typography/styles';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { CreatorAvatarWithNameView } from 'features/creator';
import {
  CreatorTrackingList,
  SocialMediaListeningCreatorProfile,
  useSLBrandPermissions,
} from 'features/socialMediaListening';
import {
  CreatorFragmentCreatorAvatarWithNameViewFragmentDoc,
  CreatorFragmentSlaTopCreatorsFragment,
  CreatorProfileFragmentSocialMediaListeningCreatorProfileFragmentDoc,
} from 'graphql/generated';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment CreatorFragmentSLATopCreators on CreatorModel {
    id
    numberOfBrandMentions
    followerCount
    ...CreatorFragmentCreatorAvatarWithNameView
    ...CreatorProfileFragmentSocialMediaListeningCreatorProfile
  }
  ${CreatorFragmentCreatorAvatarWithNameViewFragmentDoc}
  ${CreatorProfileFragmentSocialMediaListeningCreatorProfileFragmentDoc}
`;

type CreatorScoreType = [CreatorFragmentSlaTopCreatorsFragment, string];

type Props = {
  creatorsData: CreatorScoreType[];
  onCreatorIdSelect: (creatorId: string) => void;
  brandId: string;
  renderFilter: () => React.ReactNode;
};

export const SLATopCreators = ({
  creatorsData,
  onCreatorIdSelect,
  brandId,
  renderFilter,
}: Props) => {
  const { hasFullAccess } = useSLBrandPermissions({ brandId });
  //   const [sortKey, setSortKey] = useState(SortKey.NumberOfBrandMentions);

  return (
    <Box
      display="flex"
      width="100%"
      gap={4}
      flexDirection="column"
      p={8}
      sx={{
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Typography
          variant="headline-sm"
          color={theme.colors?.utility[700]}
          pb={1}
          display="flex"
          alignItems="center"
          gap={2}
        >
          Top creators
          <Tooltip title="Top creators are ranked by the number of times they have mentioned the brand ">
            <Box display="flex" alignItems="center">
              <IconBoldInfoCircle size={16} />
            </Box>
          </Tooltip>
        </Typography>

        {renderFilter()}
      </Box>

      {!creatorsData.length && (
        <Typography
          variant="body-lg"
          color={theme.colors?.utility[700]}
          minHeight={100}
          width="100%"
          display="flex"
          justifyContent="center"
          mt={4}
        >
          No creators found
        </Typography>
      )}

      {!!creatorsData.length && (
        <Box
          display="grid"
          gridTemplateColumns="repeat(4, 1fr)"
          gap={2}
          width="100%"
        >
          {creatorsData.map((item, colIndex) => (
            <SocialMediaListeningCreatorProfile
              creator={item[0]}
              componentProps={{
                showDetailsOnHover: true,
                renderCreatorTrackingButton: () =>
                  hasFullAccess && (
                    <CreatorTrackingList brandId={brandId} creator={item[0]} />
                  ),
              }}
              renderCustomCreatorProfile={() => (
                <Box
                  onClick={() => onCreatorIdSelect(item[0].id)}
                  key={colIndex}
                  display="flex"
                  flex="1 1 100%"
                  boxSizing="border-box"
                  p={1}
                  width="100%"
                  maxWidth="100%"
                  overflow="hidden"
                >
                  <Box
                    pr={4}
                    py={2}
                    pl={0}
                    display="flex"
                    gap={3}
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <CreatorAvatarWithNameView
                      creator={item[0]}
                      sx={{
                        p: 1,
                      }}
                      componentsProps={{
                        avatar: {
                          sx: {
                            width: theme.spacing(11),
                            height: theme.spacing(11),
                          },
                        },
                        nickName: { sx: { ...typography['headline-md'] } },
                        handle: { sx: { ...typography['body-lg'] } },
                      }}
                    />
                    <Typography variant="headline-sm">{item[1]}</Typography>
                  </Box>
                </Box>
              )}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};
