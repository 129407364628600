import { gql } from '@apollo/client';
import { SxProps } from '@mui/material';
import { RightClickContextMenuWrapper } from 'components/common/RightClickContextMenuWrapper';
import {
  CollectionContextMenu,
  CollectionMultiPostPreviewProps,
} from 'features/collection/components';
import { CUSTOM_COLLECTION } from 'features/collection/constants';
import { useMoveToAnotherCollectionModal } from 'features/collection/hooks/useMoveToAnotherCollectionModal';
import { useCollectionPermissionDialogView } from 'features/collectionPermission';
import { JuiceboxTableNormalCollectionTableView } from 'features/juiceboxTable/views';
import { JuiceboxTableCustomCollectionTableView } from 'features/juiceboxTable/views/customCollectionTable';
import { usePinnedEntityMutations } from 'features/pinnedEntity';
import {
  CollectionFragmentCollectionContextMenuFragmentDoc,
  CollectionFragmentJuiceboxTableCollectionTableRowFragmentDoc,
  CollectionFragmentJuiceboxTableCollectionTableRowWithContextMenuFragment,
} from 'graphql/generated';
import { useEffect, useRef, useState } from 'react';
import { LinkProps } from 'react-router-dom';
import { theme } from 'styles/theme';
import { JuiceboxTableCollectionTableRow } from './JuiceboxTableCollectionTableRow';

export const COLLECTION_FRAGMENT_JUICEBOX_TABLE_COLLECTION_TABLE_ROW_WITH_CONTEXT_MENU = gql`
  fragment CollectionFragmentJuiceboxTableCollectionTableRowWithContextMenu on CollectionModel {
    id
    ...CollectionFragmentJuiceboxTableCollectionTableRow
    ...CollectionFragmentCollectionContextMenu
  }
  ${CollectionFragmentJuiceboxTableCollectionTableRowFragmentDoc}
  ${CollectionFragmentCollectionContextMenuFragmentDoc}
`;

export type JuiceboxTableCollectionTableRowWithContextMenuProps = {
  collection: CollectionFragmentJuiceboxTableCollectionTableRowWithContextMenuFragment;
  context?: 'parent' | 'root';
  sx?: SxProps;
  allowMultiSelect?: boolean;
  componentsProps?: {
    disableContextMenu?: boolean;
    multiPostPreview?: Pick<CollectionMultiPostPreviewProps, 'variant'>;
    link?: Partial<LinkProps> | false;
    permission?: false;
  };
  nestedLevel?: number;
  onClick?: () => void;
};

export const JuiceboxTableCollectionTableRowWithContextMenu = (
  props: JuiceboxTableCollectionTableRowWithContextMenuProps,
) => {
  const {
    collection,
    context = 'parent',
    sx,
    nestedLevel = 0,
    componentsProps = {},
    onClick,
    allowMultiSelect,
  } = props;

  const [isCustomCollection, setIsCustomCollection] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (
      Object.values(CUSTOM_COLLECTION).includes(
        collection.id as CUSTOM_COLLECTION,
      )
    ) {
      setIsCustomCollection(true);
    }
  }, [collection]);

  const nameRef = useRef<HTMLTextAreaElement | null>(null);
  const [isRenaming, setIsRenaming] = useState(false);

  const {
    openDialog: showCollectionPermissionsDialog,
    renderContent: renderCollectionPermissionsDialog,
  } = useCollectionPermissionDialogView({
    collectionId: collection.id,
  });

  const { renderMoveToAnotherCollectionModal, showMoveCollection } =
    useMoveToAnotherCollectionModal({
      currentCollectionId: collection.id,
      collectionIds: [collection.id],
    });

  const {
    onPinManyCollectionsToParent,
    onUnpinManyCollectionsFromParent,
    onPinCollectionToRoot,
    renderCollectionPinnedToRootFeedback,
    onUnpinCollectionFromRoot,
  } = usePinnedEntityMutations();

  return (
    <>
      <RightClickContextMenuWrapper
        disabled={componentsProps.disableContextMenu}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flex: 1,
          pl: theme.spacing(nestedLevel * 6),
          borderRadius: 3,
          width: '100%',
          opacity: collection.isDisabled ? 0.8 : 1,
          borderBottom: '1px solid rgba(230, 221, 237, 0.7)',
          '& td:first-child': {
            borderTopLeftRadius: theme.spacing(3),
            borderBottomLeftRadius: theme.spacing(3),
          },
          '& td:last-child': {
            borderTopRightRadius: theme.spacing(3),
            borderBottomRightRadius: theme.spacing(3),
          },
          '&:hover': {
            bgcolor: 'rgba(230, 221, 237, 0.7)',
            cursor: 'pointer',
            ...(!isCustomCollection && {
              ...(allowMultiSelect && {
                '.collection-table-row-cell-select-icon': {
                  opacity: 1,
                  pointerEvents: 'auto',
                },
              }),
            }),
          },
          ...sx,
        }}
        renderMenu={(props) => (
          <CollectionContextMenu
            key={`${collection.id}${collection.previewPost?.id || ''}`}
            collection={collection}
            renderButton={false}
            onRenameCollection={() => {
              setIsRenaming(true);
              setTimeout(() => {
                nameRef.current?.focus();
                nameRef.current?.select();
              });
            }}
            onMoveCollection={showMoveCollection}
            onPinCollectionToParent={(cId) =>
              onPinManyCollectionsToParent([cId])
            }
            onUnpinCollectionFromParent={(cid) =>
              onUnpinManyCollectionsFromParent([cid])
            }
            onPinCollectionToRoot={(collectionId) => {
              onPinCollectionToRoot(collectionId, context);
            }}
            onUnpinCollectionFromRoot={onUnpinCollectionFromRoot}
            onShareCollection={() => showCollectionPermissionsDialog()}
            {...(context === 'root' ? { hideOptions: ['pinToParent'] } : {})}
            {...props}
          />
        )}
        onClick={onClick}
      >
        <JuiceboxTableCollectionTableRow
          key={`${collection.id}`}
          collection={collection}
          expanded={isExpanded}
          onToggleExpand={setIsExpanded}
          isRenaming={isRenaming}
          setIsRenaming={setIsRenaming}
        />
      </RightClickContextMenuWrapper>
      {isExpanded &&
        (isCustomCollection ? (
          <JuiceboxTableCustomCollectionTableView
            key={`${collection.id}`}
            collectionId={collection.id}
            nestedLevel={nestedLevel}
          />
        ) : (
          <JuiceboxTableNormalCollectionTableView
            key={`${collection.id}`}
            collectionId={collection.id}
            nestedLevel={nestedLevel}
          />
        ))}

      {renderMoveToAnotherCollectionModal()}
      {renderCollectionPermissionsDialog()}
      {renderCollectionPinnedToRootFeedback(collection)}
    </>
  );
};
