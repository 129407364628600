import { gql } from '@apollo/client';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { PlotRoutes } from 'Routes';
import { ContextMenu } from 'components/common/ContextMenu';
import { Tooltip } from 'components/common/Tooltip';
import { typography } from 'components/common/Typography/styles';
import { IconBoldAddCircle } from 'components/icons/components/bold/IconBoldAddCircle';
import { IconBoldPauseCircle } from 'components/icons/components/bold/IconBoldPauseCircle';
import { IconBoldPlayCircle } from 'components/icons/components/bold/IconBoldPlayCircle';
import { IconBoldTrash } from 'components/icons/components/bold/IconBoldTrash';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import {
  SocialMediaListeningKeywords,
  SocialMediaListeningManageCreatorsView,
  WordLimitedInputArea,
  useBrandDataForSocialListeningOnboarding,
  useBrandSearchTermsForSocialListeningOnboarding,
  useTopicDataForSocialListeningOnboarding,
} from 'features/socialMediaListening';
import {
  TopicContentFilterSettingsView,
  TopicLabel,
  TopicParsingSettingsView,
  TopicSignal,
} from 'features/topic';
import {
  GetTopicSignalDefinitionsForSocialListeningSettingsTopicsViewDocument,
  GetTopicSignalDefinitionsForSocialListeningSettingsTopicsViewQuery,
  ListeningTopicType,
  Platform,
  PlotFeature,
  SignalDefinitionFragmentTopicSignalFragmentDoc,
  TopicStatus,
  useCreateSignalDefinitionForSocialListeningSettingsTopicsViewMutation,
  useGetTopicSignalDefinitionsForSocialListeningSettingsTopicsViewQuery,
} from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';
import { SettingsSentimentSubjectsView } from '../settingsSentimentSubjects';

// eslint-disable-next-line
gql`
  query GetTopicSignalDefinitionsForSocialListeningSettingsTopicsView(
    $topicId: String!
  ) {
    topicSignalDefinitions(topicId: $topicId) {
      ...SignalDefinitionFragmentTopicSignal
    }
  }
  ${SignalDefinitionFragmentTopicSignalFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation CreateSignalDefinitionForSocialListeningSettingsTopicsView(
    $data: CreateSignalDefinitionInput!
  ) {
    createSignalDefinition(data: $data) {
      ...SignalDefinitionFragmentTopicSignal
    }
  }
  ${SignalDefinitionFragmentTopicSignalFragmentDoc}
`;

type Props = {
  currentBrandId: string;
  currentTopicId?: string;
  readOnly?: boolean;
};

export const SocialListeningSettingsTopicsView = ({
  currentBrandId,
  currentTopicId,
  readOnly,
}: Props) => {
  const {
    dialog: deleteTopicConfirmation,
    onOpen: openDeleteTopicConfirmationDialog,
  } = useConfirmationDialog();

  const { isFeatureEnabled } = useFeatureFlagContext();
  const creatorTrackingEnabled = isFeatureEnabled(PlotFeature.CreatorTracking);
  const isProjectInboundEnabled = isFeatureEnabled(PlotFeature.ProjectInbound);
  const [currentTopic, setCurrentTopic] = useState<any>(null);
  const [updatedTopicName, setUpdatedTopicName] = useState('');

  const [aboutFieldValue, setAboutFieldValue] = useState('');
  const [prioritizationInfo, setPrioritizationInfo] = useState('');
  const [communicationStrategy, setCommunicationStrategy] = useState('');

  const [showMoreSignalDefinitions, setShowMoreSignalDefinitions] =
    useState(false);

  const [createSignalDefinition] =
    useCreateSignalDefinitionForSocialListeningSettingsTopicsViewMutation();

  const { data } =
    useGetTopicSignalDefinitionsForSocialListeningSettingsTopicsViewQuery({
      variables: { topicId: currentTopic?.id ?? '' },
      skip: !currentTopic,
    });
  const signalDefinitions = data?.topicSignalDefinitions || [];

  const {
    brand,
    refetchBrand,
    onboardingActions: { handleBrandDescriptionUpdate },
  } = useBrandDataForSocialListeningOnboarding({ brandId: currentBrandId });
  const {
    topicActions: {
      handleAddBrandMentionedTopic,
      handleAddEngagementTopic,
      handleAddCreatorTrackingTopic,
      handleDeleteTopic,
      handlePauseTopic,
      handleResumeTopic,
      updatingTopicStatus,
    },
  } = useTopicDataForSocialListeningOnboarding({ topics: brand?.topics || [] });
  const {
    searchTermActions: { handleCreateSearchTerms },
  } = useBrandSearchTermsForSocialListeningOnboarding();

  const tiktokHandle = brand?.creators.find(
    (c) => c.platform === Platform.Tiktok,
  )?.handle;
  const instagramHandle = brand?.creators.find(
    (c) => c.platform === Platform.Instagram,
  )?.handle;
  const youtubeHandle = brand?.creators.find(
    (c) => c.platform === Platform.Youtube,
  )?.handle;

  const topics = useMemo(
    () =>
      brand?.topics.length
        ? [
            ...(brand?.topics || []).filter(
              (t) => t.type === ListeningTopicType.BrandMention,
            ),
            ...(brand?.topics || []).filter(
              (t) => t.type === ListeningTopicType.TopicEngagement,
            ),
            ...(brand?.topics || []).filter(
              (t) => t.type === ListeningTopicType.CreatorTracking,
            ),
          ]
        : [],
    [brand],
  );

  useEffect(() => {
    if (topics.length > 0) {
      setCurrentTopic(topics[0]);
    }
  }, [topics]);

  useEffect(() => {
    if (!currentTopic) {
      setCurrentTopic(topics[0]);
    } else {
      const topic = topics.find((topic) => topic.id === currentTopic.id);
      if (topic) {
        setCurrentTopic(topic);
      }
    }
  }, [brand]); // eslint-disable-line

  useEffect(() => {
    if (currentTopicId) {
      const topic = topics.find((topic) => topic.id === currentTopicId);
      if (topic) {
        setCurrentTopic(topic);
      }
    }
  }, [currentTopicId, currentBrandId, topics]);

  const aboutValue =
    currentTopic?.type === ListeningTopicType.BrandMention
      ? brand?.description
      : currentTopic?.type === ListeningTopicType.TopicEngagement
      ? currentTopic?.topicDescription
      : currentTopic?.type === ListeningTopicType.CreatorTracking
      ? currentTopic?.topicDescription
      : '';

  useEffect(() => {
    setAboutFieldValue(aboutValue || '');
    if (prioritizationInfo !== currentTopic?.prioritizationInfo) {
      setPrioritizationInfo(currentTopic?.prioritizationInfo || '');
    }
    if (communicationStrategy !== currentTopic?.communicationStrategy) {
      setCommunicationStrategy(currentTopic?.communicationStrategy || '');
    }

    setUpdatedTopicName(currentTopic?.name || '');
  }, [currentTopic, brand]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateAboutValue = async (value: string) => {
    if (currentTopic.type === ListeningTopicType.BrandMention) {
      await handleBrandDescriptionUpdate(value);
    } else if (currentTopic.type === ListeningTopicType.TopicEngagement) {
      if (!brand?.id) {
        return;
      }
      await handleAddEngagementTopic(
        {
          topicDescription: value,
          communicationStrategy:
            currentTopic.communicationStrategy || undefined,
          prioritizationInfo: currentTopic.prioritizationInfo || undefined,
        },
        brand.id,
        currentTopic.id,
      );
    } else if (currentTopic.type === ListeningTopicType.CreatorTracking) {
      if (!brand?.id) {
        return;
      }
      await handleAddCreatorTrackingTopic(
        {
          topicDescription: value,
          communicationStrategy:
            currentTopic.communicationStrategy || undefined,
          prioritizationInfo: currentTopic.prioritizationInfo || undefined,
        },
        brand.id,
        currentTopic.id,
      );
    }
  };

  const handleUpdateCommunicationStrategy = async (value: string) => {
    if (!brand?.id) {
      return;
    }

    if (currentTopic.type === ListeningTopicType.BrandMention) {
      handleAddBrandMentionedTopic(brand.id, brand?.name, {
        communicationStrategy: value,
        prioritizationInfo: currentTopic.prioritizationInfo || '',
      });
    } else if (currentTopic.type === ListeningTopicType.TopicEngagement) {
      handleAddEngagementTopic(
        {
          topicDescription: currentTopic.topicDescription || '',
          communicationStrategy: value,
          prioritizationInfo: currentTopic.prioritizationInfo || '',
        },
        brand.id,
        currentTopic.id,
      );
    } else if (currentTopic.type === ListeningTopicType.CreatorTracking) {
      handleAddCreatorTrackingTopic(
        {
          topicDescription: currentTopic.topicDescription || '',
          communicationStrategy: value,
          prioritizationInfo: currentTopic.prioritizationInfo || '',
        },
        brand.id,
        currentTopic.id,
      );
    }
  };

  const handleUpdatePrioritizationInfo = async (value: string) => {
    if (!brand?.id) {
      return;
    }

    if (currentTopic.type === ListeningTopicType.BrandMention) {
      handleAddBrandMentionedTopic(brand.id, brand?.name, {
        communicationStrategy: currentTopic.communicationStrategy || '',
        prioritizationInfo: value,
      });
    } else if (currentTopic.type === ListeningTopicType.TopicEngagement) {
      handleAddEngagementTopic(
        {
          topicDescription: currentTopic.topicDescription || '',
          communicationStrategy: currentTopic.communicationStrategy || '',
          prioritizationInfo: value,
        },
        brand.id,
        currentTopic.id,
      );
    } else if (currentTopic.type === ListeningTopicType.CreatorTracking) {
      handleAddCreatorTrackingTopic(
        {
          topicDescription: currentTopic.topicDescription || '',
          communicationStrategy: currentTopic.communicationStrategy || '',
          prioritizationInfo: value,
        },
        brand.id,
        currentTopic.id,
      );
    }
  };

  const onDeleteTopic = async () => {
    openDeleteTopicConfirmationDialog({
      onConfirm: async () => {
        await handleDeleteTopic(currentTopic.id);
        await refetchBrand();
        setCurrentTopic(topics[0]);
      },
      title: 'Are you sure?',
      subtitle: 'Deleting this topic is a permanent action?',
      confirmText: 'Delete topic',
      cancelText: 'Cancel',
    });
  };

  const onTopicNameUpdated = async (name: string) => {
    if (
      currentTopic.type === ListeningTopicType.TopicEngagement ||
      currentTopic.type === ListeningTopicType.CreatorTracking
    ) {
      handleAddEngagementTopic(
        {
          topicName: name,
        },
        brand?.id || '',
        currentTopic.id,
      );
    } else if (currentTopic.type === ListeningTopicType.BrandMention) {
      handleAddBrandMentionedTopic(brand?.id || '', brand?.name || '', {
        name,
      });
    }
  };

  const onCreateSignalDefinition = () => {
    createSignalDefinition({
      variables: {
        data: {
          data: {
            topicId: currentTopic.id,
          },
        },
      },
      update: (cache, { data }) => {
        const newSignalDefinition = data?.createSignalDefinition;
        if (newSignalDefinition) {
          cache.updateQuery(
            {
              query:
                GetTopicSignalDefinitionsForSocialListeningSettingsTopicsViewDocument,
              variables: {
                topicId: currentTopic.id,
              },
            },
            (
              data: GetTopicSignalDefinitionsForSocialListeningSettingsTopicsViewQuery | null,
            ) => {
              if (!data) return null;

              return {
                ...data,
                topicSignalDefinitions: [
                  newSignalDefinition,
                  ...data.topicSignalDefinitions,
                ],
              };
            },
          );
        }
      },
    });
  };

  const isCurrentTopicActive = currentTopic?.status === TopicStatus.Active;

  const MAX_VISIBLE_SIGNAL_DEFINITION_COUNT = 2;
  const hiddenSignalDefinitionCount = Math.max(
    0,
    signalDefinitions.length - MAX_VISIBLE_SIGNAL_DEFINITION_COUNT,
  );

  return (
    <Box display="flex" flexDirection="column" gap={6}>
      <Typography variant="headline-md" fontSize={theme.spacing(5)}>
        Topics
      </Typography>

      <Box display="flex" justifyContent="space-between" flex={1}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <ContextMenu
            PaperProps={{
              sx: {
                background: 'rgba(255, 255, 255, 0.80)',
                backdropFilter: 'blur(20px)',
                border: 'none',
                boxShadow:
                  '0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12) !important',
                maxHeight: 450,
              },
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            renderButton={() => (
              <Box
                display="flex"
                alignItems="center"
                gap={1}
                sx={{
                  cursor: 'pointer',
                  border: `2px solid ${theme.colors?.utility[400]}`,
                  backgroundColor: theme.colors?.utility[300],
                  borderRadius: theme.spacing(2),
                  padding: theme.spacing(2, 4),
                }}
              >
                <Typography
                  variant="body-xl"
                  color={theme.colors?.primary.black}
                  sx={{
                    fontWeight: 600,
                  }}
                  onClick={() => {
                    setUpdatedTopicName(currentTopic?.name || '');
                  }}
                >
                  {currentTopic?.name}
                </Typography>
                {currentTopic && (
                  <TopicLabel
                    topic={currentTopic}
                    sx={{
                      fontSize: 12,
                    }}
                  />
                )}
                <IconLinearArrowDown size={16} style={{ marginLeft: 4 }} />
              </Box>
            )}
            options={topics.map((topic) => ({
              renderOption: () => (
                <Box
                  sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                  }}
                  onClick={() => {
                    setCurrentTopic(topic);
                  }}
                >
                  <Typography
                    variant="subhead-lg"
                    color={theme.colors?.primary.black}
                  >
                    {topic.name}
                  </Typography>
                  <TopicLabel
                    topic={topic}
                    sx={{
                      fontSize: 12,
                      width: 'fit-content',
                    }}
                  />
                </Box>
              ),
              onClick: () => {},
            }))}
          />
        </Box>
        {!readOnly && (
          <ContextMenu
            renderButton={() => (
              <Button
                variant="outlined"
                endIcon={<IconBoldAddCircle size={16} />}
                sx={{
                  '&, &:hover': {
                    borderRadius: 10,
                    borderWidth: 2,
                    borderColor: theme.colors?.primary.black,
                    backgroundColor: theme.colors?.primary.black,
                    color: theme.colors?.primary.parchment,
                  },
                }}
              >
                <Typography variant="headline-sm">New topic</Typography>
              </Button>
            )}
            options={[
              {
                renderOption: () => (
                  <Link
                    to={PlotRoutes.socialListeningOnboarding(
                      'topic',
                      currentBrandId,
                    )}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        padding: theme.spacing(1),
                        borderRadius: theme.spacing(3),
                        '&:hover': {
                          backgroundColor: theme.colors?.utility[275],
                        },
                      }}
                    >
                      <Typography variant="body-lg" fontWeight={500}>
                        Topic Insights
                      </Typography>
                      <Typography
                        variant="body-sm"
                        fontWeight={500}
                        color={theme.colors?.utility[900]}
                      >
                        Track conversations across customized topics
                      </Typography>
                    </Box>
                  </Link>
                ),
              },
              ...(creatorTrackingEnabled
                ? [
                    {
                      renderOption: () => (
                        <Link
                          to={PlotRoutes.socialListeningOnboarding(
                            'creator',
                            currentBrandId,
                          )}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 1,
                              padding: theme.spacing(1),
                              borderRadius: theme.spacing(3),
                              '&:hover': {
                                backgroundColor: theme.colors?.utility[275],
                              },
                            }}
                          >
                            <Typography variant="body-lg" fontWeight={500}>
                              Creator Focus
                            </Typography>
                            <Typography
                              variant="body-sm"
                              fontWeight={500}
                              color={theme.colors?.utility[900]}
                            >
                              Track specific creators discussing your key topics
                            </Typography>
                          </Box>
                        </Link>
                      ),
                    },
                  ]
                : []),
            ]}
          />
        )}
      </Box>

      <TextField
        disabled={readOnly}
        sx={{
          width: theme.spacing(100),
          mt: 4,
          '.MuiOutlinedInput-root': {
            py: `${theme.spacing(4)} !important`,
            px: `${theme.spacing(4)} !important`,
            bgcolor: 'rgba(255, 255, 255, 0.10)',
            borderRadius: 3,
            border: `2px solid ${theme.colors?.utility[300]}`,
            background: theme.colors?.utility[200],

            input: {
              p: '0 !important',
              ...theme.typography['body-lg'],
            },

            '.MuiOutlinedInput-notchedOutline': {
              display: 'none !important',
            },
          },
        }}
        value={updatedTopicName}
        onChange={(e) => {
          setUpdatedTopicName(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onTopicNameUpdated(updatedTopicName);
          }
        }}
        onBlur={() => {
          onTopicNameUpdated(updatedTopicName);
        }}
      />

      <Box display="flex" gap={3}>
        <Box display="flex" flexDirection="column" gap={3} flex={3}>
          <Box display="flex" flexDirection="column" mt={2} gap={3}>
            <Typography variant="headline-md">
              About{' '}
              {currentTopic?.type === ListeningTopicType.BrandMention
                ? 'the brand'
                : currentTopic?.type === ListeningTopicType.TopicEngagement ||
                  currentTopic?.type === ListeningTopicType.CreatorTracking
                ? 'the topic'
                : ''}
            </Typography>
            <Typography variant="body-xl" color={theme.colors?.utility[800]}>
              Fill us in about your company. For example, what do you sell? Who
              is your target audience?
            </Typography>

            <WordLimitedInputArea
              disabled={readOnly}
              autoFocus={false}
              value={aboutFieldValue}
              onChange={(e) => {
                setAboutFieldValue(e.target.value);
              }}
              maxWords={100}
              componentProps={{
                wordUsage: {
                  sx: {
                    position: 'absolute',
                    left: theme.spacing(4),
                    bottom: theme.spacing(4),
                    color: theme.colors?.utility[700],
                  },
                },
              }}
              multiline
              minRows={7}
              maxRows={12}
              sx={{
                mt: 4,
                '.MuiOutlinedInput-root': {
                  py: `${theme.spacing(4)} !important`,
                  px: `${theme.spacing(4)} !important`,
                  bgcolor: 'rgba(255, 255, 255, 0.10)',
                  borderRadius: 3,
                  border: `2px solid ${theme.colors?.utility[300]}`,
                  background: theme.colors?.utility[200],

                  input: {
                    p: '0 !important',
                    ...theme.typography['body-xl'],
                  },

                  '.MuiOutlinedInput-notchedOutline': {
                    display: 'none !important',
                  },
                },
              }}
            >
              {aboutValue !== aboutFieldValue && (
                <Button
                  fullWidth={false}
                  variant="primary"
                  onClick={() => {
                    updateAboutValue(aboutFieldValue);
                  }}
                  sx={{
                    position: 'absolute',
                    right: theme.spacing(4),
                    bottom: theme.spacing(6),
                    mt: 4,
                    p: theme.spacing(2, 4),
                    borderRadius: theme.spacing(5),
                    backgroundColor: theme.colors?.primary.black,
                    border: 'none',
                    '&:hover': {
                      backgroundColor: theme.colors?.primary.black,
                    },
                  }}
                >
                  Save changes
                </Button>
              )}
            </WordLimitedInputArea>
          </Box>
          <Box display="flex" flexDirection="column" mt={2} gap={3}>
            <Typography variant="headline-md">
              Prioritize incoming posts
            </Typography>
            <Typography variant="body-xl" color={theme.colors?.utility[800]}>
              We sift through millions of videos to prioritize content based on
              your criteria, such as brand mentions or follower count. You
              define it, and we ensure the most important videos appear first.
            </Typography>

            <WordLimitedInputArea
              disabled={readOnly}
              autoFocus={false}
              value={prioritizationInfo}
              onChange={(e) => {
                setPrioritizationInfo(e.target.value);
              }}
              maxWords={100}
              componentProps={{
                wordUsage: {
                  sx: {
                    position: 'absolute',
                    left: theme.spacing(4),
                    bottom: theme.spacing(4),
                    color: theme.colors?.utility[700],
                  },
                },
              }}
              multiline
              minRows={7}
              maxRows={12}
              sx={{
                mt: 4,
                '.MuiOutlinedInput-root': {
                  py: `${theme.spacing(4)} !important`,
                  px: `${theme.spacing(4)} !important`,
                  bgcolor: 'rgba(255, 255, 255, 0.10)',
                  borderRadius: 3,
                  border: `2px solid ${theme.colors?.utility[300]}`,
                  background: theme.colors?.utility[200],

                  input: {
                    p: '0 !important',
                    ...theme.typography['body-xl'],
                  },

                  '.MuiOutlinedInput-notchedOutline': {
                    display: 'none !important',
                  },
                },
              }}
            >
              {prioritizationInfo !== currentTopic?.prioritizationInfo && (
                <Button
                  fullWidth={false}
                  variant="primary"
                  onClick={() => {
                    handleUpdatePrioritizationInfo(prioritizationInfo);
                  }}
                  sx={{
                    position: 'absolute',
                    right: theme.spacing(4),
                    bottom: theme.spacing(6),
                    mt: 4,
                    p: theme.spacing(2, 4),
                    borderRadius: theme.spacing(5),
                    backgroundColor: theme.colors?.primary.black,
                    border: 'none',
                    '&:hover': {
                      backgroundColor: theme.colors?.primary.black,
                    },
                  }}
                >
                  Save changes
                </Button>
              )}
            </WordLimitedInputArea>
          </Box>
          <Box display="flex" flexDirection="column" mt={2} gap={3}>
            <Typography variant="headline-md">
              Communication strategy
            </Typography>
            <Typography variant="body-xl" color={theme.colors?.utility[800]}>
              Our AI can draft responses. How should your brand voice be? Define
              the engagement parameters. For example: respond with witty humor.
            </Typography>

            <WordLimitedInputArea
              disabled={readOnly}
              autoFocus={false}
              value={communicationStrategy}
              onChange={(e) => {
                setCommunicationStrategy(e.target.value);
              }}
              maxWords={200}
              componentProps={{
                wordUsage: {
                  sx: {
                    position: 'absolute',
                    left: theme.spacing(4),
                    bottom: theme.spacing(4),
                    color: theme.colors?.utility[700],
                  },
                },
              }}
              multiline
              minRows={7}
              maxRows={12}
              sx={{
                mt: 4,
                '.MuiOutlinedInput-root': {
                  py: `${theme.spacing(4)} !important`,
                  px: `${theme.spacing(4)} !important`,
                  bgcolor: 'rgba(255, 255, 255, 0.10)',
                  borderRadius: 3,
                  border: `2px solid ${theme.colors?.utility[300]}`,
                  background: theme.colors?.utility[200],

                  input: {
                    p: '0 !important',
                    ...theme.typography['body-xl'],
                  },

                  '.MuiOutlinedInput-notchedOutline': {
                    display: 'none !important',
                  },
                },
              }}
            >
              {communicationStrategy !==
                currentTopic?.communicationStrategy && (
                <Button
                  fullWidth={false}
                  variant="primary"
                  onClick={() => {
                    handleUpdateCommunicationStrategy(communicationStrategy);
                  }}
                  sx={{
                    position: 'absolute',
                    right: theme.spacing(4),
                    bottom: theme.spacing(6),
                    mt: 4,
                    p: theme.spacing(2, 4),
                    borderRadius: theme.spacing(5),
                    backgroundColor: theme.colors?.primary.black,
                    border: 'none',
                    '&:hover': {
                      backgroundColor: theme.colors?.primary.black,
                    },
                  }}
                >
                  Save changes
                </Button>
              )}
            </WordLimitedInputArea>
          </Box>
          {currentTopic && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 6,
                mt: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 3,
                }}
              >
                <Typography variant="headline-md">Platforms</Typography>
                <Typography
                  variant="body-xl"
                  color={theme.colors?.utility[800]}
                >
                  Choose platforms for data collection. Each platform added will
                  use more hours from your account.
                </Typography>
              </Box>
              <TopicParsingSettingsView
                key={currentTopic.id}
                topicId={currentTopic.id}
                readOnly={readOnly}
                sx={{
                  gap: 3,
                }}
                componentsProps={{
                  setting: {
                    sx: {
                      bgcolor: theme.colors?.utility[200],
                      border: `1px solid ${theme.colors?.utility[300]}`,
                      borderRadius: 3,
                      color: theme.colors?.primary.black,
                      '.MuiTypography-root': {
                        fontSize: 16,
                      },
                      gap: 0,
                    },
                    componentsProps: {
                      platform: {
                        sx: {
                          bgcolor: 'transparent',
                        },
                      },
                      type: {
                        sx: {
                          bgcolor: 'transparent',
                          py: 0,
                          px: 0,
                          mb: 4,
                        },
                      },
                    },
                  },
                }}
              />
            </Box>
          )}
          {isProjectInboundEnabled && (
            <Box display="flex" flexDirection="column" mt={2} gap={3} mb={3}>
              <Typography variant="headline-md">Signals 🎯</Typography>
              <Typography variant="body-xl" color={theme.colors?.utility[800]}>
                Signals are like labels we create to organize similar types of
                information and track quantitative data. Think of it like
                sorting drink recipes into specific groups - for example, under
                'ingredients' we might label content that uses pickles,
                jalapeños, protein powder, or creamer to help find patterns,
                trends, and insights.
              </Typography>
              <Tooltip
                title={
                  !readOnly &&
                  signalDefinitions.some((s) => s.title.trim() === '')
                    ? 'Some signals are missing a title'
                    : ''
                }
                sx={{
                  padding: 0,
                }}
              >
                <Box
                  sx={{
                    width: 'fit-content',
                  }}
                >
                  <IconButton
                    sx={{
                      padding: theme.spacing(2, 3),
                      borderRadius: 2,
                      background: 'rgba(35, 6, 3, 0.10)',
                      color: theme.colors?.primary.black,
                      gap: 1,
                      ':disabled': {
                        background: 'rgba(35, 6, 3, 0.10)',
                      },
                    }}
                    disabled={
                      readOnly ||
                      signalDefinitions.some((s) => s.title.trim() === '')
                    }
                    onClick={onCreateSignalDefinition}
                  >
                    <IconBoldAddCircle size={16} />
                    <Typography variant="headline-sm">Add signal</Typography>
                  </IconButton>
                </Box>
              </Tooltip>
              {!!signalDefinitions.length && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 6,
                    mt: 3,
                  }}
                >
                  {signalDefinitions
                    .slice(
                      0,
                      showMoreSignalDefinitions
                        ? undefined
                        : MAX_VISIBLE_SIGNAL_DEFINITION_COUNT,
                    )
                    .map((signalDefinition) => (
                      <TopicSignal
                        readonly={readOnly}
                        key={signalDefinition.id}
                        signalDefinition={signalDefinition}
                        sx={{
                          border: `1px solid ${theme.colors?.utility[300]}`,
                          background: theme.colors?.utility[250],
                          '& .delete-signal-btn': {
                            svg: {
                              color: theme.colors?.utility['pink-3'],
                            },
                          },
                        }}
                        componentProps={{
                          signalDefinitionTitle: {
                            sx: {
                              color: theme.colors?.primary.black,
                              '& input': {
                                ':disabled': {
                                  color: theme.colors?.primary.black,
                                },
                              },
                            },
                          },
                          description: {
                            sx: {
                              color: theme.colors?.utility[700],
                            },
                          },
                          subCategory: {
                            sx: {
                              background: theme.colors?.utility[300],
                              ':hover': {
                                background: theme.colors?.utility[300],
                              },
                            },
                            addBtn: {
                              sx: {
                                background: theme.colors?.utility[300],
                                color: theme.colors?.primary.black,
                              },
                              icon: {
                                color: theme.colors?.primary.black,
                              },
                            },
                            label: {
                              sx: {
                                color: theme.colors?.primary.black,
                              },
                              icon: {
                                color: theme.colors?.utility[600],
                              },
                            },
                          },
                        }}
                      />
                    ))}
                </Box>
              )}
              {signalDefinitions.length >
                MAX_VISIBLE_SIGNAL_DEFINITION_COUNT && (
                <IconButton
                  disableRipple
                  sx={{
                    padding: 0,
                    ...theme.typography['subhead-xl'],
                    color: theme.colors?.primary.black,
                    width: 'fit-content',
                    display: 'grid',
                    pl: 2,
                  }}
                  onClick={() => {
                    setShowMoreSignalDefinitions(!showMoreSignalDefinitions);
                  }}
                >
                  {showMoreSignalDefinitions
                    ? 'Show less'
                    : `+${hiddenSignalDefinitionCount} more (${signalDefinitions
                        .filter((d) => d.title !== '')
                        .slice(
                          MAX_VISIBLE_SIGNAL_DEFINITION_COUNT,
                          MAX_VISIBLE_SIGNAL_DEFINITION_COUNT + 2,
                        ) // Get the next 2 hidden signal definitions to show in the button
                        .map((d) => d.title)
                        .join(', ')}${
                        hiddenSignalDefinitionCount > 2 ? ', ...' : ''
                      })`}
                  <Divider
                    sx={{
                      borderColor: theme.colors?.primary.black,
                      mt: 0.5,
                    }}
                  />
                </IconButton>
              )}
            </Box>
          )}
          {isProjectInboundEnabled && currentTopic?.id && (
            <SettingsSentimentSubjectsView currentTopicId={currentTopic?.id} />
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 6,
              mt: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
              }}
            >
              <Typography variant="headline-md">
                Content Filter Settings
              </Typography>
              <Typography variant="body-xl" color={theme.colors?.utility[800]}>
                Define minimum requirements to ensure higher quality matches and
                filter out noise.
              </Typography>
            </Box>
            <TopicContentFilterSettingsView
              topicId={currentTopic?.id || ''}
              readOnly={readOnly}
            />
          </Box>
        </Box>

        {currentTopic &&
          currentTopic.type !== ListeningTopicType.CreatorTracking && (
            <Box display="flex" flexDirection="column" gap={3} flex={2}>
              <Typography variant="headline-md">Keywords</Typography>
              <Typography variant="body-xl" color={theme.colors?.utility[800]}>
                We've generated relevant keywords for your brand and
                industry—customize as needed.
              </Typography>
              <Box
                sx={{
                  backgroundColor: theme.colors?.utility[200],
                  border: `2px solid ${theme.colors?.utility[300]}`,
                  p: 4,
                  borderRadius: 3,
                }}
              >
                <SocialMediaListeningKeywords
                  readOnly={readOnly}
                  keywords={(currentTopic.searchTerms || []).map(
                    (term) => term.term,
                  )}
                  updateKeywords={async (updatedKeywords) => {
                    await handleCreateSearchTerms(
                      currentTopic.id,
                      updatedKeywords,
                      currentTopic.allowToUpdateSearchTerm,
                    );
                    refetchBrand();
                  }}
                  componentProps={{
                    createKeyword: {
                      position: 'pre',
                      allowKeywordCreationAfterAdd: true,
                      sx: {
                        mt: 0,
                        fontWeight: 500,
                        backgroundColor: theme.colors?.utility[300],
                        color: theme.colors?.utility[600],
                        '& .MuiInputBase-root': {
                          color: `${theme.colors?.primary.black} !important`,
                        },
                        '& .keyword-add': {
                          color: theme.colors?.utility[600],
                        },
                        '& .keyword-label': {
                          fontSize: theme.spacing(3.5),
                        },
                      },
                    },
                    keyword: {
                      sx: {
                        backgroundColor: `${theme.colors?.utility[300]} !important`,
                        color: theme.colors?.primary.black,
                        fontWeight: 400,
                        span: {
                          ...typography['body-lg'],
                          color: theme.colors?.primary.black,
                        },
                      },
                      highlightBgColor: theme.colors?.utility[500],
                      componentsProps: {
                        input: {
                          sx: {
                            ...typography['body-lg'],
                            color: theme.colors?.primary.black,
                          },
                        },
                        icon: {
                          size: 16,
                        },
                      },
                    },
                    popover: {
                      itemContainer: {
                        bottomScrollGradient:
                          'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 25.71%, #FFF 95%)',
                      },
                    },
                  }}
                />
              </Box>
            </Box>
          )}

        {currentTopic &&
          currentTopic.type === ListeningTopicType.CreatorTracking && (
            <Box display="flex" flexDirection="column" gap={3} flex={2}>
              <Typography variant="headline-md">Creator list</Typography>
              <Typography variant="body-xl" color={theme.colors?.utility[800]}>
                Who are the creators you want to track?
              </Typography>
              <Box
                sx={{
                  backgroundColor: theme.colors?.utility[200],
                  border: `2px solid ${theme.colors?.utility[300]}`,
                  p: 4,
                  borderRadius: 3,
                }}
              >
                <SocialMediaListeningManageCreatorsView
                  topicId={currentTopic.id}
                  tiktokHandle={tiktokHandle}
                  instagramHandle={instagramHandle}
                  youtubeHandle={youtubeHandle}
                />
              </Box>
            </Box>
          )}
      </Box>

      <Box display="flex" gap={3} mt={4}>
        <Button
          disabled={readOnly}
          variant="primary"
          sx={{
            '&, &:hover': {
              p: theme.spacing(2, 4),
              width: 'fit-content',
              color: theme.colors?.primary.black,
              backgroundColor: theme.colors?.primary.white,
              borderRadius: 2,
              border: `1px solid ${theme.colors?.primary.black}`,
            },
          }}
          startIcon={
            isCurrentTopicActive ? (
              <IconBoldPauseCircle size={16} />
            ) : (
              <IconBoldPlayCircle size={16} />
            )
          }
          onClick={() =>
            isCurrentTopicActive
              ? handlePauseTopic({
                  topicId: currentTopic.id,
                  resetOnNextCycle: false,
                })
              : handleResumeTopic({
                  topicId: currentTopic.id,
                })
          }
        >
          <Typography variant="headline-sm">
            {isCurrentTopicActive ? 'Pause topic' : 'Resume Topic'}
          </Typography>
          {updatingTopicStatus && (
            <CircularProgress
              size={16}
              sx={{ color: theme.colors?.primary.maroon, marginLeft: 2 }}
            />
          )}
        </Button>
        {(currentTopic?.type === ListeningTopicType.TopicEngagement ||
          currentTopic?.type === ListeningTopicType.CreatorTracking) &&
          !readOnly && (
            <Button
              variant="primary"
              sx={{
                '&, &:hover': {
                  p: theme.spacing(2, 4),
                  width: 'fit-content',
                  color: theme.colors?.primary.parchment,
                  backgroundColor: theme.colors?.utility['pink-3'],
                  borderRadius: 2,
                  border: 'none',
                },
              }}
              startIcon={<IconBoldTrash size={16} />}
              onClick={onDeleteTopic}
            >
              <Typography variant="headline-sm">Delete topic</Typography>
            </Button>
          )}
      </Box>
      {deleteTopicConfirmation}
    </Box>
  );
};
