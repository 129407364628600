import { gql } from '@apollo/client';
import { Box, Grid, Typography } from '@mui/material';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { useCustomHeaderContext } from 'components/layouts/CustomHeader/contexts/CustomHeader.context';
import {
  useTrendExampleNavigatorHandler,
  useTrendNavigatorHandler,
} from 'features/trending/hooks';
import {
  LinkedPostFragmentTrendLinkPostsFragmentDoc,
  Platform,
  TrendAudioFragmentTrendingAudioPreviewFragmentDoc,
  TrendCategory,
  TrendFragmentTrendInfoFragmentDoc,
  TrendFragmentTrendingDetailViewFragment,
  useTrendQueryForTrendingDetailViewQuery,
} from 'graphql/generated';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import moment from 'moment';
import { StyledHeaderTitle } from 'pages/collections/detail/styles';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { theme } from 'styles/theme';
import { isCuid } from 'utils/helpers';
import { matchRoute } from 'utils/routes';
import { TrendingDetailContentView } from './content';
import { TrendingDetailSimilarExampleButtonView } from './content/SimilarExampleButton';
import { TrendingSidebarView } from './sidebar';
import { TrendingDetailViewSkeleton } from './TrendingDetailViewSkeleton';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
const TREND_FRAGMENT_TRENDING_DETAIL_VIEW = gql`
  fragment TrendFragmentTrendingDetailView on TrendModel {
    ...TrendFragmentTrendInfo
    trendAudioSavedCount {
      totalSavedCount
      savedContentCalendarTotal
    }
    trendAudio {
      ...TrendAudioFragmentTrendingAudioPreview
    }
    trendExamples {
      id
      totalSavedCount
      savedContentCalendarTotal
      urlMetadata {
        id
        createdAt
        metadata {
          author
          description
          hashtags
          medium
          site
          audio
          medias {
            type
            url
          }
          thumbnail {
            href
            mediaSize {
              height
              width
            }
            status
            token
            type
          }
          title
          type
        }
        updatedAt
        url
      }
    }
    linkedPosts {
      ...LinkedPostFragmentTrendLinkPosts
    }
  }
  ${TrendFragmentTrendInfoFragmentDoc}
  ${LinkedPostFragmentTrendLinkPostsFragmentDoc}
  ${TrendAudioFragmentTrendingAudioPreviewFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query TrendQueryForTrendingDetailView($trendId: String!) {
    trend(id: $trendId) {
      ...TrendFragmentTrendingDetailView
    }
  }
  ${TREND_FRAGMENT_TRENDING_DETAIL_VIEW}
`;

export const getPlatformFromTrendUrl = (url: string) => {
  const urlObj = new URL(url);
  const platform = urlObj.hostname.includes('tiktok')
    ? Platform.Tiktok
    : urlObj.hostname.includes('instagram')
    ? Platform.Instagram
    : null;
  return platform;
};
export const TrendingDetailView = () => {
  const { id: trendId = '' } = useParams();
  const navigate = useGuardNavigate();
  const location = useLocation();

  const [hideInfo, setHideInfo] = useState(false);
  const [showTrendExamples, setShowTrendExamples] = useState(false);
  const [currentTrendExampleId, setCurrentTrendExampleId] = useState('');

  const locationState = useMemo(
    () =>
      location.state as {
        hasPersona?: boolean;
        trendCategories?: TrendCategory[];
        persona?: string[];
      },
    [location.state],
  );

  const { renderLeftArrow, renderRightArrow, nextTrendId, prevTrendId } =
    useTrendNavigatorHandler({
      trendCategories: locationState?.trendCategories,
      trendId,
      customPersonaIds: locationState?.persona,
    });
  const { onWheel } = useTrendExampleNavigatorHandler();

  const analytics = useAnalytics();

  const { setRenderTitle } = useCustomHeaderContext();
  const { data: trendData, loading: isLoadingTrend } =
    useTrendQueryForTrendingDetailViewQuery({
      variables: {
        trendId,
      },
      skip: !trendId || !isCuid(trendId),
    });

  useEffect(() => {
    setShowTrendExamples(false);
  }, [trendId]);

  useEffect(() => {
    if (trendData) {
      analytics.track(EventName.TrendDetailViewed, {
        trendId: trendData.trend.id,
        category: trendData.trend.category,
        status: trendData.trend.status,
      });
    }
  }, [trendData]); // eslint-disable-line

  useEffect(() => {
    // TODO: Fix this once we refactor the PlotRoutes class
    // We need the original path (e.g. `/juicebox/:id`, not `/juicebox/asdavsdcasdas...`)
    if (
      trendData &&
      (matchRoute(location.pathname, '/juicebox/trending/:id') ||
        matchRoute(location.pathname, '/juicebox/trending/note/:id'))
    ) {
      setRenderTitle(() => (
        <StyledHeaderTitle
          component="button"
          sx={{
            cursor: 'pointer',
          }}
          // @ts-ignore
          onClick={() => navigate(-1)}
        >
          <IconOutlineArrowLeft
            size={24}
            color={theme.colors?.primary.black}
            style={{ flexShrink: 0 }}
          />
          <Typography
            variant="headline-xs"
            color={theme.colors?.primary.black}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {trendData?.trend.title}
          </Typography>
        </StyledHeaderTitle>
      ));
    }
  }, [location.pathname, trendData]); // eslint-disable-line

  const { hasMoreExamples, trendExamples } = useMemo(() => {
    const trendExamples = trendData?.trend.trendExamples || [];

    const filteredExamples: TrendFragmentTrendingDetailViewFragment['trendExamples'] =
      [];

    trendExamples.map((e) => {
      const url = e?.urlMetadata?.url;
      if (!url) return;
      const domain = new URL(url).hostname ?? url;
      if (
        domain.includes('/music/') ||
        domain.includes('/audio/') ||
        (domain.includes('tiktok.com') &&
          (e.urlMetadata.metadata?.medias || []).length === 0)
      ) {
        return;
      }
      filteredExamples.push(e);
    });

    return {
      hasMoreExamples: trendData?.trend.trendAudio
        ? filteredExamples.length > 0
        : filteredExamples.length > 1,
      trendExamples: filteredExamples.slice(
        trendData?.trend.trendAudio ? 0 : 1,
      ),
    };
  }, [trendData]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        // FIXME: minWidth is to avoid mobile breaking. Remove it once we optimize for mobile
        minWidth: 1440,
        overflowY: 'auto',
      }}
    >
      <Box
        sx={{
          flex: 1,
          maxHeight: `100%`,
          overflow: 'hidden',
          m: 3,
          borderRadius: 6,
          bgcolor: theme.colors?.primary.parchment,
          padding: theme.spacing(8, 4),
        }}
      >
        {isLoadingTrend ? (
          <TrendingDetailViewSkeleton />
        ) : (
          trendData && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 4,
                height: '100%',
                overflow: 'hidden',
              }}
            >
              {prevTrendId && (
                <Box
                  sx={{
                    maxWidth: 45,
                  }}
                >
                  {renderLeftArrow()}
                </Box>
              )}
              <Grid container spacing={8} height="100%">
                <Grid item xs={12} md={hideInfo ? 12 : 6}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: 1000,
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                      }}
                    >
                      <TrendingDetailContentView
                        key={trendData.trend.id}
                        trend={trendData.trend}
                        showTrendExamples={showTrendExamples}
                        setShowTrendExamples={setShowTrendExamples}
                        hasMoreExamples={hasMoreExamples}
                        trendExamples={trendExamples}
                        currentTrendExampleId={currentTrendExampleId}
                        setCurrentTrendExampleId={setCurrentTrendExampleId}
                        componentProps={{
                          actionButtons: {
                            hideInfo,
                            onShowInfo: () => setHideInfo(false),
                          },
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                {!hideInfo && (
                  <Grid item xs={12} md={6} height="100%">
                    <Box
                      sx={{
                        height: '100%',
                        minHeight: '100%',
                        borderRadius: 4,
                        background: `rgba(35, 6, 3, 0.04)`,
                        padding: theme.spacing(4, 8),
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 3,
                      }}
                    >
                      <TrendingSidebarView
                        trend={trendData.trend}
                        onHideInfo={() => setHideInfo(true)}
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  height: '100%',
                  flexDirection: 'column',
                  position: 'relative',
                  width: 110,
                }}
                onWheel={onWheel}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: 25,
                      background: `rgba(35, 6, 3, 0.10)`,
                      padding: theme.spacing(2, 3),
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 'fit-content',
                      height: 'fit-content',
                    }}
                  >
                    <Typography variant="headline-sm" whiteSpace="nowrap">
                      {moment(trendData.trend.createdAt).format('MMM D, YYYY')}
                    </Typography>
                  </Box>
                </Box>

                {nextTrendId && (
                  <Box sx={{ maxWidth: 45 }}>{renderRightArrow()}</Box>
                )}
              </Box>
            </Box>
          )
        )}
      </Box>
      {hasMoreExamples && trendData && (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            mb: 4,
          }}
        >
          <TrendingDetailSimilarExampleButtonView
            btnText={`${
              trendData.trend.trendAudio
                ? 'Examples of this audio in use'
                : 'View Similar Examples'
            }${
              showTrendExamples
                ? ` · ${
                    trendExamples.findIndex(
                      (e) => e.id === currentTrendExampleId,
                    ) + 1
                  } of ${trendExamples.length}`
                : ''
            }`}
            onShowMoreExamples={() =>
              !showTrendExamples && setShowTrendExamples(true)
            }
          />
        </Box>
      )}
    </Box>
  );
};
