import { Box } from '@mui/material';
import { Platform } from 'graphql/generated';

type Props = {
  platform: Platform;
  size: number;
};

export const SocialListeningPlatformLogo = ({ platform, size }: Props) => {
  if (platform === Platform.Instagram) {
    return (
      <Box
        component="img"
        src="/images/platform-logo/instagram.svg"
        width={size}
        height={size}
      />
    );
  }

  if (platform === Platform.Tiktok) {
    return (
      <Box
        component="img"
        src="/images/platform-logo/tiktok.svg"
        width={size}
        height={size}
      />
    );
  }

  if (platform === Platform.Youtube) {
    return (
      <Box
        component="img"
        src="/images/platform-logo/youtube-shorts.svg"
        width={size}
        height={size}
      />
    );
  }

  return null;
};
