import { gql } from '@apollo/client';
import { Box, Divider, Typography } from '@mui/material';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useCreatorDataForSocialListeningOnboarding } from 'features/socialMediaListening/hooks';
import {
  CreatorFragmentForUseCreatorDataForSocialListeningOnboardingFragmentDoc,
  CreatorFragmentSocialMediaListeningOnboardingSocialHandleFormFragment,
  Platform,
  PlotFeature,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { RenderNextButton } from '../renderNextButton/RenderNextButton';
import { SocialMediaListeningOnboardingSocialHandleInput } from './SocialMediaListeningOnboardingSocialHandleInput';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment CreatorFragmentSocialMediaListeningOnboardingSocialHandleForm on CreatorModel {
    ...CreatorFragmentForUseCreatorDataForSocialListeningOnboarding
  }
  ${CreatorFragmentForUseCreatorDataForSocialListeningOnboardingFragmentDoc}
`;

type Props = {
  brandId: string;
  creators: CreatorFragmentSocialMediaListeningOnboardingSocialHandleFormFragment[];
  onNext: () => void;
};

export const SocialMediaListeningOnboardingSocialHandleForm = ({
  brandId,
  creators,
  onNext,
}: Props) => {
  const { isFeatureEnabled } = useFeatureFlagContext();

  const tiktokCreator = creators.find(
    (creator) => creator.platform === Platform.Tiktok,
  );
  const instagramCreator = creators.find(
    (creator) => creator.platform === Platform.Instagram,
  );
  const youtubeCreator = creators.find(
    (creator) => creator.platform === Platform.Youtube,
  );

  const { onboardingActions } = useCreatorDataForSocialListeningOnboarding();
  const { handleCreateCreator, handleRemoveCreator } = onboardingActions;

  const canNext = Boolean(tiktokCreator || instagramCreator || youtubeCreator);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '60vw',
        width: '100%',
        color: theme.colors?.primary.parchment,
        overflowY: 'auto',
        pb: 4,
      }}
    >
      <Typography variant="headline-xl" fontSize={28} mb={3}>
        Connect your socials
      </Typography>
      <Typography variant="subhead-xl" mb={8}>
        Please add at least one social handle to continue:
      </Typography>
      <SocialMediaListeningOnboardingSocialHandleInput
        creator={tiktokCreator}
        platform={Platform.Tiktok}
        addCreator={async (creator) => {
          await handleCreateCreator(creator.handle, brandId, Platform.Tiktok);
        }}
        removeCreator={async (creatorId) => {
          await handleRemoveCreator(creatorId, brandId);
        }}
      />
      <Divider
        sx={{
          my: 6,
          borderColor: theme.colors?.primary.parchment,
          opacity: 0.2,
        }}
      />
      <SocialMediaListeningOnboardingSocialHandleInput
        creator={instagramCreator}
        platform={Platform.Instagram}
        addCreator={async (creator) => {
          await handleCreateCreator(
            creator.handle,
            brandId,
            Platform.Instagram,
          );
        }}
        removeCreator={async (creatorId: string) => {
          await handleRemoveCreator(creatorId, brandId);
        }}
      />
      {isFeatureEnabled(PlotFeature.YoutubeParsing) && (
        <>
          <Divider
            sx={{
              my: 6,
              borderColor: theme.colors?.primary.parchment,
              opacity: 0.2,
            }}
          />
          <SocialMediaListeningOnboardingSocialHandleInput
            creator={creators.find(
              (creator) => creator.platform === Platform.Youtube,
            )}
            platform={Platform.Youtube}
            addCreator={async (creator) => {
              await handleCreateCreator(
                creator.handle,
                brandId,
                Platform.Youtube,
              );
            }}
            removeCreator={async (creatorId: string) => {
              await handleRemoveCreator(creatorId, brandId);
            }}
          />
        </>
      )}
      <RenderNextButton disabled={!canNext} onNextClick={onNext} />
    </Box>
  );
};
