import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { CheckboxMenuItem } from 'components/common/form/Select';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import { IconCustomSparkles } from 'components/icons/components/custom/IconCustomSparkles';
import { SignalDefinitionFragmentSlaAnalyticsFilterBySignalsFragment } from 'graphql/generated';
import _ from 'lodash';
import { useMemo } from 'react';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  fragment SignalDefinitionFragmentSLAAnalyticsFilterBySignals on SignalDefinitionModel {
    id
    title
    options {
      id
      label
      value
    }
  }
`;

interface SLAAnalyticsFilterBySignalsProps {
  signalDefinitions: SignalDefinitionFragmentSlaAnalyticsFilterBySignalsFragment[];
  renderTitle?: () => React.ReactNode;
  selectedSignalIds: string[];
  selectedSignalOptionIds: string[];
  onChange: (
    selectedSignalIds: string[],
    selectedSignalOptionIds: string[],
  ) => void;
  variant?: 'normal' | 'accordion';
}

export const SLAAnalyticsFilterBySignals = ({
  signalDefinitions,
  renderTitle,
  selectedSignalIds,
  selectedSignalOptionIds,
  onChange,
  variant = 'accordion',
}: SLAAnalyticsFilterBySignalsProps) => {
  const signals = signalDefinitions.map((signalDefinition) => ({
    id: signalDefinition.id,
    name: signalDefinition.title,
    options: signalDefinition.options.map((option) => ({
      id: option.id,
      name: option.label,
    })),
  }));

  const renderLabel = useMemo(() => {
    if (renderTitle) {
      return renderTitle();
    }

    return (
      <Box
        sx={{
          display: 'flex',
          gap: theme.spacing(2),
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(35, 6, 3, 0.05)',
            borderRadius: theme.spacing(1),
            padding: theme.spacing(1),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconCustomSparkles
            size={16}
            style={{
              color: theme.colors?.primary.black,
            }}
          />
        </Box>

        <Typography variant="subhead-lg">Signals</Typography>
      </Box>
    );
  }, [renderTitle]);

  return (
    <DropDownMoreMenuItem
      disableRipple
      singleSelectOnly
      sx={{
        ...(variant === 'normal' && {
          pointerEvents: 'none',
        }),
        svg: {
          color: theme.colors?.primary.black,
          ...(variant === 'normal' && {
            display: 'none',
          }),
        },
      }}
      defaultOpen={variant === 'normal'}
      label={renderLabel}
      dropDownOptions={
        signals.length
          ? signals.map((signal) => {
              const allSignalOptionIds = signal.options.map(
                (option) => option.id,
              );
              const allSignalOptionsSelected = signal.options.every((option) =>
                selectedSignalOptionIds.includes(option.id),
              );

              return {
                label: signal.name,
                value: signal.id,
                renderOption: () =>
                  signal.options.length ? (
                    <Box>
                      <DropDownMoreMenuItem
                        renderHeaderAsOption
                        label={
                          <Box sx={{ maxWidth: 120, overflow: 'hidden' }}>
                            <Typography
                              variant="subhead-lg"
                              sx={{
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                width: '100%',
                              }}
                            >
                              {signal.name}
                            </Typography>
                          </Box>
                        }
                        onClick={() => {
                          if (allSignalOptionsSelected) {
                            // Unselect all children only
                            onChange(
                              [], // Never include parent IDs
                              selectedSignalOptionIds.filter(
                                (id) => !allSignalOptionIds.includes(id),
                              ),
                            );
                          } else {
                            // Select all children only
                            onChange(
                              [], // Never include parent IDs
                              _.uniq([
                                ...selectedSignalOptionIds,
                                ...allSignalOptionIds,
                              ]),
                            );
                          }
                        }}
                        dropDownOptions={signal.options.map((option) => ({
                          value: option.id,
                          label: option.name,
                          isChecked: selectedSignalOptionIds.includes(
                            option.id,
                          ),
                          renderOption: () => (
                            <CheckboxMenuItem
                              disableRipple
                              value={option.id}
                              label={option.name}
                              onClick={() => {
                                const isOptionSelected =
                                  selectedSignalOptionIds.includes(option.id);

                                let newOptionIds = _.uniq([
                                  ...selectedSignalOptionIds,
                                ]);

                                if (isOptionSelected) {
                                  // Unselect option only
                                  newOptionIds = newOptionIds.filter(
                                    (id) => id !== option.id,
                                  );
                                } else {
                                  // Select option only
                                  newOptionIds = [...newOptionIds, option.id];
                                }

                                // Never include parent IDs
                                onChange([], newOptionIds);
                              }}
                              checked={selectedSignalOptionIds.includes(
                                option.id,
                              )}
                              sx={{
                                ml: 6,
                              }}
                            />
                          ),
                        }))}
                      />
                    </Box>
                  ) : (
                    <CheckboxMenuItem
                      disableRipple
                      value={signal.id}
                      label={signal.name}
                      onClick={() => {
                        const isSelected = selectedSignalIds.includes(
                          signal.id,
                        );
                        if (isSelected) {
                          onChange(
                            selectedSignalIds.filter((id) => id !== signal.id),
                            [],
                          );
                        } else {
                          onChange([...selectedSignalIds, signal.id], []);
                        }
                      }}
                      checked={selectedSignalIds.includes(signal.id)}
                    />
                  ),
              };
            })
          : [
              {
                label: 'No signals',
                value: '',
                renderOption: () => (
                  <Typography
                    p={2}
                    textAlign="center"
                    variant="subhead-lg"
                    color={theme.colors?.utility[700]}
                  >
                    No signals available
                  </Typography>
                ),
              },
            ]
      }
    />
  );
};
