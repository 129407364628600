import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  Button,
  Card,
  IconButton,
  Input,
  Typography,
} from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { RadioMenuItem } from 'components/common/form/Select';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { IconLinearBox } from 'components/icons/components/linear/IconLinearBox';
import { IconLinearGrid2 } from 'components/icons/components/linear/IconLinearGrid2';
import { IconLinearShop } from 'components/icons/components/linear/IconLinearShop';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { IconOutlineSearchNormal1 } from 'components/icons/components/outline/IconOutlineSearchNormal1';
import { SLARankingTable } from 'features/socialListeningAnalytics/components';
import {
  BrandInboundFiltersInput,
  BrandInboundFiltersInputForCapturedProduct,
  CapturedProductSectionViewType,
} from 'graphql/generated';
import { debounce } from 'lodash';
import { useState } from 'react';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';

const ViewTypeLabelMap = {
  [CapturedProductSectionViewType.ProductLineModel]: 'Product line & Model',
  [CapturedProductSectionViewType.ProductBrand]: 'Brand',
  [CapturedProductSectionViewType.ProductCategory]: 'Category',
};

const RankingTitleMap = {
  [CapturedProductSectionViewType.ProductLineModel]: 'Product line & Model',
  [CapturedProductSectionViewType.ProductBrand]: 'Brands',
  [CapturedProductSectionViewType.ProductCategory]: 'Categories',
};

const ViewTypeIconMap = {
  [CapturedProductSectionViewType.ProductLineModel]: IconLinearBox,
  [CapturedProductSectionViewType.ProductBrand]: IconLinearShop,
  [CapturedProductSectionViewType.ProductCategory]: IconLinearGrid2,
};

interface SLABrandInboundRankingViewProps {
  filters: Omit<
    BrandInboundFiltersInputForCapturedProduct,
    'viewType' | 'searchTerm' | 'sortBy'
  >;
  onSelectFilter: (
    filter: Pick<
      BrandInboundFiltersInput,
      | 'capturedProductBrandIds'
      | 'capturedProductAttributeIds'
      | 'capturedProductCategoryIds'
      | 'capturedProductLineModelInputs'
    >,
  ) => void;
}

export const SLABrandInboundRankingView = ({
  filters,
  onSelectFilter,
}: SLABrandInboundRankingViewProps) => {
  const [type, setType] = useState<CapturedProductSectionViewType | null>(
    CapturedProductSectionViewType.ProductLineModel,
  );
  const [searchTerm, setSearchTerm] = useState('');
  // this is used for controlling to show search input or not
  const searchExpand = useDisclosure();
  const debouncedSearchTerm = debounce((value) => setSearchTerm(value), 300);

  const [totalPostsCount, setTotalPostsCount] = useState(0);

  const viewTypeOption = Object.entries(ViewTypeLabelMap).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  return (
    <Card
      sx={{
        borderRadius: 5,
        my: 2,
        p: 6,
        boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Box display="flex" justifyContent="space-between" mb={4}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap={4}
        >
          <Typography
            variant="headline-sm"
            color={theme.colors?.utility[700]}
            pb={1}
            display="flex"
            alignItems="center"
            gap={2}
          >
            Market Rankings
            <Tooltip
              title={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    maxWidth: 232,
                  }}
                >
                  <Typography
                    variant="headline-xxs"
                    color={theme.colors?.primary.parchment}
                  >
                    Market Rankings
                  </Typography>
                  <Typography
                    variant="subhead-sm"
                    color={theme.colors?.primary.parchment}
                  >
                    Track performance trends across brands, products, and
                    categories to identify market leaders and rising
                    competitors.
                  </Typography>
                </Box>
              }
            >
              <Box display="flex" alignItems="center">
                <IconBoldInfoCircle
                  size={16}
                  style={{
                    rotate: '180deg',
                    width: 17,
                  }}
                />
              </Box>
            </Tooltip>
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
            <Typography
              variant="headline-xl"
              fontSize={theme.spacing(8)}
              fontWeight={500}
            >
              {type ? RankingTitleMap[type] : ''}
            </Typography>
            {totalPostsCount > 0 && (
              <Typography
                variant="headline-xl"
                fontSize={theme.spacing(6)}
                color={theme.colors?.utility[600]}
                fontWeight={500}
              >
                {formatBigNumber(totalPostsCount)} posts
              </Typography>
            )}
          </Box>
        </Box>
        <Box>
          <ContextMenu
            sx={{
              '& .context-menu-item': {
                p: 0,
                color: theme.colors?.primary.black,
              },
            }}
            options={viewTypeOption.map((option) => ({
              renderOption: () => (
                <RadioMenuItem
                  label={option.label}
                  value={option.value}
                  checked={type === option.value}
                  sx={{
                    p: `${theme.spacing(2, 3)}`,
                  }}
                />
              ),
              onClick: () => {
                setType(option.value as CapturedProductSectionViewType);
              },
            }))}
            renderButton={() => {
              const Icon = type ? ViewTypeIconMap[type] : IconLinearSort;
              return (
                <Button
                  variant="tertiary"
                  sx={{
                    borderRadius: 2,
                    gap: 2,
                    px: 2,
                  }}
                >
                  <Box
                    sx={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                      backgroundColor: 'rgba(35, 6, 3, 0.05)',
                      p: theme.spacing(1),
                      borderRadius: theme.spacing(1),
                    }}
                  >
                    <Icon size={16} />
                  </Box>
                  <Typography fontWeight={500} variant="body-xl">
                    {type ? ViewTypeLabelMap[type] : 'View by'}
                  </Typography>
                  <IconLinearArrowDown size={16} />
                </Button>
              );
            }}
          />
        </Box>
      </Box>

      {searchExpand.isOpen ? (
        <Input
          disableUnderline
          fullWidth
          placeholder="Search for a brand, category, attribute or product line"
          autoFocus
          onChange={(e) => {
            debouncedSearchTerm(e.target.value);
          }}
          startAdornment={
            <IconOutlineSearchNormal1
              size={16}
              style={{
                marginRight: theme.spacing(2),
                color: theme.colors?.utility[700],
              }}
            />
          }
          endAdornment={
            <IconBoldCloseCircle
              size={20}
              style={{
                color: theme.colors?.utility[700],
                cursor: 'pointer',
              }}
              onClick={() => {
                searchExpand.onClose();
                setSearchTerm('');
              }}
            />
          }
          sx={{
            borderRadius: 25,
            background: theme.colors?.utility[275],
            padding: theme.spacing(1, 4),
            ...theme.typography['subhead-xl'],
            mb: 3,
          }}
        />
      ) : (
        <IconButton
          sx={{
            borderRadius: 25,
            background: theme.colors?.utility[275],
            width: 40,
            height: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 0,
            mb: 3,
          }}
          onClick={searchExpand.onOpen}
        >
          <IconOutlineSearchNormal1 size={16} />
        </IconButton>
      )}
      {type && (
        <SLARankingTable
          filters={{
            ...filters,
            viewType: type,
            searchTerm,
          }}
          onSetTotalPostsCount={setTotalPostsCount}
          onSelectFilter={({
            capturedProductBrandIds,
            capturedProductAttributeIds,
            capturedProductCategoryIds,
            capturedProductLineModelInputs,
          }) => {
            if (capturedProductBrandIds) {
              onSelectFilter({
                capturedProductBrandIds,
              });
              setType(CapturedProductSectionViewType.ProductBrand);
            }
            if (capturedProductAttributeIds) {
              onSelectFilter({
                capturedProductAttributeIds,
              });
            }
            if (capturedProductCategoryIds) {
              onSelectFilter({
                capturedProductCategoryIds,
              });
              setType(CapturedProductSectionViewType.ProductCategory);
            }
            if (capturedProductLineModelInputs) {
              onSelectFilter({
                capturedProductLineModelInputs,
              });
              setType(CapturedProductSectionViewType.ProductLineModel);
            }
          }}
        />
      )}
    </Card>
  );
};
