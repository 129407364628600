import { styled, Typography } from '@mui/material';
import { theme } from 'styles/theme';

export const StyledHoveredTableCellTypography = styled(Typography)<{
  isActive?: boolean;
}>(({ isActive }) => ({
  ':hover': {
    textDecoration: isActive ? 'underline' : 'none',
  },
  width: 'fit-content',
  ...theme.typography['subhead-xl'],
  fontWeight: `${!isActive ? 600 : 500}`,
}));

export const stickyTableColumnStyles = {
  position: 'sticky',
  left: 0,
  borderRight: `1px solid ${theme.colors?.utility[400]}`,
  boxShadow: `0px 2px 10px -3px rgba(0, 0, 0, 0.05)`,
  backgroundColor: theme.colors?.primary.white,
  zIndex: 99,
};
