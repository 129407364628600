import { gql } from '@apollo/client';
import { Box, IconButton, Input, SxProps, Typography } from '@mui/material';
import { CommandKeys } from 'components/common/CommandKey';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldAddCircle } from 'components/icons/components/bold/IconBoldAddCircle';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconBoldTrash } from 'components/icons/components/bold/IconBoldTrash';
import {
  SignalDefinitionFragmentTopicSignalFragment,
  useCreateSignalCategoryOptionForTopicSignalMutation,
  useDeleteSignalCategoryOptionForTopicSignalMutation,
  useDeleteSignalDefinitionForTopicSignalMutation,
  useUpdateSignalCategoryOptionForTopicSignalMutation,
  useUpdateSignalDefinitionForTopicSignalMutation,
} from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { evictObject, modifyObject } from 'utils/apollo';

const SIGNAL_DEFINITION_FRAGMENT_TOPIC_SIGNAL = gql`
  fragment SignalDefinitionFragmentTopicSignal on SignalDefinitionModel {
    id
    title
    description
    options {
      id
      label
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateSignalDefinitionForTopicSignal(
    $data: UpdateSignalDefinitionInput!
  ) {
    updateSignalDefinition(data: $data) {
      ...SignalDefinitionFragmentTopicSignal
    }
  }
  ${SIGNAL_DEFINITION_FRAGMENT_TOPIC_SIGNAL}
`;

// eslint-disable-next-line
gql`
  mutation DeleteSignalDefinitionForTopicSignal(
    $data: DeleteSignalDefinitionInput!
  ) {
    deleteSignalDefinition(data: $data) {
      message
      success
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation CreateSignalCategoryOptionForTopicSignal(
    $data: CreateSignalCategoryOptionInput!
  ) {
    createSignalCategoryOption(data: $data) {
      id
      label
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateSignalCategoryOptionForTopicSignal(
    $data: UpdateSignalCategoryOptionInput!
  ) {
    updateSignalCategoryOption(data: $data) {
      id
      label
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation DeleteSignalCategoryOptionForTopicSignal(
    $data: DeleteSignalCategoryOptionInput!
  ) {
    deleteSignalCategoryOption(data: $data) {
      message
      success
    }
  }
`;

interface TopicSignalProps {
  sx?: SxProps;
  signalDefinition: SignalDefinitionFragmentTopicSignalFragment;
  showHelpText?: boolean;
  autoFocus?: boolean;
  readonly?: boolean;
  componentProps?: {
    signalDefinitionTitle?: {
      sx?: SxProps;
    };
    description?: {
      sx?: SxProps;
    };
    subCategory?: {
      sx?: SxProps;
      addBtn?: {
        sx?: SxProps;
        icon?: {
          color?: string;
          size?: number;
        };
      };
      label?: {
        sx?: SxProps;
        icon?: {
          color?: string;
          size?: number;
        };
      };
    };
  };
}

export const TopicSignal = ({
  sx,
  signalDefinition,
  componentProps = {},
  showHelpText,
  autoFocus,
  readonly,
}: TopicSignalProps) => {
  const [isAddingSubcategory, setIsAddingSubcategory] = useState(false);
  const [isEditingCategory, setIsEditingCategory] = useState(false);
  const [subCategoryLabel, setSubCategoryLabel] = useState('');
  const [editingSubcategoryId, setEditingSubcategoryId] = useState<string>('');
  const [signalTitle, setSignalTitle] = useState('');
  const [description, setDescription] = useState('');

  const {
    dialog: deleteSignalDefinitionDialog,
    onOpen: openDeleteSignalDefinitionDialog,
  } = useConfirmationDialog();

  const [updateSignalDefinition] =
    useUpdateSignalDefinitionForTopicSignalMutation();
  const [deleteSignalDefinition] =
    useDeleteSignalDefinitionForTopicSignalMutation();
  const [createSignalCategoryOption] =
    useCreateSignalCategoryOptionForTopicSignalMutation();
  const [updateSignalCategoryOption] =
    useUpdateSignalCategoryOptionForTopicSignalMutation();
  const [deleteSignalCategoryOption] =
    useDeleteSignalCategoryOptionForTopicSignalMutation();

  const { hasSubcategories, subCategories } = useMemo(() => {
    const subCategories = signalDefinition.options;
    const hasSubcategories = subCategories.length > 0;
    return { subCategories, hasSubcategories };
  }, [signalDefinition.options]);

  useEffect(() => {
    return () => {
      setIsEditingCategory(false);
      setIsAddingSubcategory(false);
      setEditingSubcategoryId('');
      setSignalTitle('');
      setDescription('');
    };
  }, []);

  const onUpdateSignal = () => {
    setIsEditingCategory(false);
    updateSignalDefinition({
      variables: {
        data: {
          data: {
            title: signalTitle.trim() || signalDefinition.title,
            description: description.trim() || signalDefinition.description,
          },
          signalDefinitionId: signalDefinition.id,
        },
      },
      optimisticResponse: {
        updateSignalDefinition: {
          ...signalDefinition,
          title: signalTitle,
          description,
        },
      },
    });
  };

  const onDeleteCategory = useCallback(() => {
    openDeleteSignalDefinitionDialog({
      confirmText: 'Yes, remove signal',
      title: (
        <Typography
          variant="headline-xl"
          fontSize={24}
          fontWeight={500}
          letterSpacing="-0.96px"
        >
          Are you sure you want to delete the created signal?
        </Typography>
      ),
      subtitle: signalDefinition.title ? (
        <Typography variant="subhead-xl" color={theme.colors?.utility[800]}>
          {signalDefinition.title} will be deleted.
        </Typography>
      ) : (
        ''
      ),
      onConfirm: () => {
        deleteSignalDefinition({
          variables: {
            data: {
              signalDefinitionId: signalDefinition.id,
            },
          },
          update: (cache) => {
            evictObject(cache, signalDefinition.id, 'SignalDefinitionModel');
          },
        });
      },
    });
  }, [
    signalDefinition.id,
    signalDefinition.title,
    deleteSignalDefinition,
    openDeleteSignalDefinitionDialog,
  ]);

  const onUpdateSignalCategoryOption = (signalCategoryOptionId: string) => {
    updateSignalCategoryOption({
      variables: {
        data: {
          data: { label: subCategoryLabel.trim() },
          signalCategoryOptionId,
        },
      },
      update: (cache) => {
        modifyObject(cache, signalDefinition.id, 'SignalDefinitionModel', {
          options: (existingFieldValues) =>
            existingFieldValues.map((option) =>
              option.id === signalCategoryOptionId
                ? {
                    ...option,
                    label: subCategoryLabel,
                  }
                : option,
            ),
        });
      },
    });
  };

  const isEditing = useMemo(() => {
    return (
      isEditingCategory || isAddingSubcategory || editingSubcategoryId !== ''
    );
  }, [isEditingCategory, isAddingSubcategory, editingSubcategoryId]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            p: theme.spacing(4, 6),
            borderRadius: 4,
            border: `1px solid ${
              isEditing ? theme.colors?.primary.parchment : 'transparent'
            }`,
            background: 'rgba(255, 255, 255, 0.10)',
            ':hover': {
              background: isEditing ? '' : 'rgba(255, 255, 255, 0.20)',
              '.delete-signal-btn': {
                opacity: 1,
              },
            },
            ...sx,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              justifyContent: 'space-between',
            }}
          >
            <Input
              disabled={readonly}
              disableUnderline
              placeholder="Signal"
              autoFocus={autoFocus}
              fullWidth
              onBlur={onUpdateSignal}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onUpdateSignal();
                }
              }}
              sx={{
                ...theme.typography['headline-md'],
                color: theme.colors?.primary.parchment,
                fontSize: 20,
                p: 0,
                '& input': {
                  p: 0,
                  ':disabled': {
                    color: theme.colors?.primary.parchment,
                  },
                },
                ...componentProps.signalDefinitionTitle?.sx,
              }}
              value={signalTitle || signalDefinition.title}
              onChange={(e) => {
                if (e.target.value) {
                  setSignalTitle(e.target.value);
                } else {
                  setSignalTitle(' ');
                }
              }}
              onFocus={() => {
                setSignalTitle(signalDefinition.title);
                setIsEditingCategory(true);
              }}
            />
            <IconButton
              disableRipple
              sx={{
                p: 0,
                opacity: 0,
              }}
              className="delete-signal-btn"
              onClick={onDeleteCategory}
              {...(readonly && {
                style: {
                  opacity: 0,
                },
              })}
            >
              <IconBoldTrash
                size={20}
                color={theme.colors?.utility['pink-2']}
              />
            </IconButton>
          </Box>
          <Input
            disabled={readonly}
            placeholder="Add a description (optional)"
            disableUnderline
            multiline
            maxRows={2}
            value={description || signalDefinition.description}
            onChange={(e) => {
              if (e.target.value) {
                setDescription(e.target.value);
              } else {
                setDescription(' ');
              }
            }}
            sx={{
              ...theme.typography['subhead-xl'],
              color: theme.colors?.utility[400],
              p: 0,
              ...componentProps.description?.sx,
            }}
            onBlur={onUpdateSignal}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onUpdateSignal();
              }
            }}
            onFocus={() => {
              setDescription(signalDefinition.description);
              setIsEditingCategory(true);
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2,
              alignItems: 'center',
              mt: 3,
            }}
          >
            {subCategories.map((subCategory) => (
              <Tooltip
                title={readonly ? '' : 'Click to edit'}
                sx={{
                  p: 0,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    px: 3,
                    py: editingSubcategoryId === subCategory.id ? 1 : 1.5,
                    borderRadius: 8,
                    background: 'rgba(35, 6, 3, 0.10)',
                    gap: 2,
                    cursor: readonly ? 'default' : 'pointer',
                    border: `1px solid ${
                      editingSubcategoryId !== subCategory.id
                        ? 'transparent'
                        : 'rgba(250, 243, 236, 0.50)'
                    }`,
                    ':hover': {
                      background: 'rgba(255, 255, 255, 0.20)',
                    },
                    ...componentProps.subCategory?.sx,
                  }}
                >
                  {editingSubcategoryId === subCategory.id ? (
                    <Input
                      disabled={readonly}
                      disableUnderline
                      autoFocus
                      fullWidth
                      sx={{
                        ...theme.typography['subhead-xl'],
                        color: theme.colors?.primary.parchment,
                        p: 0,
                        width: 'auto',
                        '& input': {
                          p: 0,
                        },
                        ...componentProps.subCategory?.label?.sx,
                      }}
                      value={subCategoryLabel}
                      onBlur={() => {
                        onUpdateSignalCategoryOption(subCategory.id);
                      }}
                      onChange={(e) => setSubCategoryLabel(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Escape') {
                          setEditingSubcategoryId('');
                        } else if (e.key === 'Enter') {
                          setTimeout(() => {
                            setEditingSubcategoryId('');
                          }, 20);
                          onUpdateSignalCategoryOption(subCategory.id);
                        }
                      }}
                    />
                  ) : (
                    <Typography
                      variant="subhead-xl"
                      onClick={() => {
                        if (readonly) return;
                        setEditingSubcategoryId(subCategory.id);
                        setSubCategoryLabel(subCategory.label);
                      }}
                      sx={{
                        ...componentProps.subCategory?.label?.sx,
                      }}
                    >
                      {subCategory.label}
                    </Typography>
                  )}
                  {!readonly && (
                    <IconButton
                      sx={{ p: 0 }}
                      onClick={() => {
                        deleteSignalCategoryOption({
                          variables: {
                            data: { signalCategoryOptionId: subCategory.id },
                          },
                          update: (cache) => {
                            modifyObject(
                              cache,
                              signalDefinition.id,
                              'SignalDefinitionModel',
                              {
                                options: (existingSignalCategories) => {
                                  // Get ref
                                  const normalizedId = cache.identify({
                                    id: subCategory.id,
                                    __typename: 'SignalCategoryOptionModel',
                                  });

                                  return existingSignalCategories.filter((p) =>
                                    p.id
                                      ? p.id !== subCategory.id
                                      : p.__ref !== normalizedId,
                                  );
                                },
                              },
                            );
                          },
                        });
                      }}
                    >
                      <IconBoldCloseCircle
                        size={
                          componentProps.subCategory?.label?.icon?.size || 16
                        }
                        color={
                          componentProps.subCategory?.label?.icon?.color ||
                          theme.colors?.utility[500]
                        }
                      />
                    </IconButton>
                  )}
                </Box>
              </Tooltip>
            ))}
            {readonly ? null : isAddingSubcategory ? (
              <Input
                disableUnderline
                autoFocus
                sx={{
                  ...theme.typography['subhead-xl'],
                  color: theme.colors?.primary.parchment,
                  p: 0,
                  '& input': {
                    p: 0,
                  },
                  ...componentProps.subCategory?.label?.sx,
                }}
                value={subCategoryLabel}
                onChange={(e) => setSubCategoryLabel(e.target.value)}
                onBlur={() => setIsAddingSubcategory(false)}
                onKeyDown={(e) => {
                  if (e.key === 'Escape') {
                    setIsAddingSubcategory(false);
                  }
                  if (e.key === 'Enter' && subCategoryLabel) {
                    createSignalCategoryOption({
                      variables: {
                        data: {
                          data: {
                            label: subCategoryLabel,
                            signalDefinitionId: signalDefinition.id,
                          },
                        },
                      },
                      update: (cache, { data }) => {
                        if (!data?.createSignalCategoryOption) return;
                        modifyObject(
                          cache,
                          signalDefinition.id,
                          'SignalDefinitionModel',
                          {
                            options: (existingFieldValues) => [
                              ...existingFieldValues,
                              data.createSignalCategoryOption,
                            ],
                          },
                        );
                      },
                    });
                    setSubCategoryLabel('');
                  }
                }}
              />
            ) : hasSubcategories ? (
              <IconButton
                sx={{
                  p: 0,
                  ...componentProps.subCategory?.addBtn?.sx,
                }}
                onClick={() => {
                  setIsAddingSubcategory(true);
                  setSubCategoryLabel('');
                }}
              >
                <IconBoldAddCircle
                  size={componentProps.subCategory?.addBtn?.icon?.size || 20}
                  color={
                    componentProps.subCategory?.addBtn?.icon?.color ||
                    theme.colors?.primary.parchment
                  }
                  style={{
                    flexShrink: 0,
                  }}
                />
              </IconButton>
            ) : (
              <IconButton
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                  color: theme.colors?.primary.parchment,
                  borderRadius: 8,
                  background: 'rgba(35, 6, 3, 0.10)',
                  width: 'fit-content',
                  p: theme.spacing(1.5, 3),
                  ...componentProps.subCategory?.addBtn?.sx,
                }}
                onClick={() => setIsAddingSubcategory(true)}
              >
                <IconBoldAddCircle
                  size={componentProps.subCategory?.addBtn?.icon?.size || 17}
                  color={
                    componentProps.subCategory?.addBtn?.icon?.color ||
                    theme.colors?.utility[500]
                  }
                  style={{
                    flexShrink: 0,
                  }}
                />
                <Typography variant="subhead-xl">Add value</Typography>
              </IconButton>
            )}
          </Box>
        </Box>
        {showHelpText && isAddingSubcategory && (
          <Box
            sx={{
              ...theme.typography['subhead-lg'],
              display: 'flex',
              gap: 1,
              flexWrap: 'wrap',
            }}
          >
            press{' '}
            <CommandKeys
              keys={['⏎']}
              componentsProps={{
                key: {
                  sx: {
                    background: 'rgba(250, 243, 236, 0.15)',
                    color: theme.colors?.primary.parchment,
                    fontSize: 14,
                    fontFamily: 'system-ui',
                    fontWeight: 600,
                  },
                },
              }}
            />
            to create the subcategory
          </Box>
        )}
      </Box>
      {deleteSignalDefinitionDialog}
    </>
  );
};
