import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Chip, IconButton, Typography } from '@mui/material';
import { CapturedProductDataForInbound } from 'graphql/generated';
import { theme } from 'styles/theme';

interface AttributesSectionProps {
  attributeUsage: CapturedProductDataForInbound['attributeUsage'];
}

export const AttributesSection = ({
  attributeUsage,
}: AttributesSectionProps) => {
  const seeMoreAttributes = useDisclosure();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
        margin: theme.spacing(6, 4, 2, 4),
      }}
    >
      <Typography
        variant="headline-md"
        fontSize={20}
        color={theme.colors?.utility[900]}
      >
        Attributes
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          flexWrap: 'wrap',
        }}
      >
        {[
          ...(seeMoreAttributes.isOpen
            ? attributeUsage
            : attributeUsage.slice(0, 6)),
        ].map((attribute) => (
          <Chip
            key={attribute.attribute}
            sx={{
              background: theme.colors?.primary.parchment,
              border: 'none',
              ...theme.typography['subhead-xl'],
            }}
            label={
              <Typography variant="headline-sm">
                {attribute.attribute}{' '}
                {attribute.count > 0 && (
                  <Typography
                    variant="subhead-xl"
                    color={theme.colors?.utility[700]}
                  >
                    ({attribute.count})
                  </Typography>
                )}
              </Typography>
            }
          />
        ))}
      </Box>
      {attributeUsage.length > 6 && (
        <IconButton
          sx={{
            p: 0,
            ...theme.typography['subhead-lg'],
            color: theme.colors?.utility[800],
            width: 'fit-content',
            mt: -4,
            ml: 1,
          }}
          disableRipple
          onClick={seeMoreAttributes.onToggle}
        >
          See {seeMoreAttributes.isOpen ? 'less' : 'more'}
        </IconButton>
      )}
    </Box>
  );
};
