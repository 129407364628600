import { gql } from '@apollo/client';
import {
  SocialListeningPostsSortBy,
  SocialPostFragmentSocialMediaListeningListItemFragmentDoc,
  SocialPostFragmentSocialMediaListeningPostCardFragmentDoc,
  SortOrder,
  useGetSocialPostsQuery,
} from 'graphql/generated';
import { SocialListeningFiltersKey } from 'pages/socialMediaListening/constant';
import { useCallback, useMemo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetSocialPosts(
    $filters: GetPaginatedSocialPostFilterInput
    $after: String
    $before: String
    $sortBy: SocialListeningPostsSortBy
    $sortOrder: SortOrder
    $take: Int
    $topicIds: [String!]
    $brandId: String!
  ) {
    getPaginatedSocialPosts(
      filters: $filters
      after: $after
      before: $before
      sortBy: $sortBy
      sortOrder: $sortOrder
      take: $take
      brandId: $brandId
    ) {
      data {
        id
        __typename
        ...SocialPostFragmentSocialMediaListeningListItem
        ...SocialPostFragmentSocialMediaListeningPostCard
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      meta {
        totalCount
      }
    }
  }
  ${SocialPostFragmentSocialMediaListeningListItemFragmentDoc}
  ${SocialPostFragmentSocialMediaListeningPostCardFragmentDoc}
`;

export type UseSocialPostsListProps = {
  brandId: string;
  sortBy: SocialListeningPostsSortBy;
  sortOrder: SortOrder;
  filters: { [key: string]: any };
};

export const useSocialPostsList = ({
  brandId,
  sortBy,
  sortOrder,
  filters,
}: UseSocialPostsListProps) => {
  const variables = useMemo(() => {
    return {
      sortBy,
      sortOrder,
      take: 10,
      filters: {
        ...filters,
        dateRange:
          filters[SocialListeningFiltersKey.Period] &&
          filters[SocialListeningFiltersKey.Period].length === 3
            ? // the first element the the filter label, we don't send it to backend
              [
                filters[SocialListeningFiltersKey.Period][1],
                filters[SocialListeningFiltersKey.Period][2],
              ]
            : [],
      },
      topicIds: (filters?.topicIds as string[]) ?? [],
      brandId,
    };
  }, [sortBy, sortOrder, filters, brandId]);

  const {
    data: socialPostsData,
    loading,
    fetchMore,
    refetch,
  } = useGetSocialPostsQuery({
    variables,
    skip: !brandId,
  });

  const hasNextPage =
    socialPostsData?.getPaginatedSocialPosts.pageInfo.hasNextPage;

  const fetchMorePostSuggestions = useCallback(() => {
    if (loading || !hasNextPage || !brandId) return;

    fetchMore({
      variables: {
        ...variables,
        after: socialPostsData?.getPaginatedSocialPosts.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          ...fetchMoreResult,
          getPaginatedSocialPosts: {
            ...prev.getPaginatedSocialPosts,
            data: [
              ...prev.getPaginatedSocialPosts.data,
              ...fetchMoreResult.getPaginatedSocialPosts.data,
            ],
            meta: fetchMoreResult.getPaginatedSocialPosts.meta,
            pageInfo: fetchMoreResult.getPaginatedSocialPosts.pageInfo,
          },
        };
      },
    });
    // eslint-disable-next-line
  }, [
    loading,
    hasNextPage,
    brandId,
    variables,
    socialPostsData?.getPaginatedSocialPosts.pageInfo.endCursor,
    filters,
  ]);

  return { socialPostsData, loading, refetch, fetchMorePostSuggestions };
};
