import {
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { IconCustomInstagram } from 'components/icons/components/custom/IconCustomInstagram';
import { IconCustomSparkles } from 'components/icons/components/custom/IconCustomSparkles';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import { IconCustomYoutubeShorts } from 'components/icons/components/custom/IconCustomYoutubeShorts';
import { IconOutlineSearchNormal1 } from 'components/icons/components/outline/IconOutlineSearchNormal1';
import { SocialMediaListeningCreatorProfile } from 'features/socialMediaListening/components';
import { TrackUntrackCreatorButton } from 'features/socialMediaListening/components/trackUntrackCreatorButton';
import { useCreatorsTopicTrackingForSocialListening } from 'features/socialMediaListening/hooks/useCreatorsTopicTrackingForSocialListening';
import {
  BrandFragmentUseSocialListeningOnboardingBrandFragment,
  CreatorProfileFragmentSocialMediaListeningCreatorProfileFragment,
  Platform,
  useGetSuggestedCreatorsForSocialListeningOnboardingCreatorTrackingQuery,
} from 'graphql/generated';
import { useState } from 'react';
import { theme } from 'styles/theme';
import { RenderNextButton } from '../renderNextButton/RenderNextButton';

type Props = {
  platform: Platform;
  currentTopicId: string;
  onNext: () => void;
  brand?: BrandFragmentUseSocialListeningOnboardingBrandFragment;
};

export const SocialMediaListeningOnboardingCreatorTracking = ({
  platform,
  currentTopicId,
  brand,
  onNext,
}: Props) => {
  const [searchedTerm, setSearchedTerm] = useState('');
  const [currentSearchStr, setCurrentSearchStr] = useState('');
  const brandHandle = brand?.creators.find(
    (c) => c.platform === platform,
  )?.handle;

  const { data: creatorData, actions: creatorActions } =
    useCreatorsTopicTrackingForSocialListening({
      platform,
      topicId: currentTopicId,
      searchCreator: searchedTerm,
      handle: brandHandle,
    });
  const {
    followingCreators: {
      data: followingCreators,
      loading: loadingFollowingCreators,
    },
    searchedCreators: { data: searchedCreators, loading: searchingCreators },
    trackedCreators: { data: trackedCreators },
    inProgress: { updatingCreatorPlatformIds },
  } = creatorData;
  const { handleTrackCreator, handleUntrackCreator } = creatorActions;

  const validateAndProceed = () => {
    onNext();
  };

  const { data: suggestedCreatorsData, loading: loadingSuggestedCreators } =
    useGetSuggestedCreatorsForSocialListeningOnboardingCreatorTrackingQuery({
      variables: {
        brandId: brand?.id || '',
        filters: {
          platform,
        },
      },
      skip: !brand,
    });
  const suggestedCreators = suggestedCreatorsData?.getSuggestedCreators || [];

  function handleCreatorTracking(
    platformId: string,
    handle: string,
    track: boolean,
  ) {
    if (track) {
      handleTrackCreator(platformId, currentTopicId, handle);
    } else {
      handleUntrackCreator(platformId, currentTopicId, handle);
    }
  }

  return (
    <Box
      width="60vw"
      maxHeight="80vh"
      sx={{
        overflowY: 'auto',
      }}
      minWidth={theme.spacing(150)}
      display="block"
      py={10}
      px={6}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        flexDirection="column"
        gap={4}
      >
        <Typography
          variant="body-lg"
          sx={{
            fontWeight: 600,
            width: 'fit-content',
            padding: theme.spacing(1, 3),
            bgcolor: 'rgba(250, 243, 236, 0.20)',
            color: theme.colors?.primary.parchment,
            borderRadius: theme.spacing(4),
          }}
        >
          Creator Focus
        </Typography>
        <Typography
          color={theme.colors?.primary.parchment}
          variant="body-lg"
          fontSize={theme.spacing(7)}
          fontWeight={600}
          display="flex"
          gap={2}
          alignItems="center"
        >
          Track creators on
          {platform === Platform.Tiktok && (
            <>
              <IconCustomTiktok size={28} />
              TikTok
            </>
          )}
          {platform === Platform.Instagram && (
            <>
              <IconCustomInstagram size={28} />
              Instagram
            </>
          )}
          {platform === Platform.Youtube && (
            <>
              <IconCustomYoutubeShorts size={28} />
              Youtube Shorts
            </>
          )}
        </Typography>
        <Typography
          color={theme.colors?.primary.parchment}
          variant="body-xl"
          fontWeight={500}
        >
          Track specific creators and get notified when they mention your chosen
          topics or keywords.
        </Typography>
        {loadingSuggestedCreators && (
          <Typography
            variant="body-xl"
            color={theme.colors?.primary.parchment}
            display="flex"
            alignItems="center"
            gap={4}
            my={4}
          >
            <CircularProgress
              size={16}
              sx={{
                color: theme.colors?.primary.parchment,
              }}
            />
            Loading suggested creators...
          </Typography>
        )}
        {!!suggestedCreators.length && (
          <>
            <Typography
              mt={4}
              color={theme.colors?.primary.parchment}
              variant="body-xl"
              fontWeight={600}
              display="flex"
              gap={2}
              alignItems="center"
            >
              <IconCustomSparkles size={16} />
              AI recommended
            </Typography>

            <Box
              display="flex"
              gap={5}
              justifyContent="space-between"
              flexDirection="column"
            >
              {suggestedCreators.map((creator) => (
                <Box display="flex" justifyContent="space-between" width="100%">
                  <SocialMediaListeningCreatorProfile
                    creator={creator}
                    componentProps={{
                      showDetailsOnHover: true,
                      containerSx: {
                        sx: {
                          color: theme.colors?.primary.parchment,
                          '& .nickName': {
                            color: theme.colors?.primary.parchment,
                          },
                        },
                      },
                    }}
                  />

                  <TrackUntrackCreatorButton
                    updating={updatingCreatorPlatformIds.includes(
                      creator.platformId,
                    )}
                    tracking={
                      !!trackedCreators.find(
                        (c) => c.platformId === creator.platformId,
                      )
                    }
                    onChange={(track) => {
                      handleCreatorTracking(
                        creator.platformId,
                        creator.handle,
                        track,
                      );
                    }}
                  />
                </Box>
              ))}
            </Box>
            {/* // </Box> */}

            <Box sx={{ borderBottom: '1px solid rgba(250, 243, 236, 0.10)' }} />
          </>
        )}
        <TextField
          placeholder="Search"
          value={currentSearchStr}
          disabled={false}
          InputProps={{
            startAdornment: (
              <InputAdornment
                sx={{ color: theme.colors?.primary.parchment }}
                position="start"
              >
                <IconOutlineSearchNormal1 size={20} />
              </InputAdornment>
            ),
            endAdornment: currentSearchStr &&
              currentSearchStr !== searchedTerm && (
                <InputAdornment
                  sx={{ color: theme.colors?.primary.parchment }}
                  position="end"
                >
                  <Typography
                    variant="body-lg"
                    color={theme.colors?.primary.parchment}
                  >
                    <Box
                      component="span"
                      p={theme.spacing(0.5, 1)}
                      sx={{
                        backgroundColor: 'rgba(250, 243, 236, 0.15)',
                        mx: 1,
                        border: `2px solid ${theme.colors?.utility[500]}`,
                        borderRadius: theme.spacing(2),
                        fontWeight: 600,
                        fontSize: theme.spacing(3),
                      }}
                    >
                      ⏎
                    </Box>{' '}
                    to search
                  </Typography>
                </InputAdornment>
              ),
          }}
          onChange={(e) => {
            setCurrentSearchStr(e.target.value);
            if (!e.target.value) {
              setSearchedTerm('');
            }
          }}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.stopPropagation();

              setSearchedTerm(currentSearchStr);
            }
          }}
          sx={{
            '.MuiOutlinedInput-root': {
              borderRadius: theme.spacing(10),
              py: `${theme.spacing(3)} !important`,
              px: `${theme.spacing(6)} !important`,
              bgcolor: 'rgba(255, 255, 255, 0.10)',
              color: theme.colors?.primary.parchment,

              '& .Mui-disabled': {
                backgroundColor: 'transparent',
                color: theme.colors?.primary.parchment,
              },

              input: {
                ...theme.typography['headline-lg'],
                p: '0 !important',
              },

              '.MuiOutlinedInput-notchedOutline': {
                display: 'none !important',
              },
            },
          }}
        />
        {searchingCreators && (
          <Box>
            <Typography
              variant="body-xl"
              color={theme.colors?.primary.parchment}
              display="flex"
              alignItems="center"
              gap={4}
              my={4}
            >
              <CircularProgress
                size={16}
                sx={{
                  color: theme.colors?.primary.parchment,
                }}
              />
              Searching creators...
            </Typography>
          </Box>
        )}
        {loadingFollowingCreators && (
          <Box>
            <Typography
              variant="body-xl"
              color={theme.colors?.primary.parchment}
              display="flex"
              alignItems="center"
              gap={4}
              my={4}
            >
              <CircularProgress
                size={16}
                sx={{
                  color: theme.colors?.primary.parchment,
                }}
              />
              Loading following creators...
            </Typography>
          </Box>
        )}
        {!searchingCreators &&
          (searchedCreators.length ? searchedCreators : followingCreators).map(
            (creator) => (
              <Box display="flex" justifyContent="space-between" width="100%">
                <SocialMediaListeningCreatorProfile
                  creator={
                    creator as unknown as CreatorProfileFragmentSocialMediaListeningCreatorProfileFragment
                  }
                  componentProps={{
                    containerSx: {
                      sx: {
                        color: theme.colors?.primary.parchment,
                        '& .nickName': {
                          color: theme.colors?.primary.parchment,
                        },
                      },
                    },
                  }}
                />

                <TrackUntrackCreatorButton
                  updating={updatingCreatorPlatformIds.includes(
                    creator.platformId,
                  )}
                  tracking={
                    !!trackedCreators.find(
                      (c) => c.platformId === creator.platformId,
                    )
                  }
                  onChange={(track) => {
                    handleCreatorTracking(
                      creator.platformId,
                      creator.handle,
                      track,
                    );
                  }}
                />
              </Box>
            ),
          )}
        <RenderNextButton onNextClick={validateAndProceed} />
      </Box>
    </Box>
  );
};
