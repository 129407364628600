import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import {
  useBrandDataForSocialListeningOnboarding,
  useTopicDataForSocialListeningOnboarding,
} from 'features/socialMediaListening/hooks/onboarding';
import {
  Platform,
  PlotFeature,
  useSubmitInitialTopicParsingForSocialMediaListeningOnboardingCreatorTrackingViewMutation,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { theme } from 'styles/theme';
import { SocialMediaListeningOnboardingAddAnotherTopic } from './sections/addAnotherTopic';
import { SocialMediaListeningOnboardingCommunicationStrategy } from './sections/communicationStrategy';
import { SocialMediaListeningOnboardingCreatorTracking } from './sections/creatorTracking';
import { SocialMediaListeningOnboardingDotIndicator } from './sections/dotIndicator';
import { SocialMediaListeningOnboardingCompleted } from './sections/onboardingCompleted';
import { SocialMediaListeningOnboardingPostHistoryPreference } from './sections/postHistoryPreference';
import { SocialMediaListeningOnboardingPostPriorityInfo } from './sections/postPriorityInfo';
import { SocialMediaListeningOnboardingTopicDescription } from './sections/topicDescription';
import { SocialMediaListeningOnboardingTopicName } from './sections/topicName';
import { OnboardingStates } from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation SubmitInitialTopicParsingForSocialMediaListeningOnboardingCreatorTrackingView(
    $daysCount: Int!
    $topicId: String!
  ) {
    submitCreatorTrackingTopicParsing(
      daysCount: $daysCount
      topicId: $topicId
    ) {
      message
      success
    }
  }
`;

export const SocialMediaListeningCreatorTrackingView = () => {
  const { brandId = '' } = useParams();
  const navigate = useNavigate();

  const [submitInitialTopicParsing, { loading: submittingTopicData }] =
    useSubmitInitialTopicParsingForSocialMediaListeningOnboardingCreatorTrackingViewMutation();

  const { isFeatureEnabled } = useFeatureFlagContext();
  const creatorTrackingEnabled = isFeatureEnabled(PlotFeature.CreatorTracking);
  const youtubeParsingEnabled = isFeatureEnabled(PlotFeature.YoutubeParsing);

  const [createdTopicId, setCreatedTopicId] = useState('');
  const [prioritizeInfo, setPrioritizeInfo] = useState('');
  const [communicationStrategy, setCommunicationStrategy] = useState('');
  const [topicDescription, setTopicDescription] = useState('');

  const { brand } = useBrandDataForSocialListeningOnboarding({ brandId });
  const {
    queries: topicQueries,
    topicActions: { handleAddCreatorTrackingTopic, saving: savingTopicData },
  } = useTopicDataForSocialListeningOnboarding({
    topics: brand?.topics || [],
  });

  const { getAIGeneratedTopicPrompt } = topicQueries;

  const [currentState, setCurrentState] = useState<OnboardingStates>(
    OnboardingStates.AskTopicName,
  );
  const [latestOnboardingState, setLatestOnboardingState] = useState(
    OnboardingStates.AskTopicName,
  );

  const tiktokHandle = brand?.creators.find(
    (c) => c.platform === Platform.Tiktok,
  )?.handle;
  const instagramHandle = brand?.creators.find(
    (c) => c.platform === Platform.Instagram,
  )?.handle;
  const youtubeHandle = brand?.creators.find(
    (c) => c.platform === Platform.Youtube,
  )?.handle;

  const Sequence = [
    OnboardingStates.AskTopicName,
    ...(tiktokHandle ? [OnboardingStates.TikTokCreatorTracking] : []),
    ...(instagramHandle ? [OnboardingStates.InstagramCreatorTracking] : []),
    ...(youtubeParsingEnabled && youtubeHandle
      ? [OnboardingStates.YoutubeCreatorTracking]
      : []),
    OnboardingStates.AskTopicDescription,
    OnboardingStates.PostPriorityInfo,
    OnboardingStates.CommunicationStrategy,
    OnboardingStates.PostHistoryPreference,
    ...(creatorTrackingEnabled ? [OnboardingStates.AddAnotherTopic] : []),
    OnboardingStates.OnboardingComplete,
  ];

  const onNextClick = () => {
    const currentIndex = Sequence.indexOf(currentState);
    if (currentIndex === Sequence.length - 1) {
      navigate(PlotRoutes.socialListening());
    } else {
      setCurrentState(Sequence[currentIndex + 1]);
      if (Sequence.indexOf(latestOnboardingState) < currentIndex + 1) {
        setLatestOnboardingState(Sequence[currentIndex + 1]);
      }
    }
  };

  const onSubmitTopicPrompt = async () => {
    if (!createdTopicId) return;
    await handleAddCreatorTrackingTopic(
      {
        communicationStrategy,
        prioritizationInfo: prioritizeInfo,
        topicDescription,
      },
      brand?.id || '',
      createdTopicId,
    );

    onNextClick();
  };

  const handleSubmitInitialTopicParsing = async (daysCount: number) => {
    await submitInitialTopicParsing({
      variables: {
        topicId: createdTopicId,
        daysCount,
      },
    });
    onNextClick();
  };
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (
      createdTopicId &&
      currentState === OnboardingStates.AskTopicDescription
    ) {
      setLoading(true);
      getAIGeneratedTopicPrompt(createdTopicId)
        .then((d) => {
          if (!topicDescription) {
            setTopicDescription(
              d.data?.aiGeneratedTopicPrompt.relevantContent || '',
            );
          }

          if (!prioritizeInfo) {
            setPrioritizeInfo(
              d.data?.aiGeneratedTopicPrompt.priorityContent || '',
            );
          }

          if (!communicationStrategy) {
            setCommunicationStrategy(
              d.data?.aiGeneratedTopicPrompt.engagementPlan || '',
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [brand, currentState]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      height="100vh"
      width="100vw"
      sx={{
        backgroundColor: theme.colors?.utility.blueberry,
      }}
    >
      <SocialMediaListeningOnboardingDotIndicator
        latestOnboardingState={latestOnboardingState}
        onboardingSequence={Sequence}
        currentState={currentState}
        onSelectState={setCurrentState}
      />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        {currentState === OnboardingStates.AskTopicName && (
          <SocialMediaListeningOnboardingTopicName
            type="creator"
            componentProps={{
              title: 'Topic Title',
              inputPlaceholder: 'Create a title for the topic of interest.',
            }}
            currentTopicId={createdTopicId}
            updateCurrentTopicId={setCreatedTopicId}
            brandId={brand?.id || ''}
            topics={brand?.topics || []}
            onNext={onNextClick}
          />
        )}
        {currentState === OnboardingStates.AskTopicDescription && (
          <SocialMediaListeningOnboardingTopicDescription
            loading={loading}
            value={topicDescription}
            setValue={setTopicDescription}
            onNext={onNextClick}
            type="creator"
            componentProps={{
              subtitle:
                'Describe the videos you want our AI engine to monitor. Provide as much detail as possible across various content topics. ',
              inputPlaceholder:
                'Example: Find videos that have creators discussing topics such as social media management, storing ideas, content calendars, asset management, ideation, and storage.',
            }}
          />
        )}
        {currentState === OnboardingStates.TikTokCreatorTracking && (
          <SocialMediaListeningOnboardingCreatorTracking
            platform={Platform.Tiktok}
            currentTopicId={createdTopicId}
            brand={brand || undefined}
            onNext={onNextClick}
          />
        )}
        {currentState === OnboardingStates.InstagramCreatorTracking && (
          <SocialMediaListeningOnboardingCreatorTracking
            platform={Platform.Instagram}
            currentTopicId={createdTopicId}
            brand={brand || undefined}
            onNext={onNextClick}
          />
        )}
        {currentState === OnboardingStates.YoutubeCreatorTracking && (
          <SocialMediaListeningOnboardingCreatorTracking
            platform={Platform.Youtube}
            currentTopicId={createdTopicId}
            brand={brand || undefined}
            onNext={onNextClick}
          />
        )}
        {currentState === OnboardingStates.PostPriorityInfo && (
          <SocialMediaListeningOnboardingPostPriorityInfo
            value={prioritizeInfo}
            setValue={setPrioritizeInfo}
            onNext={() => {
              onNextClick();
            }}
            type="creator"
            componentProps={{
              title: 'How should we prioritize incoming posts for you? 🖋️',
              subtitle:
                'We sift through millions of videos to prioritize content based on your topic criteria. You define it and we ensure the most important videos appear first.',
              inputPlaceholder: `- Mentions Plot
- Generates significant engagement or has high potential reach`,
            }}
          />
        )}
        {currentState === OnboardingStates.CommunicationStrategy && (
          <SocialMediaListeningOnboardingCommunicationStrategy
            savingData={savingTopicData}
            type="creator"
            value={communicationStrategy}
            setValue={setCommunicationStrategy}
            onNext={() => {
              onSubmitTopicPrompt();
            }}
          />
        )}
        {currentState === OnboardingStates.PostHistoryPreference && (
          <SocialMediaListeningOnboardingPostHistoryPreference
            savingData={submittingTopicData}
            onNext={handleSubmitInitialTopicParsing}
          />
        )}
        {currentState === OnboardingStates.AddAnotherTopic && (
          <SocialMediaListeningOnboardingAddAnotherTopic
            brandId={brand?.id || ''}
            onNext={onNextClick}
            onPrev={() =>
              setCurrentState(OnboardingStates.PostHistoryPreference)
            }
          />
        )}
        {currentState === OnboardingStates.OnboardingComplete && (
          <SocialMediaListeningOnboardingCompleted />
        )}
      </Box>
    </Box>
  );
};
