import { gql } from '@apollo/client';
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconBoldPlay } from 'components/icons/components/bold/IconBoldPlay';
import { IconLinearChart2 } from 'components/icons/components/linear/IconLinearChart2';
import { IconLinearExport2 } from 'components/icons/components/linear/IconLinearExport2';
import { IconLinearHeart } from 'components/icons/components/linear/IconLinearHeart';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { IconOutlinePlay } from 'components/icons/components/outline/IconOutlinePlay';
import { getCreatorHandleWithPrefix } from 'features/creator';
import { useSocialListeningPostLinkGenerator } from 'features/socialMediaListening';
import { HoverPlayableVideo } from 'features/socialMediaListening/views/postsGrid/postCard/HoverPlayableVideo';
import { StaticImage } from 'features/socialMediaListening/views/postsGrid/postCard/StaticImage';
import { platformIconMap } from 'features/socialPost';
import {
  PaginatedBrandInboundFiltersInputForCapturedProductSortField,
  SocialPostFragmentSlaRankingProductSocialPostsFragment,
  SocialPostFragmentUseSocialListeningPostLinkGeneratorFragmentDoc,
  SocialPostType,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';

// eslint-disable-next-line
gql`
  fragment SocialPostFragmentSLARankingProductSocialPosts on SocialPostModel {
    id
    platform
    thumbnailUrl
    videoUrl
    playCount
    type
    creator {
      id
      handle
      profilePictureUrl
    }
    ...SocialPostFragmentUseSocialListeningPostLinkGenerator
  }
  ${SocialPostFragmentUseSocialListeningPostLinkGeneratorFragmentDoc}
`;

interface SLARankingProductSocialPostsProps {
  socialPosts: SocialPostFragmentSlaRankingProductSocialPostsFragment[];
  onPostClick: (postId: string) => void;
  selectedSortKey?: PaginatedBrandInboundFiltersInputForCapturedProductSortField;
  setSelectedSortKey: (
    sortKey: PaginatedBrandInboundFiltersInputForCapturedProductSortField,
  ) => void;
}

export const SLARankingProductSocialPosts = ({
  socialPosts,
  onPostClick,
  selectedSortKey,
  setSelectedSortKey,
}: SLARankingProductSocialPostsProps) => {
  const { generateLink } = useSocialListeningPostLinkGenerator();

  const sortOptions = [
    {
      label: 'Total engagement',
      value:
        PaginatedBrandInboundFiltersInputForCapturedProductSortField.TotalEngagement,
      icon: IconLinearChart2,
    },
    {
      label: 'Play count',
      value:
        PaginatedBrandInboundFiltersInputForCapturedProductSortField.TotalImpressions,
      icon: IconOutlinePlay,
    },
    {
      label: 'Like count',
      value:
        PaginatedBrandInboundFiltersInputForCapturedProductSortField.DiggCount,
      icon: IconLinearHeart,
    },
  ];

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        m={4}
        mb={2}
      >
        <Typography variant="headline-md" fontSize={20}>
          Posts
        </Typography>
        <ContextMenu
          options={sortOptions.map((option) => ({
            renderOption: () => (
              <Box display="flex" alignItems="center" gap={2}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 24,
                    height: 24,
                    borderRadius: 1,
                    background: 'rgba(35, 6, 3, 0.05)',
                  }}
                >
                  <option.icon size={16} color={theme.colors?.primary.black} />
                </Box>
                <Typography variant="subhead-lg">{option.label}</Typography>
              </Box>
            ),
            onClick: () => {
              setSelectedSortKey(option.value);
            },
          }))}
          renderButton={() => (
            <Button
              variant="tertiary"
              sx={{
                backgroundColor: theme.colors?.primary.parchment,
                borderRadius: theme.spacing(2),
                gap: 2,
                px: 2,
                '&:hover': {
                  backgroundColor: theme.colors?.primary.parchment,
                },
              }}
              disableRipple={false}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  backgroundColor: 'rgba(35, 6, 3, 0.05)',
                  p: theme.spacing(1),
                  borderRadius: theme.spacing(1),
                }}
              >
                <IconLinearSort size={16} />
              </Box>
              <Typography variant="subhead-lg">
                {selectedSortKey
                  ? sortOptions.find(
                      (option) => option.value === selectedSortKey,
                    )?.label
                  : 'Sort by'}
              </Typography>
            </Button>
          )}
        />
      </Box>
      <Box sx={{ m: 4 }}>
        {!socialPosts?.length ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography
              variant="body-xl"
              fontWeight={600}
              color={theme.colors?.utility[800]}
            >
              No posts found
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={2}>
            {socialPosts?.map((post) => {
              const Icon = platformIconMap[post.platform];

              const renderActionsTopRight = (isHovered: boolean) => (
                <Box
                  display="flex"
                  gap={2}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  {isHovered && post.creator?.handle && (
                    <IconButton
                      disableRipple
                      sx={{
                        p: 1,
                        backgroundColor: 'rgba(35, 6, 3, 0.10)',
                        color: theme.colors?.primary.white,
                        backdropFilter: 'blur(4px)',
                      }}
                      onClick={() => {
                        window.open(generateLink(post) || '', '_blank');
                      }}
                    >
                      <IconLinearExport2 size={16} />
                    </IconButton>
                  )}
                  {Icon && (
                    <Icon
                      size={16}
                      style={{ color: theme.colors?.primary.white }}
                    />
                  )}
                </Box>
              );

              const renderActionsBottom = () => (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <Box
                    display="flex"
                    gap={1}
                    alignItems="center"
                    ml={2}
                    width="70%"
                    maxWidth="70%"
                  >
                    <Avatar
                      src={post.creator?.profilePictureUrl || ''}
                      sx={{
                        width: theme.spacing(4),
                        height: theme.spacing(4),
                      }}
                    />
                    <Typography
                      color={theme.colors?.primary.parchment}
                      variant="body-lg"
                      fontWeight={600}
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {getCreatorHandleWithPrefix(post.creator?.handle ?? '')}
                    </Typography>
                  </Box>

                  {post.type === SocialPostType.Video && (
                    <Box mr={2} display="flex" gap={1} alignItems="center">
                      <IconBoldPlay
                        size={16}
                        color={theme.colors?.primary.parchment}
                      />
                      <Typography
                        color={theme.colors?.primary.parchment}
                        variant="body-lg"
                        fontWeight={600}
                      >
                        {formatBigNumber(post.playCount || 0)}
                      </Typography>
                    </Box>
                  )}
                </Box>
              );

              return (
                <Grid item xs={12} sm={6} md={3} lg={2.4} key={post.id}>
                  <Box onClick={() => onPostClick(post.id)}>
                    {post.type === SocialPostType.Video ? (
                      <HoverPlayableVideo
                        thumbnailUrl={post.thumbnailUrl || ''}
                        videoUrl={post.videoUrl || ''}
                        renderActionsTopRight={renderActionsTopRight}
                        renderActionsBottom={renderActionsBottom}
                      />
                    ) : (
                      <StaticImage
                        thumbnailUrl={post.thumbnailUrl || ''}
                        renderActionsTopRight={renderActionsTopRight}
                        renderActionsBottom={renderActionsBottom}
                      />
                    )}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
    </>
  );
};
