import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useUserContext } from 'contexts/users/User.context';
import { useBrandDataForSocialListeningOnboarding } from 'features/socialMediaListening/hooks/onboarding';
import {
  CreateCompetitorDataInput,
  CreatorFragmentSocialMediaListeningOnboardingSocialHandleFormFragment,
  Platform,
  PlotFeature,
  useCreateCompetitorsForSocialMediaListeningCompetitorsOnboardingViewMutation,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { theme } from 'styles/theme';
import { SocialMediaListeningOnboardingAddCompetitors } from './sections/addCompetitors';
import { SocialMediaListeningOnboardingCompetitorsDetails } from './sections/competitorsDetails';
import { SocialMediaListeningOnboardingDotIndicator } from './sections/dotIndicator';
import { SocialMediaListeningOnboardingCompetitorsIntro } from './sections/intro';
import { SocialMediaListeningOnboardingCompleted } from './sections/onboardingCompleted';
import { OnboardingStates } from './types';

export type CompetitorDetailType = {
  name: string;
  instagramCreator?: CreatorFragmentSocialMediaListeningOnboardingSocialHandleFormFragment;
  tiktokCreator?: CreatorFragmentSocialMediaListeningOnboardingSocialHandleFormFragment;
  youtubeCreator?: CreatorFragmentSocialMediaListeningOnboardingSocialHandleFormFragment;
  selectedCountries: string[];
  aboutBrand: string;
  aiKeywords: string[];
  keywords: string[];
  allowPlotToUpdateKeywords: boolean;
};

export const DefaultCompetitorDetail: CompetitorDetailType = {
  name: '',
  selectedCountries: [],
  aboutBrand: '',
  aiKeywords: [],
  keywords: [],
  allowPlotToUpdateKeywords: false,
};

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation CreateCompetitorsForSocialMediaListeningCompetitorsOnboardingView(
    $data: CreateCompetitorInput!
  ) {
    createCompetitors(data: $data) {
      id
    }
  }
`;

export const SocialMediaListeningCompetitorsOnboardingView = () => {
  const { brandId = '' } = useParams();
  const navigate = useNavigate();
  const { user } = useUserContext();

  const [competitorsData, setCompetitorsData] = useState(
    [] as CompetitorDetailType[],
  );

  const { brand } = useBrandDataForSocialListeningOnboarding({ brandId });
  const [createCompetitors, { loading: savingCompetitors }] =
    useCreateCompetitorsForSocialMediaListeningCompetitorsOnboardingViewMutation();

  const { isFeatureEnabled, loading } = useFeatureFlagContext();

  useEffect(() => {
    if (!loading) {
      const _isFeatureEnabled = isFeatureEnabled(
        PlotFeature.CompetitorAnalysis,
      );
      if (
        !_isFeatureEnabled &&
        !user?.socialListeningBrands.find((b) => b.id === brandId)
      ) {
        navigate(PlotRoutes.home());
      }
    }
  }, [isFeatureEnabled, loading]); // eslint-disable-line

  const Sequence = [
    OnboardingStates.CompetitorsIntro,
    OnboardingStates.AddCompetitors,
    OnboardingStates.CompetitorsDetails,
    OnboardingStates.OnboardingComplete,
  ];

  const [currentState, setCurrentState] = useState<OnboardingStates>(
    Sequence[0],
  );
  const [latestOnboardingState, setLatestOnboardingState] = useState(
    Sequence[0],
  );

  const onNextClick = () => {
    const currentIndex = Sequence.indexOf(currentState);
    if (currentIndex === Sequence.length - 1) {
      navigate(PlotRoutes.socialListening());
    } else {
      setCurrentState(Sequence[currentIndex + 1]);
      if (Sequence.indexOf(latestOnboardingState) < currentIndex + 1) {
        setLatestOnboardingState(Sequence[currentIndex + 1]);
      }
    }
  };

  const onSubmit = async () => {
    if (!brand?.id) return;

    await createCompetitors({
      variables: {
        data: {
          organizationBrandId: brand.id,
          data: competitorsData.map(
            (competitor) =>
              ({
                name: competitor.name,
                description: competitor.aboutBrand,
                isPaused: competitor.allowPlotToUpdateKeywords,
                regions: competitor.selectedCountries,
                searchTerms: [
                  ...competitor.aiKeywords,
                  ...competitor.keywords,
                ].map((keyword) => ({
                  term: keyword,
                })),
                allowAppCreatorToUpdateSearchTermList:
                  competitor.allowPlotToUpdateKeywords,
                creators: [
                  ...(competitor.instagramCreator
                    ? [
                        {
                          platform: Platform.Instagram,
                          handle: competitor.instagramCreator.handle,
                        },
                      ]
                    : []),
                  ...(competitor.tiktokCreator
                    ? [
                        {
                          platform: Platform.Tiktok,
                          handle: competitor.tiktokCreator.handle,
                        },
                      ]
                    : []),
                  ...(competitor.youtubeCreator
                    ? [
                        {
                          platform: Platform.Youtube,
                          handle: competitor.youtubeCreator.handle,
                        },
                      ]
                    : []),
                ],
              } as CreateCompetitorDataInput),
          ),
        },
      },
    });

    onNextClick();
  };

  return (
    <Box
      height="100vh"
      width="100vw"
      sx={{
        backgroundColor: theme.colors?.utility.blueberry,
      }}
    >
      <SocialMediaListeningOnboardingDotIndicator
        latestOnboardingState={latestOnboardingState}
        onboardingSequence={Sequence}
        currentState={currentState}
        onSelectState={setCurrentState}
      />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        {currentState === OnboardingStates.CompetitorsIntro && (
          <SocialMediaListeningOnboardingCompetitorsIntro
            onContinue={() => onNextClick()}
          />
        )}
        {currentState === OnboardingStates.AddCompetitors && (
          <SocialMediaListeningOnboardingAddCompetitors
            competitors={competitorsData}
            updateCompetitors={setCompetitorsData}
            onNext={() => onNextClick()}
            onPrev={() => setCurrentState(OnboardingStates.CompetitorsIntro)}
          />
        )}
        {currentState === OnboardingStates.CompetitorsDetails && (
          <SocialMediaListeningOnboardingCompetitorsDetails
            brandId={brand?.id || ''}
            saving={savingCompetitors}
            competitorsData={competitorsData}
            updateCompetitorsData={setCompetitorsData}
            onNext={() => {
              onSubmit();
            }}
            onPrev={() => {
              setCurrentState(OnboardingStates.AddCompetitors);
            }}
          />
        )}
        {currentState === OnboardingStates.OnboardingComplete && (
          <SocialMediaListeningOnboardingCompleted />
        )}
      </Box>
    </Box>
  );
};
