import { gql } from '@apollo/client';
import { Avatar, Box, SxProps, Typography } from '@mui/material';
import { getCreatorHandleWithPrefix } from 'features/creator/utils';
import { CreatorFragmentCreatorAvatarWithNameViewFragment } from 'graphql/generated';
import { theme } from 'styles/theme';

export const CREATOR_FRAGMENT_CREATOR_AVATAR_WITH_NAME_VIEW = gql`
  fragment CreatorFragmentCreatorAvatarWithNameView on CreatorModel {
    id
    nickName
    handle
    platform
    profilePictureUrl
  }
`;

export type CreatorAvatarWithNameViewProps = {
  creator: CreatorFragmentCreatorAvatarWithNameViewFragment;
  sx?: SxProps;
  componentsProps?: {
    avatar?: {
      sx?: SxProps;
    };
    nickName?: {
      sx?: SxProps;
    };
    handle?: {
      sx?: SxProps;
    };
  };
};

export const CreatorAvatarWithNameView = (
  props: CreatorAvatarWithNameViewProps,
) => {
  const { creator, sx, componentsProps = {} } = props;

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={3}
      sx={{
        cursor: 'pointer',
        p: 6,
        backgroundColor: 'rgba(255, 255, 255, 0.10)',
        borderRadius: theme.spacing(4),
        ...sx,
      }}
    >
      <Avatar
        src={creator.profilePictureUrl || ''}
        sx={{
          width: theme.spacing(20),
          height: theme.spacing(20),
          ...componentsProps.avatar?.sx,
        }}
      />
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography
          variant="headline-lg"
          sx={{
            fontWeight: 600,
            fontSize: 28,
            lineHeight: 1,
            ...componentsProps.nickName?.sx,
          }}
        >
          {creator.nickName || getCreatorHandleWithPrefix(creator.handle)}
        </Typography>
        <Typography
          variant="headline-lg"
          sx={{
            fontWeight: 500,
            fontSize: 20,
            lineHeight: 1,
            ...componentsProps.handle?.sx,
          }}
        >
          {getCreatorHandleWithPrefix(creator.handle)}
        </Typography>
      </Box>
    </Box>
  );
};
