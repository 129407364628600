import { Box, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { useCustomHeaderContext } from 'components/layouts/CustomHeader/contexts/CustomHeader.context';
import { SocialMediaListeningBrandOnboardingView } from 'features/socialMediaListening/views/onboarding';
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { theme } from 'styles/theme';

export type InternalNavRefProps = {
  onPrevClick: () => void;
  hasPrevStep: boolean;
};

export const SocialMediaListeningOnboardingBrandPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { setRenderTitle, setHeaderSx } = useCustomHeaderContext();

  const internalNavRef = useRef<InternalNavRefProps | null>(null);

  useEffect(() => {
    setHeaderSx({
      borderBottom: `1px solid ${theme.colors?.primary.parchment}`,
      color: theme.colors?.primary.parchment,
      bgcolor: theme.colors?.utility.blueberry,
      '& svg': {
        color: theme.colors?.primary.parchment,
      },
    });

    setRenderTitle(() => (
      <Box
        color={theme.colors?.primary.parchment}
        component="button"
        onClick={() => {
          if (internalNavRef.current?.hasPrevStep) {
            internalNavRef.current?.onPrevClick();
          } else {
            navigate(PlotRoutes.home());
          }
        }}
        display="flex"
        alignItems="center"
        gap={4}
        sx={{
          cursor: 'pointer',
          textAlign: 'left',
          overflow: 'hidden',
          flex: 1,
        }}
      >
        <IconOutlineArrowLeft
          size={24}
          color={theme.colors?.primary.black}
          style={{ flexShrink: 0 }}
        />
        <Typography
          variant="headline-sm"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          📣 Social Listening
        </Typography>
      </Box>
    ));

    return () => {
      setHeaderSx({});
    };
  }, [location.pathname]); // eslint-disable-line

  return <SocialMediaListeningBrandOnboardingView ref={internalNavRef} />;
};
