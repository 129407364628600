import { gql } from '@apollo/client';
import { Box, Input, Typography } from '@mui/material';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import { IconLinearLanguageCircle } from 'components/icons/components/linear/IconLinearLanguageCircle';
import { useGetLanguagesForSlaAnalyticsFilterByLanguageQuery } from 'graphql/generated';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetLanguagesForSLAAnalyticsFilterByLanguage {
    languages {
      name
    }
  }
`;

interface SLAAnalyticsFilterByLanguageProps {
  selectedLanguages: string[];
  variant?: 'normal' | 'accordion';
  renderTitle?: () => React.ReactNode;
  onChange: (values: { selectedLanguages: string[] }) => void;
}

export const SLAAnalyticsFilterByLanguage = ({
  selectedLanguages = [],
  variant = 'accordion',
  renderTitle,
  onChange,
}: SLAAnalyticsFilterByLanguageProps) => {
  const { data } = useGetLanguagesForSlaAnalyticsFilterByLanguageQuery();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const dropdownOptions = useMemo(() => {
    const languages = data?.languages ?? [];

    return languages.map((language) => ({
      label: language.name,
      value: language.name,
      onClick: () => {
        onChange({
          selectedLanguages: selectedLanguages.includes(language.name)
            ? selectedLanguages.filter((o) => o !== language.name)
            : [...selectedLanguages, language.name],
        });
      },
      isChecked: selectedLanguages.includes(language.name),
    }));
  }, [data, selectedLanguages, onChange]);

  const [optionsSearch, setOptionsSearch] = useState('');
  const filteredOptions = useMemo(() => {
    if (!optionsSearch) {
      return dropdownOptions;
    }

    const searchRegex = new RegExp(optionsSearch, 'gi');
    return dropdownOptions.filter((option) =>
      searchRegex.test(option.label?.toString() ?? ''),
    );
  }, [optionsSearch, dropdownOptions]);

  const renderLabel = useMemo(() => {
    if (renderTitle) {
      return renderTitle();
    }

    return (
      <Box
        sx={{
          display: 'flex',
          gap: theme.spacing(2),
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(35, 6, 3, 0.05)',
            borderRadius: theme.spacing(1),
            padding: theme.spacing(1),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconLinearLanguageCircle
            size={16}
            style={{
              color: theme.colors?.primary.black,
            }}
          />
        </Box>

        <Typography variant="subhead-lg">Languages</Typography>
      </Box>
    );
  }, [renderTitle]);

  return (
    <DropDownMoreMenuItem
      disableRipple
      label={renderLabel}
      componentsProps={{
        dropDownOptions: {
          container: {
            sx: {
              maxHeight: 300,
              overflowX: 'auto',
              '&::-webkit-scrollbar': {
                width: 0,
              },
              li: {
                flexShrink: 0,
              },
            },
          },
        },
      }}
      renderDropDownOptionSearchInput={() => (
        <Input
          disableUnderline
          autoFocus
          placeholder="Search"
          onKeyDown={(e) => e.stopPropagation()}
          onChange={(e) => {
            setOptionsSearch(e.currentTarget.value);
          }}
          fullWidth
          sx={{
            mb: 2,
            p: theme.spacing(0.5, 3),
            ...theme.typography['subhead-lg'],
            backgroundColor: theme.colors?.utility[275],
            borderRadius: 25,
          }}
        />
      )}
      sx={{
        ...(variant === 'normal' && {
          pointerEvents: 'none',
        }),
        svg: {
          color: theme.colors?.primary.black,
          ...(variant === 'normal' && {
            display: 'none',
          }),
        },
      }}
      dropDownOptions={filteredOptions}
      defaultOpen={variant === 'normal'}
    />
  );
};
