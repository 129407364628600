import { Box, Switch, Typography } from '@mui/material';
import { SocialMediaListeningKeywords } from 'features/socialMediaListening/components';
import { useBrandSearchTermsForSocialListeningOnboarding } from 'features/socialMediaListening/hooks';
import { TopicFragmentForUseTopicDataForSocialListeningOnboardingFragment } from 'graphql/generated';
import { useState } from 'react';
import { theme } from 'styles/theme';
import { RenderNextButton } from '../renderNextButton/RenderNextButton';

type SocialMediaListeningOnboardingKeywordsProps = {
  topic?: TopicFragmentForUseTopicDataForSocialListeningOnboardingFragment;
  onNext: () => void;
};

export const SocialMediaListeningOnboardingKeywords = ({
  topic,
  onNext,
}: SocialMediaListeningOnboardingKeywordsProps) => {
  const existingKeywords = topic?.searchTerms.map((term) => term.term) || [];

  const [manuallyAddedKeywords, setManuallyAddedKeywords] =
    useState<string[]>(existingKeywords);
  const [allowingPlotToImprove, setAllowingPlotToImprove] = useState(
    !!topic?.allowToUpdateSearchTerm,
  );

  const { searchTermActions } =
    useBrandSearchTermsForSocialListeningOnboarding();
  const { handleCreateSearchTerms } = searchTermActions;

  const handleCreateSearchTermsForTopic = async () => {
    if (!topic?.id) return;
    await handleCreateSearchTerms(
      topic.id,
      manuallyAddedKeywords,
      allowingPlotToImprove,
    );
    onNext();
  };

  return (
    <Box
      width="60vw"
      maxHeight="65vh"
      display="flex"
      gap={4}
      flexDirection="column"
      color={theme.colors?.primary.parchment}
    >
      <Typography
        variant="headline-lg"
        fontSize={theme.spacing(7)}
        fontWeight={600}
      >
        Keywords. 🔎
      </Typography>
      <Typography variant="body-xl" fontWeight={500} mt={-2}>
        We've generated relevant keywords for your brand and industry—customize
        as needed.
      </Typography>

      <Box mt={4}>
        <SocialMediaListeningKeywords
          componentProps={{
            container: {
              sx: { my: 0, alignItems: 'center' },
            },
            popover: {
              itemContainer: {
                bottomScrollGradient:
                  'linear-gradient(180deg, rgba(226, 229, 232, 0.00) 25.71%, #E2E5E8 95%)',
              },
            },
            createKeyword: {
              position: 'pre',
              allowKeywordCreationAfterAdd: true,
              sx: {
                mt: 0,
              },
            },
          }}
          keywords={manuallyAddedKeywords}
          updateKeywords={(keywords) => {
            setManuallyAddedKeywords(keywords);
          }}
          showHelperText
        />
      </Box>
      <Box sx={{ borderTop: `2px solid rgba(250, 243, 236, 0.10)` }} my={4} />

      <Box display="flex" justifyContent="space-between">
        <Typography variant="body-xl" fontWeight={500}>
          Allow Plot to update this list to improve results
        </Typography>
        <Switch
          checked={allowingPlotToImprove}
          onChange={() => setAllowingPlotToImprove((prev) => !prev)}
          sx={{
            '& .MuiSwitch-thumb': {
              backgroundColor: allowingPlotToImprove
                ? theme.colors?.primary.black
                : theme.colors?.primary.parchment,
            },
            '& .MuiSwitch-track': {
              backgroundColor: `${theme.colors?.utility[500]} !important`,
            },
          }}
        />
      </Box>
      <RenderNextButton onNextClick={handleCreateSearchTermsForTopic} />
    </Box>
  );
};
