import { gql } from '@apollo/client';
import { Avatar, Box, Grid, IconButton, Typography } from '@mui/material';
import { IconBoldPlay } from 'components/icons/components/bold/IconBoldPlay';
import { IconLinearExport2 } from 'components/icons/components/linear/IconLinearExport2';
import { getCreatorHandleWithPrefix } from 'features/creator';
import { useSocialListeningPostLinkGenerator } from 'features/socialMediaListening/hooks/useSocialListeningPostLinkGenerator';
import { HoverPlayableVideo } from 'features/socialMediaListening/views/postsGrid/postCard/HoverPlayableVideo';
import { StaticImage } from 'features/socialMediaListening/views/postsGrid/postCard/StaticImage';
import { platformIconMap } from 'features/socialPost';
import {
  SocialPostFragmentSlaInfinitePostsGridFragment,
  SocialPostFragmentUseSocialListeningPostLinkGeneratorFragmentDoc,
  SocialPostType,
} from 'graphql/generated';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment SocialPostFragmentSLAInfinitePostsGrid on SocialPostModel {
    id
    platform
    thumbnailUrl
    videoUrl
    playCount
    type
    creator {
      id
      handle
      profilePictureUrl
    }
    ...SocialPostFragmentUseSocialListeningPostLinkGenerator
  }
  ${SocialPostFragmentUseSocialListeningPostLinkGeneratorFragmentDoc}
`;

type Props = {
  brandId: string;
  socialPosts: SocialPostFragmentSlaInfinitePostsGridFragment[];
  hasMore: boolean;
  loadMore: () => void;
};

export const SLAInfinitePostsGrid = ({
  brandId,
  loadMore,
  hasMore,
  socialPosts,
}: Props) => {
  const { generateLink } = useSocialListeningPostLinkGenerator();

  return (
    <InfiniteScroll
      loadMore={loadMore}
      hasMore={hasMore}
      threshold={200}
      useWindow={false}
    >
      <Grid container spacing={4} columns={20}>
        {socialPosts.map((post, index) => {
          const Icon = platformIconMap[post.platform];

          const renderActionsTopRight = (isHovered: boolean) => (
            <Box
              display="flex"
              gap={2}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {isHovered && post.creator?.handle && (
                <IconButton
                  disableRipple
                  sx={{
                    p: 1,
                    backgroundColor: 'rgba(35, 6, 3, 0.10)',
                    color: theme.colors?.primary.white,
                    backdropFilter: 'blur(4px)',
                  }}
                  onClick={() => {
                    window.open(generateLink(post) || '', '_blank');
                  }}
                >
                  <IconLinearExport2 size={16} />
                </IconButton>
              )}
              {Icon && (
                <Icon
                  size={16}
                  style={{ color: theme.colors?.primary.white }}
                />
              )}
            </Box>
          );

          const renderActionsBottom = () => (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Box
                display="flex"
                gap={0}
                alignItems="center"
                ml={2}
                width="50%"
                maxWidth="50%"
              >
                <Avatar
                  src={post.creator?.profilePictureUrl || ''}
                  sx={{
                    width: theme.spacing(4),
                    height: theme.spacing(4),
                  }}
                />
                <Typography
                  color={theme.colors?.primary.parchment}
                  variant="body-lg"
                  fontWeight={600}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {getCreatorHandleWithPrefix(post.creator?.handle ?? '')}
                </Typography>
              </Box>

              {post.type === SocialPostType.Video && (
                <Box mr={2} display="flex" gap={1} alignItems="center">
                  <IconBoldPlay
                    size={16}
                    color={theme.colors?.primary.parchment}
                  />
                  <Typography
                    color={theme.colors?.primary.parchment}
                    variant="body-lg"
                    fontWeight={600}
                  >
                    {formatBigNumber(post.playCount || 0)}
                  </Typography>
                </Box>
              )}
            </Box>
          );

          return (
            <Grid
              item
              xs={10}
              md={5}
              lg={4}
              key={post.id + index}
              component={Link}
              to={PlotRoutes.socialListeningPost(post.id, brandId)}
            >
              {post.type === SocialPostType.Video ||
              (post.type === SocialPostType.Carousel && !!post.videoUrl) ? (
                <HoverPlayableVideo
                  thumbnailUrl={post.thumbnailUrl || ''}
                  videoUrl={post.videoUrl || ''}
                  renderActionsTopRight={renderActionsTopRight}
                  renderActionsBottom={renderActionsBottom}
                />
              ) : (
                <StaticImage
                  thumbnailUrl={post.thumbnailUrl || ''}
                  renderActionsTopRight={renderActionsTopRight}
                  renderActionsBottom={renderActionsBottom}
                />
              )}
            </Grid>
          );
        })}
      </Grid>
    </InfiniteScroll>
  );
};
