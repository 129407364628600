import { gql } from '@apollo/client';
import { SLACreatorDemographics } from 'features/socialListeningAnalytics/components';
import {
  CompetitorFlatAnalyticFiltersInput,
  SocialPostCreatorDemographicFragmentSlaCreatorDemographicsFragmentDoc,
  SocialPostGenderDemographic,
  SocialPostGenerationDemographic,
  useGetCompetitorFlatCreatorDemographicsForCompetitorCreatorDemographicsQuery,
} from 'graphql/generated';

// eslint-disable-next-line
gql`
  query GetCompetitorFlatCreatorDemographicsForCompetitorCreatorDemographics(
    $data: CompetitorFlatAnalyticFiltersInput!
  ) {
    getCompetitorFlatCreatorDemographics(data: $data) {
      ...SocialPostCreatorDemographicFragmentSLACreatorDemographics
    }
  }
  ${SocialPostCreatorDemographicFragmentSlaCreatorDemographicsFragmentDoc}
`;

interface Props {
  filters: CompetitorFlatAnalyticFiltersInput;
  onChange: (data: {
    selectedGender: SocialPostGenderDemographic[];
    selectedGeneration: SocialPostGenerationDemographic[];
  }) => void;
}

export const CompetitorCreatorDemographics = ({ filters, onChange }: Props) => {
  const { data } =
    useGetCompetitorFlatCreatorDemographicsForCompetitorCreatorDemographicsQuery(
      {
        variables: {
          data: filters,
        },
        skip: !filters.generation && !filters.gender,
        fetchPolicy: 'cache-and-network',
      },
    );

  const creatorDemographics = data?.getCompetitorFlatCreatorDemographics || [];

  return (
    <SLACreatorDemographics
      creatorDemographics={creatorDemographics}
      selectedGender={filters.gender || []}
      selectedGeneration={filters.generation || []}
      onChange={onChange}
    />
  );
};
