import { Box } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { SiteHeader } from 'components/layouts/SiteHeader';
import { useUserContext } from 'contexts/users/User.context';
import { BillingBannerView } from 'features/billing';
import { InternalOrganizationRole } from 'graphql/generated';
import { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { theme } from 'styles/theme';
import { CustomHeader } from './CustomHeader';
import { LeftDrawer } from './LeftDrawer';
import { DRAWER_WIDTH } from './LeftDrawer/LeftDrawer';
import { useLeftDrawer } from './LeftDrawer/hooks/useLeftDrawer';
import { StartOptionsGroupWrapper } from './styles';

const HIDE_HEADER_ROUTES = [
  PlotRoutes.registerIdeas(),
  '/onboarding',
  '/verify',
  '/onboarding/creative-juicebox',
  PlotRoutes.accessRequest(),
];

const HIDE_BANNER_ROUTES = [
  ...HIDE_HEADER_ROUTES,
  PlotRoutes.billingCheckout(),
];

export const PrivateRoute = () => {
  const location = useLocation();
  const { orgBilling, isMobileAppWebView, user } = useUserContext();

  const navigate = useNavigate();

  // TODO: Use plot routes instead of hardcoding
  const ROUTES_WITH_CUSTOM_HEADER = [
    '/juicebox',
    '/collections',
    '/inbox',
    '/ai-organize',
    '/tasks',
    PlotRoutes.socialListening(),
    PlotRoutes.contentCalendar(),
    PlotRoutes.contentIdea(''),
  ];

  // Check if we should use custom header
  // Logic is a bit trouble-some because we also need to cater for notifications page which
  // can be rendered in the 3rd layer from juice detail modal.
  const useCustomHeader = useMemo(() => {
    return (
      ROUTES_WITH_CUSTOM_HEADER.some(
        (route) =>
          location.pathname.includes(route) ||
          //  @ts-ignore
          location.state?.backgroundLocation?.pathname?.includes(route) ||
          //  @ts-ignore
          location.state?.secondaryLocation?.pathname?.includes(route),
      ) && !isMobileAppWebView
    );
  }, [location, isMobileAppWebView]); // eslint-disable-line

  const isShowNoteView = location.pathname.includes('/juicebox/note/');
  const isShowBillingPageView = location.pathname.includes(
    PlotRoutes.billing(),
  );

  const isShowNoteViewInMobileWebView = isShowNoteView && isMobileAppWebView;
  const isShowBillingPageInMobileWebView =
    isShowBillingPageView && isMobileAppWebView;

  const hideSiteHeader =
    ((HIDE_HEADER_ROUTES.some(
      (route) =>
        location.pathname.includes(route) &&
        !location.pathname.endsWith('trending'),
    ) ||
      // @ts-ignore
      Boolean(location.state?.secondaryLocation)) &&
      !useCustomHeader) ||
    isShowNoteViewInMobileWebView ||
    isShowBillingPageInMobileWebView;

  const hideSiteBanner =
    HIDE_BANNER_ROUTES.some(
      (route) =>
        location.pathname.includes(route) &&
        !location.pathname.endsWith('trending'),
    ) || hideSiteHeader;

  const {
    tabs,
    topTabs,
    isPersistentDrawer,
    isOpenDrawer,
    onToggleDrawer,
    onCloseDrawer,
  } = useLeftDrawer();

  // Redirect to access request page if user is not approved by admin
  useEffect(() => {
    if (
      user &&
      !user.hasBeenApprovedByAdmin &&
      user.role !== InternalOrganizationRole.Admin
    ) {
      navigate(PlotRoutes.accessRequest());
    }
  }, [user]); // eslint-disable-line

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      {!hideSiteHeader && (
        <>
          {useCustomHeader ? (
            <CustomHeader
              onToggleDrawer={onToggleDrawer}
              iconColor={
                location.pathname.includes(PlotRoutes.juiceboxTrending()) &&
                !orgBilling?.trendsEnabled
                  ? theme.colors?.primary.white
                  : theme.colors?.primary.black
              }
              sx={{
                position: 'fixed',
                top: 0,
                ...(isPersistentDrawer && isOpenDrawer
                  ? {
                      marginLeft: DRAWER_WIDTH,
                      width: `calc(100% - ${DRAWER_WIDTH})`,
                    }
                  : {}),
                ...(location.pathname.includes(PlotRoutes.juiceboxTrending()) &&
                !orgBilling?.trendsEnabled
                  ? {
                      bgcolor: theme.colors?.utility['blueberry'],
                      color: theme.colors?.primary.white,
                    }
                  : {}),
              }}
            />
          ) : (
            <SiteHeader
              onToggleDrawer={onToggleDrawer}
              isOpenDrawer={isOpenDrawer}
              sx={{
                ...(isPersistentDrawer && isOpenDrawer
                  ? {
                      marginLeft: DRAWER_WIDTH,
                      width: `calc(100% - ${DRAWER_WIDTH})`,
                    }
                  : {}),
              }}
            />
          )}
          <StartOptionsGroupWrapper>
            <LeftDrawer
              topTabs={topTabs}
              tabs={tabs}
              isOpen={isOpenDrawer}
              onClose={onCloseDrawer}
              variant={isPersistentDrawer ? 'persistent' : 'temporary'}
            />
          </StartOptionsGroupWrapper>
        </>
      )}
      <Box
        component="main"
        id="main"
        sx={{
          zIndex: 0,
          position: 'relative',
          transition: 'all 0.2s ease-in-out',
          ...(isPersistentDrawer && isOpenDrawer
            ? {
                marginLeft: DRAWER_WIDTH,
              }
            : {
                marginLeft: 0,
              }),
        }}
      >
        {orgBilling && !isMobileAppWebView && !hideSiteBanner && (
          <Box
            sx={{ width: '100%', position: 'sticky', top: 0, zIndex: 1 }}
            id="billing-banner"
          >
            <BillingBannerView
              organizationBilling={orgBilling}
              sx={{
                zIndex: 2,
                mt: hideSiteHeader ? 0 : 16,
              }}
            />
          </Box>
        )}

        <Outlet />
      </Box>
    </Box>
  );
};
