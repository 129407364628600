import { Box, Typography } from '@mui/material';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import { ExtendedSocialPostTypeForFilter } from 'graphql/generated';
import {
  socialListeningFilterIconMap,
  SocialListeningFiltersKey,
} from 'pages/socialMediaListening/constant';
import { useMemo } from 'react';
import { theme } from 'styles/theme';

interface FilterByPostTypesProps {
  selectedTypes: ExtendedSocialPostTypeForFilter[];
  type?: 'accordion' | 'normal';
  renderTitle?: () => React.ReactNode;
  onChange: (selectedTypes: ExtendedSocialPostTypeForFilter[]) => void;
}

export const FilterByPostTypes = ({
  selectedTypes = [],
  type = 'accordion',
  renderTitle,
  onChange,
}: FilterByPostTypesProps) => {
  const Icon = socialListeningFilterIconMap[SocialListeningFiltersKey.Types];

  const renderLabel = useMemo(() => {
    if (renderTitle) {
      return renderTitle();
    }

    if (type === 'normal') {
      return <Typography variant="subhead-lg">Post Types</Typography>;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          gap: theme.spacing(2),
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(35, 6, 3, 0.05)',
            borderRadius: theme.spacing(1),
            padding: theme.spacing(2),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Icon size={16} />
        </Box>

        <Typography variant="subhead-lg">Post Types</Typography>
      </Box>
    );
  }, [Icon, renderTitle, type]);

  return (
    <DropDownMoreMenuItem
      disableRipple
      defaultOpen={type === 'normal'}
      sx={{
        ...(type === 'normal' && {
          pointerEvents: 'none',
        }),
        svg: {
          color: theme.colors?.primary.black,
          ...(type === 'normal' && {
            display: 'none',
          }),
        },
      }}
      label={renderLabel}
      dropDownOptions={Object.values(ExtendedSocialPostTypeForFilter).map(
        (type) => {
          return {
            label: type,
            value: type,
            isChecked: selectedTypes.includes(type),
            onClick: () => {
              onChange(
                selectedTypes.includes(type)
                  ? selectedTypes.filter((o) => o !== type)
                  : [...selectedTypes, type],
              );
            },
          };
        },
      )}
    />
  );
};
