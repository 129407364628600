import { Box } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { AdminUserPermissionGate } from 'components/common/PermissionGate';
import { AuthRoute } from 'components/layouts/AuthRoute';
import {
  generateOrganizationTabsData,
  OrganizationLayout,
} from 'components/layouts/OrganizationLayout';
import { PrivateRoute } from 'components/layouts/PrivateRoute';
import { useUserContext } from 'contexts/users/User.context';
import { SocialListeningPermissionRequestView } from 'features/socialMediaListening/views/permissionRequest';
import { SocialMediaListeningCompetitorDetailPage } from 'pages/socialMediaListening';
import { SocialMediaListeningCompetitorsPage } from 'pages/socialMediaListening/competitors';
import { lazy, Suspense, useEffect, useState } from 'react';
import {
  Location,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { matchRoute } from 'utils/routes';

// Lazyload all the pages
const TrendingAudioGroupPage = lazy(() =>
  import('pages/juicebox/trending/audioGroup').then((module) => ({
    default: module['TrendingAudioGroupPage'],
  })),
);
const TrendingAudioGroupDetailPage = lazy(() =>
  import('pages/juicebox/trending/audioGroupDetail').then((module) => ({
    default: module['TrendingAudioGroupDetailPage'],
  })),
);
const TrendingDetailPage = lazy(() =>
  import('pages/juicebox/trending/detail').then((module) => ({
    default: module['TrendingDetailPage'],
  })),
);
const JuiceboxTrendingRequestAccessPage = lazy(() =>
  import('pages/juicebox/trending/requestAccess').then((module) => ({
    default: module['JuiceboxTrendingRequestAccessPage'],
  })),
);
const JuiceboxNoteModalRedirect = lazy(() =>
  import('pages/juicebox/note/detail').then((module) => ({
    default: module['JuiceboxNoteModalRedirect'],
  })),
);
const JuiceModalRedirect = lazy(() =>
  import('pages/juicebox/detail/JuiceModalRedirect').then((module) => ({
    default: module['JuiceModalRedirect'],
  })),
);
const PostPreviewRedirectPage = lazy(() =>
  import('pages/juicebox/detail/preview').then((module) => ({
    default: module['PostPreviewRedirectPage'],
  })),
);
const JuiceboxPage = lazy(() =>
  import('pages/juicebox/index').then((module) => ({
    default: module['JuiceboxPage'],
  })),
);
const JuiceboxRequestAccess = lazy(() =>
  import('pages/juicebox/juiceboxRequestAccess').then((module) => ({
    default: module['JuiceboxRequestAccess'],
  })),
);
const Onboarding = lazy(() =>
  import('pages/onboarding/Onboarding').then((module) => ({
    default: module['Onboarding'],
  })),
);
const PendingAdminApproval = lazy(() =>
  import('pages/onboarding/PendingAdminApproval').then((module) => ({
    default: module['PendingAdminApproval'],
  })),
);
const OnboardingJuiceboxPage = lazy(() =>
  import('pages/onboardingJuicebox').then((module) => ({
    default: module['OnboardingJuiceboxPage'],
  })),
);
const RegisterIdeasPage = lazy(() =>
  import('pages/registerIdeas').then((module) => ({
    default: module['RegisterIdeasPage'],
  })),
);
const SocialMediaListeningAuthLayout = lazy(() =>
  import('features/socialMediaListening').then((module) => ({
    default: module['SocialMediaListeningAuthLayout'],
  })),
);
const AccessRequestPage = lazy(() =>
  import('pages/accessRequest').then((module) => ({
    default: module['AccessRequestPage'],
  })),
);
const AiOrganizePage = lazy(() =>
  import('pages/aiOrganize').then((module) => ({
    default: module['AiOrganizePage'],
  })),
);
const CheckoutPage = lazy(() =>
  import('pages/billing/checkout').then((module) => ({
    default: module['CheckoutPage'],
  })),
);
const CollectionPage = lazy(() =>
  import('pages/collections/detail').then((module) => ({
    default: module['CollectionPage'],
  })),
);
const ContentCalendarPage = lazy(() =>
  import('pages/contentCalendar').then((module) => ({
    default: module['ContentCalendarPage'],
  })),
);
const ContentCalendarRequestAccessPage = lazy(() =>
  import('pages/contentCalendar/requestAccess').then((module) => ({
    default: module['ContentCalendarRequestAccessPage'],
  })),
);
const ContentIdeaRedirectPage = lazy(() =>
  import('pages/contentIdeas/detail').then((module) => ({
    default: module['ContentIdeaRedirectPage'],
  })),
);
const InboxPage = lazy(() =>
  import('pages/inbox').then((module) => ({
    default: module['InboxPage'],
  })),
);
const Homepage = lazy(() =>
  import('pages/index').then((module) => ({
    default: module['Homepage'],
  })),
);
const SocialMediaListeningAnalyticsPage = lazy(() =>
  import('pages/socialMediaListening').then((module) => ({
    default: module['SocialMediaListeningAnalyticsPage'],
  })),
);
const SocialMediaListeningOnboardingBrandPage = lazy(() =>
  import('pages/socialMediaListening').then((module) => ({
    default: module['SocialMediaListeningOnboardingBrandPage'],
  })),
);
const SocialMediaListeningOnboardingCreatorTrackingPage = lazy(() =>
  import('pages/socialMediaListening').then((module) => ({
    default: module['SocialMediaListeningOnboardingCreatorTrackingPage'],
  })),
);
const SocialMediaListeningPage = lazy(() =>
  import('pages/socialMediaListening').then((module) => ({
    default: module['SocialMediaListeningPage'],
  })),
);
const SocialMediaListeningAnalyticsInboundPage = lazy(() =>
  import(
    'pages/socialMediaListening/analytics/inbound/SocialMediaListeningAnalyticsInboundPage'
  ).then((module) => ({
    default: module['SocialMediaListeningAnalyticsInboundPage'],
  })),
);
const SocialMediaListeningAnalyticsTopicsPage = lazy(() =>
  import(
    'pages/socialMediaListening/analytics/topics/SocialMediaListeningAnalyticsTopicsPage'
  ).then((module) => ({
    default: module['SocialMediaListeningAnalyticsTopicsPage'],
  })),
);
const SocialMediaListeningAnalyticsOutboundPage = lazy(() =>
  import('pages/socialMediaListening/analytics/outbound').then((module) => ({
    default: module['SocialMediaListeningAnalyticsOutboundPage'],
  })),
);
const SocialMediaListeningAnalyticsCompetitorsPage = lazy(() =>
  import('pages/socialMediaListening/analytics/competitors').then((module) => ({
    default: module['SocialMediaListeningAnalyticsCompetitorsPage'],
  })),
);
const SocialMediaListeningAnalyticsOutboundInteractionRedirectPage = lazy(() =>
  import('pages/socialMediaListening/analytics/outbound/interaction').then(
    (module) => ({
      default:
        module['SocialMediaListeningAnalyticsOutboundInteractionRedirectPage'],
    }),
  ),
);
const SocialMediaListeningAnalyticsOutboundLeaderboardDetailRedirectPage = lazy(
  () =>
    import(
      'pages/socialMediaListening/analytics/outbound/leaderboard/detail/SocialMediaListeningAnalyticsOutboundLeaderboardDetailRedirectPage'
    ).then((module) => ({
      default:
        module[
          'SocialMediaListeningAnalyticsOutboundLeaderboardDetailRedirectPage'
        ],
    })),
);
const SocialMediaListeningIntroPage = lazy(() =>
  import('pages/socialMediaListening/intro').then((module) => ({
    default: module['SocialMediaListeningIntroPage'],
  })),
);
const SocialMediaListeningOnboardingTopicPage = lazy(() =>
  import('pages/socialMediaListening/onboarding/topic').then((module) => ({
    default: module['SocialMediaListeningOnboardingTopicPage'],
  })),
);
const SocialMediaListeningOnboardingCompetitorPage = lazy(() =>
  import('pages/socialMediaListening/onboarding/competitors').then(
    (module) => ({
      default: module['SocialMediaListeningOnboardingCompetitorsPage'],
    }),
  ),
);
const SocialMediaListeningPostDetailPage = lazy(() =>
  import('pages/socialMediaListening/post/detail').then((module) => ({
    default: module['SocialMediaListeningPostDetailPage'],
  })),
);
const TasksPage = lazy(() =>
  import('pages/tasks/index').then((module) => ({
    default: module['TasksPage'],
  })),
);
const TasksRequestAccessPage = lazy(() =>
  import('pages/tasks/requestAccess').then((module) => ({
    default: module['TasksRequestAccessPage'],
  })),
);
const BillingTrendsPaywall = lazy(() =>
  import('features/billing').then((module) => ({
    default: module['BillingTrendsPaywall'],
  })),
);
const TrendingJuicebox = lazy(() =>
  import('pages/juicebox/trending/index').then((module) => ({
    default: module['TrendingJuicebox'],
  })),
);
const AttachmentDetailRedirectPage = lazy(() =>
  import('pages/attachments/detail/AttachmentDetailRedirectPage').then(
    (module) => ({
      default: module['AttachmentDetailRedirectPage'],
    }),
  ),
);

export const MainRouteView = () => {
  const location = useLocation();
  const state =
    (location.state as {
      backgroundLocation?: Location;
    }) || {};

  const scrollable = !state?.backgroundLocation;

  const { orgBilling } = useUserContext();

  // to avoid the phenomenon of flashing the home screen (layer 1)
  // and then loading the note (layer 2) when displaying the post modal
  // TODO: Better solution for this. Rn it's a hack
  const [isPendingToRedirectToPostPage, setIsPendingToRedirectToPostPage] =
    useState(
      !state.backgroundLocation &&
        (matchRoute(location.pathname, PlotRoutes.juice(':id')) ||
          matchRoute(
            location.pathname,
            PlotRoutes.juiceboxNote({
              id: ':id',
            }),
          )) &&
        ![PlotRoutes.juiceboxTrending()].some((route) =>
          location.pathname.includes(route),
        ),
    );

  useEffect(() => {
    if (isPendingToRedirectToPostPage && state.backgroundLocation) {
      setIsPendingToRedirectToPostPage(false);
    }
  }, [isPendingToRedirectToPostPage, state.backgroundLocation]);

  return (
    <Box
      sx={{
        height: scrollable ? 'auto' : '100vh',
        overflow: scrollable ? 'inherit' : 'hidden',
        pointerEvents: scrollable ? 'auto' : 'none',
        opacity: isPendingToRedirectToPostPage ? 0 : 1,
      }}
    >
      <SocialListeningPermissionRequestView />
      <Suspense>
        <Routes location={state?.backgroundLocation || location}>
          <Route path="/auth" element={<AuthRoute />}>
            <Route index element={<Navigate to="/" />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/org">
              <Route path="settings" element={<OrganizationLayout />}>
                {generateOrganizationTabsData().map((tab) => {
                  return (
                    <Route
                      key={tab.path}
                      path={`${tab.path}`}
                      element={tab.component}
                    />
                  );
                })}
              </Route>
              <Route
                path="settings/billing/checkout"
                element={
                  <AdminUserPermissionGate>
                    <CheckoutPage />
                  </AdminUserPermissionGate>
                }
              />
            </Route>

            {/* User onboarding */}
            <Route path="/onboarding" element={<Onboarding />} />
            <Route
              path={PlotRoutes.registerIdeas()}
              element={<RegisterIdeasPage />}
            />
            <Route
              path="/onboarding/creative-juicebox"
              element={<OnboardingJuiceboxPage />}
            />

            <Route path="/access-request" element={<AccessRequestPage />} />

            <Route path="/pending" element={<PendingAdminApproval />} />
            <Route path="/ai-organize" element={<AiOrganizePage />} />

            <Route
              path="/collections/:collectionId"
              element={<CollectionPage />}
            />

            <Route path="/inbox" element={<InboxPage />} />

            {/* Creative Juicebox */}
            <Route path="/juicebox">
              <Route
                path="request-access"
                element={<JuiceboxRequestAccess />}
              />
              <Route path="note/:id" element={<JuiceboxNoteModalRedirect />} />
              <Route path=":id" element={<JuiceModalRedirect />} />
              <Route path=":id/preview" element={<PostPreviewRedirectPage />} />
              <Route path="" element={<JuiceboxPage />} />
            </Route>

            <Route path="/juicebox/trending">
              <Route
                path="request-access"
                element={
                  orgBilling?.trendsEnabled ? (
                    <JuiceboxTrendingRequestAccessPage />
                  ) : null
                }
              />
              <Route
                path=":id"
                element={
                  orgBilling?.trendsEnabled ? (
                    <TrendingDetailPage />
                  ) : orgBilling ? (
                    <BillingTrendsPaywall organizationBilling={orgBilling} />
                  ) : null
                }
              />
              <Route
                index
                element={
                  orgBilling?.trendsEnabled ? (
                    <TrendingJuicebox />
                  ) : orgBilling ? (
                    <BillingTrendsPaywall organizationBilling={orgBilling} />
                  ) : null
                }
              />
              <Route path="audio">
                <Route index element={<TrendingAudioGroupPage />} />
                <Route path=":id" element={<TrendingAudioGroupDetailPage />} />
              </Route>
            </Route>

            {/* Tasks */}
            <Route path="/tasks">
              <Route
                path="request-access"
                element={<TasksRequestAccessPage />}
              />
              <Route path="" element={<TasksPage />} />
              <Route path=":taskId" element={<TasksPage />} />
            </Route>

            <Route path="/juicebox">
              <Route path="note/:id" element={<JuiceboxNoteModalRedirect />} />
              <Route path=":id" element={<JuiceModalRedirect />} />
              <Route path=":id/preview" element={<PostPreviewRedirectPage />} />
            </Route>

            <Route
              path="/social-listening"
              element={<SocialMediaListeningAuthLayout />}
            >
              <Route
                path="onboarding/brand/:brandId"
                element={<SocialMediaListeningOnboardingBrandPage />}
              />
              <Route
                path="onboarding/brand"
                element={<SocialMediaListeningOnboardingBrandPage />}
              />
              <Route
                path="brands/:brandId/onboarding/creator"
                element={<SocialMediaListeningOnboardingCreatorTrackingPage />}
              />
              <Route
                path="brands/:brandId/onboarding/topic"
                element={<SocialMediaListeningOnboardingTopicPage />}
              />
              <Route
                path="brands/:brandId/onboarding/competitors"
                element={<SocialMediaListeningOnboardingCompetitorPage />}
              />
              <Route path="intro" element={<SocialMediaListeningIntroPage />} />

              <Route
                path="brands/:brandId/analytics"
                element={<SocialMediaListeningAnalyticsPage />}
              >
                <Route
                  path="inbound"
                  element={<SocialMediaListeningAnalyticsInboundPage />}
                />
                <Route
                  path="topics"
                  element={<SocialMediaListeningAnalyticsTopicsPage />}
                />
                <Route path="outbound">
                  <Route
                    path=""
                    element={<SocialMediaListeningAnalyticsOutboundPage />}
                  />
                  <Route
                    path="leaderboard/:id"
                    element={
                      <SocialMediaListeningAnalyticsOutboundLeaderboardDetailRedirectPage />
                    }
                  />
                  <Route
                    path="interactions"
                    element={
                      <SocialMediaListeningAnalyticsOutboundInteractionRedirectPage />
                    }
                  />
                </Route>
                <Route
                  path="competitors"
                  element={<SocialMediaListeningAnalyticsCompetitorsPage />}
                />
              </Route>

              {/* <Route
                path="posts/:postId"
                element={<SocialMediaListeningPostDetailPage />}
              /> */}
              <Route
                path="brands/:brandId/posts/:postId"
                element={<SocialMediaListeningPostDetailPage />}
              />

              <Route
                path="brands/:brandId/competitors"
                element={<SocialMediaListeningCompetitorsPage />}
              />
              <Route
                path="brands/:brandId/competitors/:competitorId"
                element={<SocialMediaListeningCompetitorDetailPage />}
              />

              <Route
                path="brands/:brandId"
                element={<SocialMediaListeningPage />}
              />
            </Route>

            {/* Content Calendar */}
            <Route path="/content-calendar" element={<ContentCalendarPage />} />
            <Route
              path="/content-calendar/request-access"
              element={<ContentCalendarRequestAccessPage />}
            />
            <Route
              path="/content-ideas/:id"
              element={<ContentIdeaRedirectPage />}
            />

            {/* Attachment */}
            <Route
              path="/attachments/:id"
              element={<AttachmentDetailRedirectPage />}
            />

            {/* Homepage */}
            <Route path="/" element={<Homepage />} />
          </Route>

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </Box>
  );
};
