import { gql } from '@apollo/client';
import { CapturedProductGraphDataFragmentForSlaRankingGraphDataFragment } from 'graphql/generated';
import { Area, AreaChart, ResponsiveContainer } from 'recharts';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  fragment CapturedProductGraphDataFragmentForSLARankingGraphData on CapturedProductDataForInboundGraphData {
    x
    y
  }
`;

interface SLARankingGraphProps {
  graphData: CapturedProductGraphDataFragmentForSlaRankingGraphDataFragment[];
  isNegativePercentChange: boolean;
  chartHeight?: number | string;
  chartWidth?: number | string;
}
export const SLARankingGraph = ({
  graphData,
  isNegativePercentChange,
  chartHeight = 30,
  chartWidth = 100,
}: SLARankingGraphProps) => {
  return (
    <ResponsiveContainer width={chartWidth} height={chartHeight}>
      <AreaChart data={graphData}>
        <Area
          type="monotone"
          dataKey="y"
          stroke={
            theme.colors?.utility[
              isNegativePercentChange ? 'pink-3' : 'green-3'
            ]
          }
          fill={
            isNegativePercentChange
              ? 'rgba(255, 204, 204, 0.6)'
              : 'rgba(138, 161, 101, 0.5)'
          }
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
